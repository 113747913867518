/** @jsx jsx */
import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { useTheme } from 'emotion-theming';
import { jsx } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primaryColor?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export const Button = (props: IButtonProps) => {
  const { primaryColor, backgroundColor, style, children, ...btnProps } = props;
  const customStyle = style || {};

  const { theme } = useTheme();

  return (
    <button
      {...btnProps}
      // @ts-expect-error FIXME emotion types on spread
      css={{
        ...buttonStyle(theme, primaryColor, backgroundColor),
        ...customStyle,
      }}
    >
      {children}
    </button>
  );
};

const buttonStyle = (theme: ITheme, color?: string, background?: string) => {
  const primaryColor = color || theme.base.primary;
  const backgroundColor = background || theme.main.background;
  return {
    wordBreak: 'break-word',
    fontFamily: theme.main.fontFamily,
    fontSize: theme.main.fontSize,
    lineHeight: 1.5715,
    position: 'relative',
    display: 'inline-block',
    fontWeight: 400,
    textAlign: 'center',
    border: '1px solid transparent',
    WebkitBoxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
    cursor: 'pointer',
    WebkitTransition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    height: 'fit-content',
    padding: '4px 15px',
    borderRadius: 2,
    color: theme.base.fontColor,
    background: backgroundColor,
    borderColor: theme.base.fontColor,
    '&:not([disabled]):hover': {
      textDecoration: 'none',
    },
    '&:not([disabled]):active': {
      outline: 0,
      boxShadow: 'none',
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      opacity: 0.6,
      borderColor: '#d9d9d9',
      textShadow: 'none',
      WebkitBoxshadow: 'none',
      boxShadow: 'none',
    },
    '&:hover': {
      color: primaryColor,
      background: backgroundColor,
      borderColor: primaryColor,
      textDecoration: 'none',
    },
    '&:focus': {
      color: primaryColor,
      background: backgroundColor,
      borderColor: primaryColor,
      textDecoration: 'none',
    },
    '&:active': {
      color: primaryColor,
      background: backgroundColor,
      borderColor: primaryColor,
      textDecoration: 'none',
    },
  };
};

export default Button;
