import React, { useEffect } from 'react';
import ImgOneX from '../../img/100-offline-sprite.png';
import ImgTwoX from '../../img/200-offline-sprite.png';
import './runner.css';
import Runner from './runner';
import Tag from '../../components/Tag';
import chroma from 'chroma-js';
import { translate } from '../languages';
import Reporting from '../../analytics/Reporting';
import Z from '@commandbar/internal/client/Z';

const Game = () => {
  // Track the time played
  const [time, setTime] = React.useState(0);
  // Need a ref to get the up to date stack in the useEffect callback
  const stateRef = React.useRef<number>();
  stateRef.current = time;

  useEffect(() => {
    const eventListenerContainer = document.getElementsByClassName('commandbar-modal')?.[0];
    // @ts-expect-error: Avoids type error with Runner
    new Runner(eventListenerContainer, '.commandbar-game-interstitial-wrapper');

    if (document.activeElement) {
      // Blur from the input bar
      // @ts-expect-error: We would need to cast activeElement as an Element with blur on it
      document.activeElement && document.activeElement.hasOwnProperty('blur') && document.activeElement.blur();
    }

    const interval = setInterval(() => {
      setTime((seconds) => seconds + 1);
    }, 1000);

    // On unmount, remove the interval, report the gameplay
    return () => {
      clearInterval(interval);
      Reporting.game(stateRef.current);
    };
  }, []);

  return (
    <div style={{ padding: 25 }}>
      <div className="commandbar-game">
        <div id="commandbar-game-t" className="commandbar-game-offline" style={{ zIndex: Z.Z_INDEX_MAX }}>
          {/* <div id="messageBox" className="sendmessage">
        <h1 style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif' }}>Press Space to start</h1>
        {/* <div className="niokbutton" onClick="okbuttonsend()" /> */}
          {/* </div> */}
          <div id="commandbar-main-frame-error" className="commandbar-game-interstitial-wrapper">
            <div id="commandbar-main-content">
              <div className="commandbar-game-icon commandbar-game-icon-offline"></div>
            </div>
            <div id="commandbar-game-offline-resources">
              <img id="commandbar-game-offline-resources-1x" src={ImgOneX} alt="offline-1" />
              <img id="commandbar-game-offline-resources-2x" src={ImgTwoX} alt="offline-2" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 10 }}>
        <Tag color={chroma('rgb(174, 177, 221)')}>Space</Tag>
        &nbsp; {translate('to start and jump')} &nbsp; &nbsp; &nbsp;
        <Tag color={chroma('rgb(174, 177, 221)')}>Esc</Tag>
        &nbsp; {translate('to close')}
      </div>
    </div>
  );
};

export default Game;
