/* eslint-disable react/prop-types */
import * as React from 'react';
import { ErrorReporter } from '../error/ErrorReporter';
import { useState } from 'react';

export interface Props {
  children: React.ReactNode;
}

export interface State {
  errorMessage: string;
  hasError: boolean;
  stacktrace: string | undefined;
}

const BSOD: React.FC<{ hide: VoidFunction; msg: string; stack?: string }> = ({ hide, msg, stack }) => {
  const [collapse, setCollapse] = useState(true);

  if (collapse)
    return (
      <div
        onClick={() => setCollapse(false)}
        style={{
          position: 'absolute',
          zIndex: 99999,
          bottom: '1rem',
          right: '1rem',
          width: '5rem',
          height: '5rem',
          fontSize: '3rem',
          background: '#4242cb',
          color: 'white',
          borderRadius: '99999px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'clip',
          cursor: 'pointer',
        }}
      >
        <span role="img" aria-label="grimace">
          😬
        </span>
      </div>
    );

  return (
    <div
      onDoubleClick={hide}
      style={{
        alignItems: 'center',
        background: 'blue',
        bottom: 0,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'monospace',
        justifyItems: 'center',
        overflowX: 'clip',
        left: 0,
        padding: '1rem',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 99999,
      }}
    >
      <h3>Error boundary triggered! Double click here to hide. (This message will not be visible in production.)</h3>
      {msg.toLowerCase().includes('<!doctype html>') ? (
        <iframe
          style={{ height: '100%', width: '100%', overflowWrap: 'anywhere', overflowY: 'scroll' }}
          title="fail"
          src={`data:text/html;charset=utf-8,${encodeURIComponent(msg)}`}
        ></iframe>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            overflowWrap: 'anywhere',
            overflowY: 'scroll',
          }}
        >
          <h3>Error (doubleclick to hide): {msg}</h3>
          <pre>{stack}</pre>
        </div>
      )}
    </div>
  );
};

class ErrorBoundary extends React.Component<Props, State> {
  public state: Readonly<State> = { hasError: false, errorMessage: '', stacktrace: undefined };

  // captureRejection = (ev: PromiseRejectionEvent) => {
  //   const errorMessage = ev.reason instanceof Error ? ev.reason.message : String(ev.reason);
  //   const stacktrace = ev.reason instanceof Error ? ev.reason.stack : '';
  //   ErrorReporter.get().exception(ev.reason, { tags: { fromErrorBoundary: true } });
  //   this.setState({ hasError: true, errorMessage, stacktrace });
  //   ev.preventDefault();
  // };

  // componentDidMount = () => {
  //   window.addEventListener('unhandledrejection', this.captureRejection);
  // };

  // public componentWillUnmount() {
  //   window.removeEventListener('unhandledrejection', this.captureRejection);
  // }

  public static getDerivedStateFromError(err: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: err.message, stacktrace: err.stack };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    ErrorReporter.get().exception(error, errorInfo as unknown as Record<string, unknown>);
  }

  public render() {
    if (process.env.NODE_ENV === 'production' || !this.state.hasError) return this.props.children;
    return (
      <BSOD
        hide={() => this.setState({ hasError: false })}
        msg={this.state.errorMessage}
        stack={this.state.stacktrace}
      />
    );
  }
}

export default ErrorBoundary;
