/** @jsx jsx */

import { ITheme } from '@commandbar/internal/client/theme';
import { jsx } from '@emotion/react';
import chroma from 'chroma-js';
import { useTheme } from 'emotion-theming';

import './LoadingIndicator.css';

const styles = (theme: ITheme) => ({
  ':before': {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: chroma(theme.base.fontColor).alpha(0.2).css(),
    borderTopColor: theme.base.fontColor,
  },
});

const LoadingIndicator = (props: { isLoading: boolean }) => {
  const { theme } = useTheme();
  if (!props.isLoading) {
    return null;
  } else {
    return <div className="commandbar-loader" key="commandbar-loader" css={styles(theme)} />;
  }
};

export default LoadingIndicator;
