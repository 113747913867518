import LocalStorage from '@commandbar/internal/util/LocalStorage';
import axiosInstance from '@commandbar/internal/middleware/network';
import { EVENT_NAME } from '@commandbar/internal/client/Reporting';
import { ErrorReporter } from '../error/ErrorReporter';
import { isErrorCode } from '../error/ErrorCode';
import { getSDK, getProxySDK } from '@commandbar/internal/client/globals';
import { _search, _user, _configuration, _eventMeta, _orgConfig } from '@commandbar/internal/client/symbols';

const DEBUG_MODE = false;

// Turn off all analytics for orgs with sensitive data
const TRACK_IN_LOCALHOST = false; // turn off logging when in localhost?
const QUEUE_SIZE = 5; // number of events after which we flush

let COMMANDBAR_IS_ACTIVE = false;
let USER_IS_ADMIN = false;
let eventQueue: any[] = [];

/**
 * Determine whether we should turn on analytics for a site
 */
const inProduction = !(process.env.NODE_ENV && process.env.NODE_ENV === 'development');

const shouldTrackEvents = () => {
  if (!COMMANDBAR_IS_ACTIVE) return false;
  if (DEBUG_MODE) return true;
  const shouldTrackThisEnv = !inProduction ? TRACK_IN_LOCALHOST : true;
  return shouldTrackThisEnv;
};

const shouldTrackErrors = () => {
  const shouldTrackThisEnv = !inProduction ? TRACK_IN_LOCALHOST : true;
  return shouldTrackThisEnv;
};

export const isSilentMode = () => {
  // Safe check
  // If _silent is undefined, turn it on

  const proxy = getProxySDK();
  const silent = proxy[_orgConfig]?.silent;

  if (silent === undefined) {
    ErrorReporter.get().breadcrumb({
      message: 'Silent mode configuration is undefined',
      data: {
        configuration: proxy[_configuration],
        org: proxy[_configuration]?.uuid,
      },
    });
  }

  return silent !== false;
};

/**
 * Analytics API
 */

enum EVENT_TYPE {
  Track = 't',
  Identify = 'i',
  Log = 'l',
  Availability = 'a',
  Error = 'e',
}

const track = (eventName: EVENT_NAME, attrs: Record<string, any>, forceFlush?: boolean) => {
  ErrorReporter.get().breadcrumb({
    category: 'track',
    message: eventName,
    data: attrs,
  });

  if (shouldTrackEvents()) onEvent(EVENT_TYPE.Track, eventName, attrs, forceFlush);
};

const log = (eventName: EVENT_NAME, attrs: Record<string, any>, forceFlush?: boolean) => {
  ErrorReporter.get().breadcrumb({
    category: 'log',
    message: eventName,
    data: attrs,
  });

  if (shouldTrackEvents()) onEvent(EVENT_TYPE.Log, eventName, attrs, forceFlush);
};

const identify = (attrs: Record<string, any>) => {
  ErrorReporter.get().breadcrumb({
    category: 'identify',
    message: 'Identify',
    data: attrs,
  });

  // HACK: Allow identify only for Topshot
  if (shouldTrackEvents() || getSDK()[_configuration].uuid === '294a5b63')
    onEvent(EVENT_TYPE.Identify, 'Identify', attrs, true);
};

const error = function (message: string, data?: { [variable: string]: any }) {
  // REVIEW: This will only report an error to analytics if the message is one of the predefined error codes. Otherwise
  // the error message could contain sensitive information / user-entered data. Is this the desired behavior?
  if (shouldTrackErrors() && isErrorCode(message)) {
    const eventName: EVENT_NAME = message.startsWith('Internal') ? 'Internal-Error' : 'Client-Error';
    onEvent(EVENT_TYPE.Error, eventName, { description: message, ...data }, true);
  }
};

// Command availability
const availability = (commands: number[]) => {
  const attrs = { commands };
  ErrorReporter.get().breadcrumb({
    category: 'availability',
    message: 'Calculate availability',
    data: attrs,
  });

  if (shouldTrackEvents() && !isSilentMode()) sendEventToServer(EVENT_TYPE.Availability, undefined, attrs);
};

const isAdmin = () => {
  USER_IS_ADMIN = true;
  LocalStorage.set('adm', true);
  identify({ isAdmin: true });
};

const setActive = (isActive: boolean) => {
  COMMANDBAR_IS_ACTIVE = isActive;
};

const getUserType = () => {
  if (USER_IS_ADMIN) return 'admin';
  if (!!LocalStorage.get('adm', '')) return 'likely-admin';
  return 'end_user';
};
/**
 * Send events to server
 */

const CLIENT_HANDLER_EVENT_TYPES: { internal: EVENT_NAME; external: string }[] = [
  { internal: 'Abandoned search' as EVENT_NAME, external: 'abandoned_search' },
  { internal: 'Command suggestion' as EVENT_NAME, external: 'command_suggestion' },
  { internal: 'Command execution' as EVENT_NAME, external: 'command_execution' },
  { internal: 'Admin command execution' as EVENT_NAME, external: 'admin_command_execution' },
  { internal: 'New search' as EVENT_NAME, external: 'opened' },
  { internal: 'Exited' as EVENT_NAME, external: 'closed' },
  { internal: 'Client-Error' as EVENT_NAME, external: 'client_error' },
];

const onEvent = (type: EVENT_TYPE, name: EVENT_NAME, attrs: Record<string, any>, forceFlush?: boolean) => {
  console.debug('onEvent:', type);
  const eventMeta = getSDK()[_eventMeta] || {};

  if (!isSilentMode()) {
    // Send to server if not in silent mode
    sendEventToServer(type, name, attrs, forceFlush);
  }

  // If custom client event callback, call it with event
  const externalName = name && CLIENT_HANDLER_EVENT_TYPES.find((x) => x.internal === (name as EVENT_NAME))?.external;
  if (!!externalName) {
    const eventHandler = getSDK().shareCallbacks()?.['commandbar-event-handler'];
    if (eventHandler) eventHandler(externalName, { ...attrs, userAttributes: eventMeta });
  }
};

const sendEventToServer = (
  type: EVENT_TYPE,
  name: string | undefined,
  attrs: Record<string, any>,
  forceFlush?: boolean,
) => {
  sendEventToQueue(type, name, attrs);
  if (eventQueue.length >= QUEUE_SIZE || forceFlush) {
    flushQueueToServer();
  }
};

const sendEventToQueue = (type: EVENT_TYPE, name: string | undefined, attrs: Record<string, any>) => {
  const userType = getUserType();
  const userID = typeof getSDK()[_user] === 'string' ? getSDK()[_user] : null;
  const payload = {
    context: {
      page: {
        path: window.location?.pathname,
        title: document?.title,
        url: window.location?.href,
        search: window.location?.search,
      },
      userAgent: navigator?.userAgent,
      groupId: getSDK()[_configuration].uuid,
      cbSource: getSDK()[_configuration],
      release: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? '',
    },
    userType,
    type,
    attrs,
    name,
    id: userID,
    session: getSDK()[_configuration].session,
    search: getSDK()[_search],
  };
  if (DEBUG_MODE) {
    console.log(payload);
  } else {
    eventQueue.push(payload);
  }
};

const flushQueueToServer = () => {
  const body = JSON.stringify({
    events: eventQueue,
    organization: getSDK()[_configuration].uuid,
    id: getSDK()[_user],
  });
  eventQueue = [];
  axiosInstance.post('/t/', body);
};

/**
 * Exports
 */

const AnalyticsAPI = {
  track,
  log,
  identify,
  error,
  isAdmin,
  setActive,
  availability,
};

export default AnalyticsAPI;
