import { ICommandType, IOrganizationType, isCommand } from '@commandbar/internal/middleware/types';
import { osControlKey, isMobile } from '@commandbar/internal/util/operatingSystem';
import Reporting, { SEARCH_TRIGGER } from '../../analytics/Reporting';
import StepGenerator from './StepGenerator';
import { CommandOption, Option } from '../option';
import { _user } from '@commandbar/internal/client/symbols';
import { getSDK } from '@commandbar/internal/client/globals';

/***************************************************************************************
 * Types
 ***************************************************************************************/
export interface IOnboardingState {
  isActive: boolean;
  steps: IOnboardingStep[];
  currentStepIndex: number;
  allowNext: boolean;
  hideBar: boolean;
  activeCommand: ICommandType | undefined;
  // Time tracking
  stepStartTime: number | undefined;
  timeSpent: TimePerStep[];
  shouldTrack: boolean;
}

interface TimePerStep {
  index: number;
  timeOnStep: number;
}

export interface IOnboardingDispatch {
  data?: Record<string, any>;
  type: IOnboardingEventType | IOnboardingActions;
}

/** Types of events that can trigger a state change */

type IOnboardingActions =
  | 'setSteps'
  | 'setCommands'
  | 'Boot'
  | 'Shutdown'
  | 'Start'
  | 'Exit'
  | 'Next'
  | 'Previous'
  | 'Jump';

export type IOnboardingEventType =
  | 'Closed'
  | 'CMDK'
  | 'Scroll'
  | 'ArrowPress'
  | 'ArrowRight'
  | 'TextInput'
  | 'Execution'
  | 'Enter'
  | 'StepInitialization';

export enum ONBOARDING_TEXT_TOKEN {
  COMMAND_NAME,
  ORG_NAME,
  OS_CTRL_KEY,
}

type IOnboardingText = (string | ONBOARDING_TEXT_TOKEN)[];

export interface IOnboardingStep {
  hideBarAtStart: boolean;
  title: IOnboardingText;
  text: IOnboardingText;
  instructionText: IOnboardingText;
  nextBtnText: string | undefined;
  canShowNext: (state: IOnboardingState, event_type: IOnboardingEventType, data?: Record<string, any>) => boolean;
  allowExecution?: boolean;
  emptyContent?: React.ReactNode; // Content to show below the bar instead of Cmd+K
}

/***************************************************************************************
 * External functions to fetch state
 ***************************************************************************************/
export const getUniqueID = (state: IOnboardingState) => {
  const endUser = getSDK()[_user];
  if (!state.stepStartTime || typeof endUser !== 'string') {
    return '';
  }
  return `${endUser}-${state.stepStartTime.toString()}`;
};

export const getCurrentOnboardingStep = (state: IOnboardingState) => {
  if (!state.isActive || !state.steps[state.currentStepIndex]) return null;
  return state.steps[state.currentStepIndex];
};

export const isBarHidden = (state: IOnboardingState) => {
  if (!state.isActive) return false;
  return state.hideBar;
};

export const getTitle = (state: IOnboardingState, organization: IOrganizationType | undefined) => {
  const thisStep = getCurrentOnboardingStep(state);
  if (thisStep) return parseTokens(thisStep.title, state, organization);
  return '';
};

export const getText = (state: IOnboardingState, organization: IOrganizationType | undefined) => {
  const thisStep = getCurrentOnboardingStep(state);
  if (thisStep) return parseTokens(thisStep.text, state, organization);
  return '';
};

export const getInstruction = (state: IOnboardingState, organization: IOrganizationType | undefined) => {
  const thisStep = getCurrentOnboardingStep(state);
  if (thisStep) {
    if (state.allowNext) {
      const nextInstruction = hasNextStep(state) ? 'to move to next step' : 'to finish';
      return `Click ${thisStep.nextBtnText} or press enter ${nextInstruction}`;
    }
    return parseTokens(thisStep.instructionText, state, organization);
  }
  return '';
};

export const canGoBack = (state: IOnboardingState) => {
  return hasPreviousStep(state);
};

export const allowExecution = (state: IOnboardingState) => {
  const thisStep = getCurrentOnboardingStep(state);
  return thisStep?.allowExecution === true;
};

/**
 * Add / Remove mask
 */

/***************************************************************************************
 * Internal utility functions
 ***************************************************************************************/

const hasNextStep = (state: IOnboardingState) => {
  return state.steps.length - 1 > state.currentStepIndex;
};

const hasPreviousStep = (state: IOnboardingState) => {
  return state.steps.length > 1 && state.currentStepIndex > 0;
};

/* Checks if we can let the user click on the nextStep button based on the most recent event */
const allowNextStep = (state: IOnboardingState, event_type: IOnboardingEventType, data?: Record<string, any>) => {
  const thisStep = getCurrentOnboardingStep(state);
  if (thisStep) {
    return thisStep.canShowNext(state, event_type, data);
  } else return false;
};

const exitOnboarding = (state: IOnboardingState) => {
  if (state.isActive) {
    const updatedState = { ...state, timeSpent: addTimeSpentOnStep(state) };
    Reporting.onboarding.exited(updatedState);
  }
  return initialOnboardingState;
};

const finishOnboarding = (state: IOnboardingState) => {
  let updatedState = { ...state, timeSpent: addTimeSpentOnStep(state) };
  Reporting.onboarding.completed(updatedState);
  updatedState = {
    ...updatedState,
    isActive: false,
    currentStepIndex: 0,
    allowNext: false,
    stepStartTime: undefined,
    timeSpent: [],
    steps: StepGenerator.onboarding(),
  };
  // Start a new search when the onboarding closes out
  Reporting.search.start(updatedState, SEARCH_TRIGGER.ONBOARDING);
  return updatedState;
};

const moveToSpecificStep = (state: IOnboardingState, index: number) => {
  const newState = { ...state, currentStepIndex: index };
  const showActionByDefault = allowNextStep(newState, 'StepInitialization');
  const hideBar = getCurrentOnboardingStep(newState)?.hideBarAtStart || false;
  return {
    ...newState,
    allowNext: showActionByDefault,
    timeSpent: addTimeSpentOnStep(state),
    hideBar,
  };
};

const moveToPreviousStep = (state: IOnboardingState) => {
  if (!hasPreviousStep(state)) return state;
  return moveToSpecificStep(state, state.currentStepIndex - 1);
};

const moveToNextStep = (state: IOnboardingState) => {
  if (!hasNextStep(state)) return finishOnboarding(state);
  return moveToSpecificStep(state, state.currentStepIndex + 1);
};

const addTimeSpentOnStep = (state: IOnboardingState) => {
  const currentTime = Date.now();
  if (state.stepStartTime) {
    const timeSpentOnLastStepInSeconds = (currentTime - state.stepStartTime) / 1000;
    const newTimeStep = { index: state.currentStepIndex, timeOnStep: timeSpentOnLastStepInSeconds };
    return [...state.timeSpent, newTimeStep];
  }
  return state.timeSpent;
};

const showActionForThisStep = (state: IOnboardingState) => {
  return { ...state, allowNext: true };
};

const pickRandomCommand = (commands: ICommandType[]) => {
  if (commands.length === 0) return undefined;
  return commands[Math.floor(Math.random() * commands.length)];
};

/* Parses a tokenified string */
const parseTokens = (tokens: IOnboardingText, state: IOnboardingState, organization: IOrganizationType | undefined) => {
  const tokensToStrings = tokens.map((token: string | ONBOARDING_TEXT_TOKEN) => {
    switch (token) {
      case ONBOARDING_TEXT_TOKEN.COMMAND_NAME:
        if (state.activeCommand) return `"${state.activeCommand.text}"`;
        return 'a command';
      case ONBOARDING_TEXT_TOKEN.ORG_NAME:
        if (organization) return organization.name;
        return 'this site';
      case ONBOARDING_TEXT_TOKEN.OS_CTRL_KEY:
        return osControlKey();
      default:
        return token;
    }
  });
  return tokensToStrings.join('');
};

const hasNotStarted = (state: IOnboardingState) => {
  return state.isActive && state.currentStepIndex === 0 && state.stepStartTime === undefined;
};

const onOnboardingStart = (state: IOnboardingState, trigger?: string) => {
  const newState = { ...state, stepStartTime: Date.now() };
  Reporting.onboarding.started(newState, trigger);
  return newState;
};

const filterCommandsFromOptions = (options: ICommandType[] | Option[]) => {
  if (!Array.isArray(options) || options.length === 0) return [];
  if (isCommand(options[0])) return options as ICommandType[];

  return (options as Option[])
    .filter((opt): opt is CommandOption => opt instanceof CommandOption && !opt.optionDisabled.isDisabled)
    .map((opt) => opt.command);
};

/***************************************************************************************
 * Reducer
 ***************************************************************************************/

const onboardingStateSkeleton: IOnboardingState = {
  isActive: false,
  steps: [],
  currentStepIndex: 0,
  allowNext: false,
  hideBar: false,
  activeCommand: undefined,
  stepStartTime: undefined,
  timeSpent: [],
  shouldTrack: true,
};

export const initialOnboardingState: IOnboardingState = {
  ...onboardingStateSkeleton,
  steps: StepGenerator.onboarding(),
  allowNext: StepGenerator.onboarding()[0].canShowNext({ ...onboardingStateSkeleton }, 'StepInitialization'),
  hideBar: StepGenerator.onboarding()[0]?.hideBarAtStart,
};

const OnboardingReducer = (state: IOnboardingState, action: IOnboardingDispatch): IOnboardingState => {
  switch (action.type) {
    case 'setSteps':
      const steps: IOnboardingStep[] = action.data?.steps || [];
      const hideBar = steps.length > 0 ? steps[0].hideBarAtStart : false;

      const initState = {
        ...state,
        steps,
        currentStepIndex: 0,
        hideBar,
        stepStartTime: undefined,
        timeSpent: [],
        shouldTrack: action.data?.shouldTrack || false,
      };
      return {
        ...initState,
        allowNext: allowNextStep(initState, 'StepInitialization'),
      };
    case 'setCommands':
      const commands: ICommandType[] = filterCommandsFromOptions(action.data?.options) || [];
      return { ...state, activeCommand: pickRandomCommand(commands) };
    case 'Boot':
      if (isMobile()) return state;

      return {
        ...state,
        isActive: true,
        currentStepIndex: 0,
      };
    case 'Shutdown':
      return { ...state, isActive: false };
    case 'Exit':
      return exitOnboarding(state);
    case 'Previous':
      return moveToPreviousStep(state);
    case 'Next':
      return moveToNextStep(state);
    case 'Jump':
      if (typeof action.data?.stepIndex === 'number') {
        return moveToSpecificStep(state, action.data.stepIndex);
      }
      return state;
    case 'Start':
      if (hasNotStarted(state)) {
        return onOnboardingStart(state, action.data?.trigger);
      }
      return state;
    default:
      if (!state.isActive || hasNotStarted(state)) return state;
      let updatedState = state;

      if (action.type === 'CMDK') {
        if (!hasNextStep(state)) return finishOnboarding(state);
        updatedState = { ...updatedState, hideBar: !state.hideBar };
      }
      if (action.type === 'Enter' && updatedState.allowNext) {
        return moveToNextStep(updatedState);
      }

      const allowAction = allowNextStep(updatedState, action.type, action.data);
      const thisStep = getCurrentOnboardingStep(updatedState);
      if (allowAction && thisStep) {
        if (thisStep.nextBtnText) {
          return showActionForThisStep(updatedState);
        } else {
          return moveToNextStep(updatedState);
        }
      }

      return updatedState;
  }
};

export default OnboardingReducer;
