import { getSDK } from '@commandbar/internal/client/globals';
import { _reporter } from '@commandbar/internal/client/symbols';

/**
 * A hook for obtaining the currently active instance of the ErrorReporter, to use for manually capturing exceptions,
 * breadcrumb trails, session information, and diagnostic messages to an error reporting system.
 *
 * Example:
 *
 * ```typescript
 * const reporter = useErrorReporter();
 *
 * const handleThing = (err?: Error) => {
 *   reporter.breadcrumb('handling a thing');
 *   if (err) reporter.exception('failed to do the thing', err);
 * };
 *
 * return (<ChildComponent thingCallback={handleThing}>...</ChildComponent>);
 * ```
 */
export const useErrorReporter = () => {
  const reporter = getSDK()[_reporter];
  if (reporter === undefined) throw new Error('invariant violation: unreachable code');
  return reporter;
};
// NOTE: This is simply returning a property on the global object. If multiple instances of CommandBar are required then
// React.useContext() should be used instead to access a singleton instance that is initialized with the application.
