/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { jsx } from '@emotion/core';

import { IOrganizationType } from '@commandbar/internal/middleware/types';

import LauncherWidget from './LauncherWidget';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  isAdmin: boolean;
  organization: IOrganizationType;
  toggle: () => void;
}

const LauncherContainer = (props: IProps) => {
  React.useEffect(() => {
    dispatchCustomEvent('commandbar-launcher-ready', {});
  }, []);

  const launcherRef = React.useRef(null);

  const onLauncherClick = () => {
    props.toggle();
  };

  const onLauncherHover = React.useCallback(() => undefined, []);

  /********************* Start text handling **********************/

  if (props.organization.launcher_type === 'none') {
    return <div />;
  }

  return (
    <span>
      <LauncherWidget
        isAdmin={props.isAdmin}
        onClick={onLauncherClick}
        onHover={onLauncherHover}
        organization={props.organization}
        launcherRef={launcherRef}
      />
    </span>
  );
};

export default LauncherContainer;
