/** @jsx jsx */

import { jsx } from '@emotion/react';
/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';
import { IEndUserType, IOrganizationType, ICommandCategoryType } from '@commandbar/internal/middleware/types';
import ExecutionPath, { IExecutionPathState } from '../../engine/ExecutionPath';
import { ISearchFilter } from '../../engine/useCommandBar';
import Control from './input/Control';
import OptionList from '../../engine/OptionList';
// import EmptyMenu from './menu/EmptyMenu';
import useExpandedGroups from './menu/useExpandedGroups';
import { Option } from '../../engine/option';
import { getTriggerKey, osControlKey } from '../../../../internal/util/operatingSystem';
import OptionGroup from '../../engine/OptionGroup';
import MenuWrapper from './menu/MenuWrapper';
import { isEditorBeingSummoned } from '../../client_api/utils';
import Footer from './footer/Footer';
import ClientSearch from '../../engine/ClientSearch';

/*******************************************************************************/
/* Interfaces
/*******************************************************************************/

interface IProps {
  organization: IOrganizationType | undefined;
  executionPathState: IExecutionPathState;
  executionPathDispatch: any;
  inputText: string;
  onInputChange: any;
  onKeyDown: any;
  loading: boolean;
  options: any;
  menuIsOpen: boolean;
  emptyMessage: any;
  endUser: IEndUserType | undefined;
  categories: ICommandCategoryType[];
  openEditor: any;
  searchFilter: ISearchFilter | undefined;
  clearSearchFilter: () => void;
  autoFocus: boolean;
  dispatch: any;
}

function findFirstOptionIndex(options: (Option | OptionGroup)[]) {
  for (let i = 0; i < options.length; i++) {
    if (options[i] instanceof Option) {
      return i;
    }
  }
  return 1; // Default of 1 because 0 is a category
}

function getNextFocusedIndex(options: (Option | OptionGroup)[], focusedIndex: number, direction: 'up' | 'down') {
  switch (direction) {
    case 'up':
      for (let i = 1; i < options.length; i++) {
        const offset = focusedIndex - i;
        const index = offset >= 0 ? offset : options.length + offset;
        if (options[index] instanceof Option) {
          return index;
        }
      }
      break;
    case 'down':
      for (let i = 1; i < options.length; i++) {
        const index = (focusedIndex + i) % options.length;
        if (options[index] instanceof Option) {
          return index;
        }
      }
      break;
  }
  return focusedIndex;
}

const CommandSelect = (props: IProps, ref: any) => {
  const [focusedIndex, setFocusedIndex] = React.useState(0);
  const { expandedGroupKeys, toggleGroupExpansion } = useExpandedGroups();
  const { currentStepIndex } = ExecutionPath.currentStepAndIndex(props.executionPathState);
  const inputRef: any = React.useRef();

  // Tmp implementation to have the same api as react-select
  React.useImperativeHandle(ref, () => ({
    onInputChange: (newInput: string) => {
      props.onInputChange(newInput);
    },
    selectCurrentOption: () => {
      selectCurrentOption();
    },
    focus: () => {
      focusInput();
    },
    blur: () => {
      blurInput();
    },
  }));

  // Autofocus on each render. Copied from react-select.
  React.useEffect(() => {
    if (props.autoFocus) {
      focusInput();
    }
  });

  // Block option hover when a user presses a key. Copied from react-select.
  // This avoids the focused option from changing when options change because of passive mouse position
  // https://github.com/JedWatson/react-select/blob/b0411ff46bc1ecf45d9bca4fb58fbce1e57f847c/packages/react-select/src/Select.js#L1245
  const blockOptionHoverRef = React.useRef(false);

  const { currentStep } = ExecutionPath.currentStepAndIndex(props.executionPathState);
  const sortedOptions: (Option | OptionGroup)[] = React.useMemo(() => {
    return OptionList.sort(props.options, {
      organization: props.organization,
      categories: props.categories,
      user: props.endUser,
      inputText: props.inputText,
      hackContextSettings: props.executionPathState.hackContextSettings,
      activeObjects: props.executionPathState.activeObjects,
      expandedGroupKeys,
      currentStep,
    });
  }, [props.options, props.categories, expandedGroupKeys]);

  React.useEffect(() => {
    resetFocusedIndex();
  }, [currentStepIndex, props.inputText]);

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const blurInput = () => {
    inputRef?.current?.blur();
  };

  const changeFocus = (direction: 'up' | 'down') => {
    setFocusedIndex(getNextFocusedIndex(sortedOptions, focusedIndex, direction));
  };

  const selectCurrentOption = () => {
    const currentOption = sortedOptions[focusedIndex];
    if (currentOption instanceof Option) {
      onOptionSelect(currentOption);
    }
  };

  const controlKey = React.useMemo(osControlKey, []);
  const onKeyDown = (e: any) => {
    blockOptionHoverRef.current = true;

    if (e.key === 'ArrowDown') {
      changeFocus('down');
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.key === 'ArrowUp') {
      changeFocus('up');
      e.stopPropagation();
      e.preventDefault();
    }

    if (e.key === 'Enter' && !getTriggerKey(e)) {
      if (isEditorBeingSummoned(props.inputText)) {
        props.openEditor();
        e.stopPropagation();
        e.preventDefault();
      } else {
        selectCurrentOption();
      }
    }

    /*
     *  Emacs arrow key shortcuts:
     *      - ctrl + n (down)
     *      - ctrl + p (up)
     * */
    if (e.key === 'n' && e.ctrlKey && controlKey.toLowerCase() !== 'ctrl') {
      changeFocus('down');
      e.stopPropagation();
      e.preventDefault();
    }

    if (e.key === 'p' && e.ctrlKey && controlKey.toLowerCase() !== 'ctrl') {
      changeFocus('up');
      e.stopPropagation();
      e.preventDefault();
    }

    if (e.key === 'Tab') {
      changeFocus('down');
      e.stopPropagation();
      e.preventDefault();
    }

    // https://github.com/JedWatson/react-select/issues/3562#issuecomment-518841754
    if (e.key === 'Home') {
      e.stopPropagation();
      e.preventDefault();
      if (e.shiftKey) {
        e.target.selectionStart = 0;
      } else {
        e.target.setSelectionRange(0, 0);
      }
    }

    if (e.key === 'End') {
      e.stopPropagation();
      e.preventDefault();
      const len = e.target.value.length;
      if (e.shiftKey) {
        e.target.selectionEnd = len;
      } else {
        e.target.setSelectionRange(len, len);
      }
    }

    props.onKeyDown(e);
  };

  const onOptionSelect = async (opt: Option) => {
    const { isDisabled } = opt.optionDisabled;

    if (!isDisabled) {
      props.dispatch.setSearchFilter(undefined);
      opt.choose(props.executionPathState, props.executionPathDispatch);
      props.onInputChange('');
    }
  };

  const onOptionHover = (optionIndex: number) => {
    if (blockOptionHoverRef.current) {
      blockOptionHoverRef.current = false;
      return;
    }
    if (optionIndex === focusedIndex) {
      return;
    }
    setFocusedIndex(optionIndex);
  };

  const resetFocusedIndex = () => {
    setFocusedIndex(findFirstOptionIndex(sortedOptions));
  };

  /******************************************************************/

  const setTestMode = React.useCallback(
    (testMode: boolean) => {
      props.dispatch.executionPathDispatch({ type: 'setTestMode', testMode });
    },
    [props.dispatch.executionPathDispatch],
  );
  const setDashboard = React.useCallback(
    (dashboard: undefined | React.ReactNode) => {
      props.dispatch.setDashboard(dashboard);
    },
    [props.dispatch.setDashboard],
  );

  const getNextPageResults = (contextKey?: string) => {
    ClientSearch.getNextPageResults(
      props.executionPathState,
      props.inputText,
      (loading: boolean) => props.dispatch.setLoading(loading),
      contextKey,
    );
  };

  const isLoading = props.loading || !props.executionPathState.commandsLoaded;

  return (
    <div style={{ width: '100%' }} onKeyDown={onKeyDown}>
      <Control
        executionPathState={props.executionPathState}
        executionPathDispatch={props.executionPathDispatch}
        activeSearchFilter={props.searchFilter}
        clearSearchFilter={props.clearSearchFilter}
        inputText={props.inputText}
        onInputChange={props.onInputChange}
        isLoading={isLoading}
        inputRef={inputRef}
      />
      <div
        style={{
          height: props.menuIsOpen ? 'auto' : '0px',
          overflow: props.menuIsOpen ? 'visible' : 'hidden',
        }}
      >
        <MenuWrapper
          categories={props.categories}
          sortedOptions={sortedOptions}
          isLoading={isLoading}
          executionPathState={props.executionPathState}
          onInputSelect={onOptionSelect}
          focusedIndex={focusedIndex}
          onOptionHover={onOptionHover}
          expandedGroupKeys={expandedGroupKeys}
          toggleGroupExpansion={toggleGroupExpansion}
          setDashboard={setDashboard}
          openEditor={props.openEditor}
          emptyMessage={props.emptyMessage}
          isInputEmpty={props.inputText.length === 0}
          getNextPageResults={getNextPageResults}
        />
        <Footer
          setDashboard={setDashboard}
          isAdmin={props.executionPathState.isAdmin}
          testMode={props.executionPathState.testMode}
          setTestMode={setTestMode}
          organization={props.executionPathState.organization}
          // Custom for ClickUp footer
          currentStepIndex={currentStepIndex}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(CommandSelect);
