import { Step, IStepSelectionParameter } from './Step';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { IExecutionPathState } from '../ExecutionPath';
import { Option, ParameterOption } from '../option';

export class TextInputStep extends Step {
  public argument: IStepArgumentType;
  public selected: IStepSelectionParameter | null;

  constructor(argument: IStepArgumentType) {
    super('textinput');
    this.argument = argument;
    this.selected = null;
  }

  public select = (option: Option): Step => {
    if (!(option instanceof ParameterOption)) {
      return this;
    }

    // immutable
    const newStep = new TextInputStep(this.argument);

    newStep.selected = {
      type: 'parameter',
      category: null,
      data: option.parameter,
    };
    newStep.completed = this.completed;

    return newStep;
  };

  public selection = () => {
    if (this.selected === null) {
      return {};
    }

    if (this.selected.type !== 'parameter') {
      return {};
    }

    return { [this.argument.userDefinedValue]: this.selected.data };
  };

  public placeholder = () => {
    return `${this.argument.userDefinedName}`;
  };

  public breadcrumb = (shouldIncludeBreadcrumb: boolean): string | undefined => {
    if (!shouldIncludeBreadcrumb) {
      return undefined;
    }

    if (this.selected === null) {
      return this.argument.userDefinedName;
    }

    if (this.selected.type !== 'parameter') {
      return this.argument.userDefinedName;
    }

    return this.selected.data;
  };
  public fulfill = (_executionPathState: IExecutionPathState) => {
    this.completed = true;
    return this;
  };
}
