/* eslint-disable unused-imports/no-unused-vars */
import { Command } from '@commandbar/internal/middleware/command';
import axiosInstance from '@commandbar/internal/middleware/network';
import { Organization } from '@commandbar/internal/middleware/organization';
import { ICommandType, IGuideType } from '@commandbar/internal/middleware/types';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import Logger from '@commandbar/internal/util/Logger';
import _get from 'lodash.get';
import AnalyticsAPI from '../analytics/Analytics';
import Reporting, { SEARCH_TRIGGER } from '../analytics/Reporting';
import { closeBarAndReset, executeCommand, openBarWithOptionalText, setCommands } from '../engine/stateUtils';
import { ICommandBarDispatch, ICommandBarState } from '../engine/useCommandBar';
import { ErrorCode } from '../error/ErrorCode';
import { ErrorReporter } from '../error/ErrorReporter';
import { AddContextOptions } from '@commandbar/internal/client/AddContextOptions';
import {
  CASE,
  getCase,
  getFirstType,
  getSecondType,
  getThirdType,
  SECOND_ARG_INITVALUE_TYPE,
} from '@commandbar/internal/client/addContextValidators';
import { BootOptions, ContextLoader, Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import { isProxySDK } from '@commandbar/internal/client/CommandBarProxySDK';
import { CommandBarSDK as SDK } from '@commandbar/internal/client/CommandBarSDK';
import { dispose, isDisposable } from '@commandbar/internal/util/Disposable';
import { getProxySDK, getSDK } from '@commandbar/internal/client/globals';
import {
  _access,
  _configure,
  _configuration,
  _configUser,
  _dispatch,
  _dispose,
  _disposed,
  _eventMeta,
  _isProxy,
  _loadEditor,
  _perf,
  _programmaticTheme,
  _queue,
  _reloadCommands,
  _reloadOrganization,
  _report,
  _reporter,
  _search,
  _setDashboard,
  _setPreviewMode,
  _setTestMode,
  _showGuide,
  _showMessage,
  _state,
  _user,
  _orgConfig,
} from '@commandbar/internal/client/symbols';

// Create a concrete type out of the abstract type exported from internal; the implementation details of dispatch and
// state are irrelevant as far as the SDK interfaces are concerned - that only matters here. Where possible, use this
// type instead of the one exported from internal.
export type CommandBarSDK = SDK<ICommandBarState, ICommandBarDispatch>;

/** An array of SDK methods that should be tracked for analytics. */
const TRACK_METHODS: Array<keyof CommandBarSDK> = [
  _setDashboard,
  _showGuide,
  _showMessage,
  'addCallback',
  'addCommand',
  'addContext',
  'addEventHandler',
  'addRouter',
  'boot',
  'close',
  'execute',
  'isOpen',
  'open',
  'removeCallback',
  'removeCommand',
  'removeContext',
  'setContext',
  'setTheme',
  'shareCallbacks',
  'shareContext',
  'shutdown',
];

/**
 * "Upgrades" the `window.CommandBar` global object by augmenting it with the implementation of the entire private SDK.
 * The fact that the global window declaration uses symbols and a limited interface (CommandBarClientSDK) ensures that
 * private SDK methods are nearly invisible to the end user. This function may also be used after first init to update
 * the core `state` and `dispatch` objects while they are mutated throughout the lifecycle of the application.
 */
export function initSDK(__state: ICommandBarState, __dispatch: ICommandBarDispatch) {
  const proxy = getProxySDK();

  if (!isProxySDK(proxy)) {
    const sdk: CommandBarSDK = getSDK();
    sdk[_state] = __state;
    sdk[_dispatch] = __dispatch;
    return;
  }

  Logger.info('initializing client SDK...');

  const sdk = proxy as unknown as CommandBarSDK;

  const startAnalyticsTracking = (userId: string | undefined) => {
    sdk[_user] = userId;
    AnalyticsAPI.setActive(true);
    Reporting.session.start(sdk[_configuration].session);
  };

  const sdkMixin: CommandBarSDK = {
    addCallback(callbackKey, callbackFn) {
      if (callbackKey.startsWith('commandbar-')) {
        Logger.warn('callback name is reserved');
        return;
      }
      sdk[_dispatch].executionPathDispatch({
        type: 'addCallbacks',
        callbacks: { [callbackKey]: callbackFn },
      });
    },
    addCallbacks(callbacks) {
      if (
        !!Object.keys(callbacks).find((n: string) => {
          return n.includes('commandbar-');
        })
      ) {
        Logger.warn('One or more of the callback names are reserved.');
        return;
      }
      sdk[_dispatch].executionPathDispatch({
        type: 'addCallbacks',
        callbacks,
      });
    },
    async addCommand(command) {
      // Make sure command is specified correctly
      await Command.validateFromClient(command);

      // FIXME: we should add validators here
      // Some can be shared with the editor
      // (1) Ensure shortcut is invalid
      // (2) Ensure no command's shortcut collides with another command
      // Some are unique to programmatic commands
      // (1) Check that name is unique

      const formattedCommand: ICommandType = {
        text: command.text,
        template: command.template,
        name: command.name,
        tags: command.tags || [],
        shortcut_mac: command.shortcut_mac || [],
        shortcut_win: command.shortcut_win || [],
        explanation: command.explanation || '',
        sort_key: command.sort_key || null,
        arguments: command.arguments || {},
        availability: [],
        recommend_conditions: [],
        availability_rules: [],
        recommend_rules: [],
        confirm: '',
        shortcut: [],
        is_live: true,
        source: 'programmatic',
        id: Math.random(), // placeholder
        organization: -1,
        category: command.category || null,
        icon: command.icon || null,
        celebrate: command.celebrate || null,
        recommend_sort_key: null,
      };

      sdk[_dispatch].executionPathDispatch({
        type: 'addCommand',
        command: { ...formattedCommand },
      });
    },
    addContext(_key: string | Metadata, initialValue?: ContextLoader | unknown, options?: AddContextOptions) {
      const firstArgType = getFirstType(_key);
      const secondArgType = getSecondType(initialValue);
      const thirdArgType = getThirdType(options);

      const _case = getCase(firstArgType, secondArgType, thirdArgType);

      let context: Record<string, unknown> | string = {};
      const callbacks: Record<string, unknown> = {};
      let hackContextSettings: Record<string, unknown> = {};
      let callbackKey;

      switch (_case) {
        case CASE.OLD_ADD_CONTEXT:
          // OLD -- add object to context
          context = _key;
          sdk[_dispatch].executionPathDispatch({
            type: 'addContext',
            context,
          });
          return;

        case CASE.NEW_ADD_CONTEXT:
          const key = _key as string;
          const keyWithoutSpaces = key.replace(/\s+/g, '');

          // Handle second argument: initialValue
          switch (secondArgType) {
            // Static initial value -> add to context
            case SECOND_ARG_INITVALUE_TYPE.STATIC:
              context[key] = initialValue;
              break;
            // Function iniital value -> add to callbacks. Set context to an empty array.
            case SECOND_ARG_INITVALUE_TYPE.FUNCTION:
              const initialValueFnKey = `commandbar-initialvalue-${keyWithoutSpaces}`;
              callbacks[initialValueFnKey] = initialValue;
              context[key] = [];
              break;
          }

          // Handle third argument: meta options
          // Special case: search
          if (options?.searchOptions?.searchFunction) {
            const customSearchFunction = options?.searchOptions?.searchFunction;
            if (typeof customSearchFunction !== 'function') {
              Logger.error('CustomSearchFunction is not a function');
            }
            callbackKey = `commandbar-search-${keyWithoutSpaces}`;
            callbacks[callbackKey] = customSearchFunction;
          }

          // FIXME: Valdiate type for meta options
          // Handle the rest of the options
          const META_TO_HACKSETTINGS_MAP: Record<string, string> = {
            'renderOptions.descriptionKey': 'description_field',
            'renderOptions.labelKey': 'label_field',
            'renderOptions.defaultIcon': 'icon',
            'quickFindOptions.quickFind': 'search',
            'quickFindOptions.unfurl': 'unfurl',
            'quickFindOptions.autoExecute': 'auto_execute',
            'quickFindOptions.categoryName': 'name',
            'searchOptions.fields': 'search_fields',
            'searchOptions.sortFunction': 'sortFunction',
          };

          const hackContextSettingsForKey: Record<string, any> = {};
          Object.keys(META_TO_HACKSETTINGS_MAP).forEach((metaKey: string) => {
            const hackContextKey: string = META_TO_HACKSETTINGS_MAP[metaKey];
            const metaValue = _get(options, metaKey);
            if (metaValue !== undefined) {
              hackContextSettingsForKey[hackContextKey] = metaValue;
            }
          });

          if (Object.keys(hackContextSettingsForKey).length > 0) {
            hackContextSettings = { [key]: hackContextSettingsForKey };
          }

          sdk[_dispatch].executionPathDispatch({
            type: 'UTIL_setContextState',
            callbacks,
            context,
            hackContextSettings,
          });

          return;

        default:
          Logger.error(_case, _key, initialValue, options);
          return;
      }
    },
    addEventHandler(eventHandler) {
      Organization.read(sdk[_configuration].uuid).then((org) => {
        if (!!org?.allow_event_handlers) {
          sdk[_dispatch].executionPathDispatch({
            type: 'addCallbacks',
            callbacks: { 'commandbar-event-handler': eventHandler },
          });
        } else {
          Logger.warn('Event handlers are only available for enterprise customers. Please contact CommandBar.');
        }
      });
    },
    addRouter(routerFn) {
      sdk[_dispatch].executionPathDispatch({
        type: 'addCallbacks',
        callbacks: { 'commandbar-router': routerFn },
      });
    },
    addSearch(name, func) {
      const callbackKey = `commandbar-search-${name.replace(/\s+/g, '')}`;
      sdk[_dispatch].executionPathDispatch({
        type: 'addCallbacks',
        callbacks: { [callbackKey]: func },
      });
    },
    async boot(opts: BootOptions, metadata?: Metadata) {
      // Boot examples:
      //   Anonymous user: .boot()
      //   Old: .boot({id: 'someid', org: 'someorg'})
      //   New: .boot('someid')
      //   New w/ event attrs: .boot('someid', {org: 'someorg'})

      if (sdk[_configuration].uuid === undefined) {
        throw new Error(
          'CommandBar: Organization ID is undefined. Call config() to set the organization ID before calling boot().',
        );
      }

      let userId: string | undefined;
      let ctx = {};
      if (!opts) {
        userId = undefined;
      } else if (typeof opts === 'string') {
        userId = opts;
        ctx = { ...metadata };
      } else if (typeof opts === 'object') {
        ({ id: userId, ...ctx } = opts);
        userId = opts?.id;
      } else {
        Logger.error('Boot parameter should be a string or key-value pair');
      }

      sdk[_dispatch].executionPathDispatch({
        type: 'addContext',
        context: { ...ctx, id: userId },
      });

      // Activate the CommandBar
      sdk[_dispatch].setActive(true);

      // Store boot data to pass back to client eventHanlder
      sdk[_eventMeta] = { ...metadata };

      // CASE 0: Same user has been booted already. Don't re-load user
      if (userId && sdk[_user] === userId) {
        return;
      }

      // Case 1: Anonymous user
      if (!userId) {
        startAnalyticsTracking(undefined);
        return;
      }

      // CASE 2: New user boot
      try {
        // Fetch (and create if required) the endUser from our DB
        // ClickUp flag
        if (sdk[_configuration].uuid !== '10d7dc04') {
          const endUser = await Organization.userHasAccess(sdk[_configuration].uuid, userId);
          sdk[_dispatch].setEndUser(endUser);
        }
      } catch (e) {
        // Boot often can be called after login, right before a page redirect
        // Unless the client removes the onclick event from the source element (e.preventDefault)
        // then the request gets cancelled when the source element is removed from the page
        // We want to ignore these types of errors
      }

      // Wait till user has been fetched & created before we start tracking analytics
      startAnalyticsTracking(userId);
      AnalyticsAPI.identify({ user_attributes: JSON.stringify({ ...ctx, id: userId }) });
    },
    clearActiveObject(contextKey) {
      // only track events for non-admins
      sdk[_dispatch].executionPathDispatch({ type: 'clearActiveObject', contextKey });
    },
    close() {
      closeBarAndReset(sdk[_state], sdk[_dispatch]);
    },
    execute(id) {
      let command;
      if (typeof id === 'number') {
        command = sdk[_state].executionPathState.allCommands.find((c) => c.id === id);
      } else if (typeof id === 'string') {
        command = sdk[_state].executionPathState.allCommands.find((c) => c.name === id);
      }

      if (command) {
        const isDisabled = executeCommand(command, sdk[_state], sdk[_dispatch], undefined, () => {
          // REVIEW: Should this be going to analytics or Sentry? It may be more appropriate to throw instead.
          Logger.error(`Command with id ${id} is unavailable.`);
        });
        // REVIEW: This function is not supposed to have a return value. Is true/false meant to indicate whether the
        // command executed successfully? This should be documented and there needs to be a return value at the end if
        // so.
        return !isDisabled;
      } else {
        // report to client that the command wasn't found
        // REVIEW: Should this be going to analytics or Sentry? It may be more appropriate to throw instead.
        Logger.error(`Command with id=${id} wasn't found.`);
      }
    },
    isOpen() {
      return sdk[_state].executionPathState.visible;
    },
    onboard() {
      sdk[_dispatch].onboardingDispatch({ type: 'Boot' });
      sdk[_dispatch].onboardingDispatch({ type: 'Start', data: { trigger: 'client-api' } });
      sdk[_dispatch].executionPathDispatch({ type: 'setVisible', visible: true });
    },
    open(input, options) {
      openBarWithOptionalText(sdk[_state], sdk[_dispatch], SEARCH_TRIGGER.PROGRAMMATIC, {
        startingInput: input,
        categoryFilterID: options?.categoryFilter,
      });
    },
    removeCallback(callbackKey) {
      sdk[_dispatch].executionPathDispatch({
        type: 'removeCallback',
        toRemove: callbackKey,
      });
    },
    removeCommand(commandName) {
      // FIXME: what should we do if name doesn't correspond to a command?
      // TODO: We should also tell Proxy that it can remove the command
      sdk[_dispatch].executionPathDispatch({
        type: 'removeCommand',
        commandName,
      });
    },
    // FIXME: Should remove settings from hackContextSettings as well
    removeContext(keyToRemove) {
      sdk[_dispatch].executionPathDispatch({
        type: 'removeContext',
        toRemove: keyToRemove,
      });
    },
    setActiveObject(contextKey, objectLabel, categoryName) {
      // only track events for non-admins
      // Fixme check array of strings
      if (typeof contextKey !== 'string' || (typeof objectLabel !== 'string' && !Array.isArray(objectLabel))) {
        Logger.error('Arguments for setActiveObject are of the wrong type.');
        return false;
      }
      sdk[_dispatch].executionPathDispatch({ type: 'setActiveObject', contextKey, objectLabel, categoryName });
      return true;
    },
    setContext(context, meta) {
      if (meta !== undefined) {
        if (meta?.useCustom && meta?.customID) {
          LocalStorage.set('customcontext', meta.customID);
        } else if (meta?.useCustom) {
          LocalStorage.remove('customcontext');
        }
      } else {
        LocalStorage.remove('customcontext');
      }

      sdk[_dispatch].executionPathDispatch({
        type: 'setContext',
        context,
      });
    },
    setTheme(color) {
      sdk[_programmaticTheme] = color;

      sdk[_dispatch].executionPathDispatch({
        type: 'setBaseTheme',
        payload: color,
        metadata: { themeSource: 'setThemeFunction' },
      });
    },
    shareCallbacks() {
      return sdk[_state].executionPathState.callbacks;
    },
    shareContext() {
      return sdk[_state].executionPathState.context;
    },
    shutdown() {
      sdk[_dispatch].setActive(false);
      AnalyticsAPI.setActive(false);
      sdk[_user] = undefined;
      sdk[_dispatch].executionPathDispatch({ type: 'setContext', context: {} });
    },
    updateContextSettings(key, settings) {
      const requiredSettings = { search: false };
      sdk[_dispatch].executionPathDispatch({
        type: 'setHackContextSettings',
        hackContextSettings: { [key]: { ...requiredSettings, ...settings } },
      });
    },
    [_access]: undefined,
    [_configure](uid) {
      sdk[_configuration].uuid = uid;

      axiosInstance.defaults.baseURL = sdk[_configuration].api || process.env.REACT_APP_API_URL;

      sdk[_configUser]();
    },
    async [_configUser]() {
      const access = (window as any)?.CommandBarProxy?._access;

      if (!!access) {
        axiosInstance.defaults.headers['Authorization'] = 'JWT ' + access;

        // FIXME
        // Clone of /internal/middleware/network::Auth.user
        const { success, user } = await axiosInstance
          .get('/auth/current/')
          .then((result: any) => {
            return { success: true, user: result.data, error: undefined };
          })
          .catch((err: any) => {
            const error = err?.response?.data?.detail ?? 'Invalid.';
            return { success: false, error, user: undefined };
          });

        if (!success) {
          axiosInstance.defaults.headers['Authorization'] = null;
          (window as any).CommandBarProxy._access = '';
        }

        if (user?.organization === sdk[_configuration].uuid) {
          sdk[_dispatch].executionPathDispatch({ type: 'setIsAdmin', isAdmin: true });
          const _testMode = !!LocalStorage.get('testMode', '');
          sdk[_dispatch].executionPathDispatch({ type: 'setTestMode', testMode: _testMode });
        } else {
          sdk[_dispatch].executionPathDispatch({ type: 'setIsAdmin', isAdmin: false });
        }
      } else {
        sdk[_dispatch].executionPathDispatch({ type: 'setIsAdmin', isAdmin: false });
      }

      Organization.listCommands(sdk[_configuration].uuid).then((val) => {
        val.sort(compareObjs); // sort by sort_key before storing
        setCommands(sdk[_dispatch], val);
      });
      Organization.listCommandCategories(sdk[_configuration].uuid).then((val) => sdk[_dispatch].setCategories(val));
      Organization.read(sdk[_configuration].uuid).then((org) => {
        sdk[_dispatch].executionPathDispatch({
          type: 'setOrganization',
          organization: org,
          metadata: {
            themeSource: 'initial_load',
          },
        });
      });

      return;
    },
    [_configuration]: {
      api: '',
      editor: '',
      proxy: '',
      session: '',
      uuid: '',
      ...proxy[_configuration],
    },
    [_dispatch]: __dispatch,
    [_dispose]() {
      if (proxy[_disposed]) return;
      sdk.shutdown();
      Object.keys(proxy).forEach((k) => {
        const v = proxy[k];
        if (isDisposable(v)) dispose(v);
        delete proxy[k];
      });
      const warn = () => Logger.warn('attempted to dispatch after disposing sdk');
      const dispatch: Record<string, VoidFunction> = {};
      Object.keys(sdk[_dispatch]).forEach((k) => {
        dispatch[k] = warn;
      });
      sdk[_dispatch] = dispatch as unknown as ICommandBarDispatch;
      proxy[_disposed] = true;
      Object.assign(window, { CommandBar: undefined });
    },
    [_disposed]: false,
    [_eventMeta]: undefined,
    [_isProxy]: false,
    [_loadEditor]() {
      const configuration = sdk[_configuration];
      if (!configuration || !configuration.api || !configuration.uuid || !configuration.proxy) {
        Logger.warn('configuration details are missing; cannot open editor');
        return;
      }
      if (!!document.getElementById('commandbar-proxy-wrapper')) {
        Logger.warn('proxy wrapper not present; cannot open editor');
        return;
      }

      let override = '';
      let domain = configuration?.api ?? 'https://api.commandbar.com';
      if (configuration.proxy !== 'https://frames-proxy-prod.commandbar.com') {
        override = `?source=${configuration.proxy}`;

        if (configuration.proxy.includes('localhost')) {
          domain = 'http://localhost:8000';
        }
      }

      const endpoint = `${domain}/latest_aux/${configuration.uuid}${override}`;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = endpoint;
      if (endpoint.includes('localhost')) s.crossOrigin = 'anonymous';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode && x.parentNode.insertBefore(s, x);
    },
    [_orgConfig]: {
      ...proxy[_orgConfig],
    },
    // REVIEW: What is the right way to initialize this? It will always equal false as-is.
    [_perf]: false,
    [_programmaticTheme]: undefined,
    [_reloadCommands]() {
      Organization.listCommands(sdk[_configuration].uuid).then((val) => {
        val.sort(compareObjs); //sort by sort_key before storing
        setCommands(sdk[_dispatch], val);
      });
      Organization.listCommandCategories(sdk[_configuration].uuid).then((val) => sdk[_dispatch].setCategories(val));
    },
    [_reloadOrganization]() {
      sdk[_programmaticTheme] = undefined;

      Organization.read(sdk[_configuration].uuid).then((org) => {
        sdk[_dispatch].executionPathDispatch({
          type: 'setOrganization',
          organization: org,
          metadata: {
            themeSource: 'reload_organization',
          },
        });
      });
    },
    [_report](event, data) {
      AnalyticsAPI.track(event, { ...data, user_event: true });
    },
    [_reporter]: ErrorReporter.get(),
    [_search]: '',
    [_setDashboard](element) {
      // only track events for non-admins
      sdk[_dispatch].executionPathDispatch({ type: 'setVisible', visible: true });
      sdk[_dispatch].setDashboard(element);
    },
    [_setPreviewMode](on) {
      if (sdk[_state].executionPathState.isAdmin) {
        sdk[_dispatch].setPreviewMode(on);
      }
    },
    [_setTestMode](on) {
      // we could require admin authentication here
      // e.g. pass in a secret or admin credentials
      if (sdk[_state].executionPathState.isAdmin) {
        sdk[_dispatch].executionPathDispatch({ type: 'setTestMode', testMode: on });
      }
    },
    [_showGuide](eventName, preview) {
      let thisGuide = sdk[_state].guides.find((guide: IGuideType) => {
        return guide.event === eventName;
      });

      if (thisGuide === undefined) {
        // FIXME: What should we do here?
        return;
      }

      if (preview) {
        thisGuide = { ...thisGuide, preview: true };
      }

      sdk[_dispatch].setActiveGuide(thisGuide);
    },
    [_showMessage](eventName, preview) {
      sdk[_showGuide](eventName, preview);
    },
    [_state]: __state,
    [_user]: '',
  };

  TRACK_METHODS.forEach((k) => {
    // NOTE: The type assertion is unavoidable here (even with the key remapping introduced in TS 4.1).
    const s = sdkMixin as unknown as Record<keyof CommandBarSDK, VoidFunction>;
    s[k] = clientWrapper(String(k), s[k]);
  });

  Object.assign(sdk, sdkMixin);

  /*************************************************************************/
  /* Process queue
  /*************************************************************************/
  // Go through queue that was created on startup and perform each operations
  const queue = proxy[_queue];

  const callAndSplice = (fnName: keyof typeof sdk) => {
    const i = queue.findIndex((item) => item?.[0] === fnName);
    if (i < 0) return;
    (sdk[fnName] as Function)(...queue[i].slice(1));
    queue.splice(i, 1);
  };

  // Run config first
  callAndSplice(_configure);
  // Run boot second
  callAndSplice('boot');

  // Run the rest of the queue
  for (let i = 0; i < queue.length; i++) {
    const req = queue[i][0] as keyof typeof sdk;
    if (!(req in sdk)) {
      sdk[_reporter]?.exception(`CommandBar SDK method ${String(req)} is not defined.`);
      continue;
    }
    const args = queue[i];
    args.shift(); // pop off the command name
    (sdk[req] as Function)(...args);
  }

  queue.length = 0;
}

// Wrpaper function that (a) logs each use of client functions (b) catches any errors and reports them
const clientWrapper = <T extends unknown[], U>(name: string, fn: (...args: T) => U) => {
  return (...args: T) => {
    // Checking the presence of a local storage token because we want to log all command events if
    // the editor is in the process of logging in
    if (!!LocalStorage.get('editor', '')) {
      (window as any).CommandBarLogs = (window as any).CommandBarLogs || [];
      (window as any).CommandBarLogs.push({ name, args, url: window.location.href, time: Date.now() });
    }
    try {
      return fn(...args);
    } catch (e) {
      ErrorReporter.get().exception(ErrorCode.API, e, { name });
    }
  };
};
