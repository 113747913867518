/******************************************************************/
/* Imports
/******************************************************************/

export const getQueryStringType = (x: string): 'css' | 'xpath' => {
  if (x.substring(0, 2) === '//') {
    return 'xpath';
  } else {
    return 'css';
  }
};

/******************************************************************/

export const loadFromSelector = (x: string, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;
  try {
    const elem = document.querySelector(x);
    return elem;
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

/******************************************************************/

// https://stackoverflow.com/a/14284815/1569490
export const loadFromXPath = (x: string, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;
  try {
    const xPathRes = document.evaluate(x, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    if (xPathRes.singleNodeValue) {
      return xPathRes.singleNodeValue as HTMLElement;
    } else {
      return null;
    }
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

/******************************************************************/

export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
}

/******************************************************************/

export function isHidden(el: HTMLElement) {
  return el.offsetParent === null;
}

/******************************************************************/

export const checkSelector = (selector: string): boolean => {
  const type = getQueryStringType(selector);
  let element: HTMLElement | null;
  if (type === 'xpath') {
    element = loadFromXPath(selector) as HTMLElement | null;
  } else {
    element = loadFromSelector(selector) as HTMLElement | null;
  }

  return !!element;
};

/******************************************************************/

export function clickElement(selector: string, logError?: (x: string) => void) {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector) as HTMLElement | null;
  } else if (type === 'css') {
    element = loadFromSelector(selector) as HTMLElement | null;
  }

  if (!element) {
    if (logError) {
      logError(selector);
    }
    return;
  }

  element.click();
}

export const getElement = (selector: string): HTMLElement | undefined => {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector) as HTMLElement | undefined;
  } else if (type === 'css') {
    element = loadFromSelector(selector) as HTMLElement | undefined;
  }

  return element;
};

/******************************************************************/

export function clickElementsWithPause(index: number, selectors: string[], logError?: (x: string) => void) {
  if (index >= selectors.length) {
    return;
  }

  const type = getQueryStringType(selectors[index]);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selectors[index]) as HTMLElement | null;
  } else if (type === 'css') {
    element = loadFromSelector(selectors[index]) as HTMLElement | null;
  }

  if (!element) {
    if (logError) {
      logError(selectors[index]);
    }
    return;
  }

  element.click();
  setTimeout(() => {
    clickElementsWithPause(index + 1, selectors);
  }, 150);
}
