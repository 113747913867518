import React from 'react';
import { IExecutionPathState, IExecutionPathDispatchAction } from '../ExecutionPath';
import { ChooseCommandOrResourceStep } from '../step';
import _get from 'lodash.get';
import { IResourceSettings } from '@commandbar/internal/middleware/types';
import { isPrimitive } from '../../client_api/utils';
import Icon, { ICON_SIZE } from '@commandbar/internal/client/Icon';
import { ParameterOption } from './ParameterOption';
import { commandsForResource } from '../Available';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { TooltipWrapper } from './TooltipWrapper';
import Tag from '../../components/Tag';
import chroma from 'chroma-js';
import { MultiCommandsIndicator } from './MultiCommandsIndicator';
import { CommandOption } from './CommandOption';
import { ITheme } from '@commandbar/internal/client/theme';

export class ResourceOption extends ParameterOption {
  public category: { contextKey: string; label: string; limit?: number | null };
  public cachedCommands: CommandOption[] | undefined;

  constructor(
    executionPathState: IExecutionPathState,
    resource: any,
    label: string,
    resourceKey: string,
    searchOptions: IResourceSettings,
  ) {
    super(executionPathState, label, resource, searchOptions);
    this.category = {
      label: searchOptions.name ?? resourceKey,
      contextKey: resourceKey,
      limit: searchOptions?.max_options_count,
    };
  }

  /************************ Static functions ****************************/
  // Get the custom label field for a resource.
  //   Example: object is {title: string, address: string}
  //            User sets the label field to be "title" (stored in argument.label_field)
  //            The function returns "title". If label_field is undefined, default is "label"

  public static getLabel = (resource: any, key: string, executionPathState: IExecutionPathState) => {
    if (isPrimitive(resource)) return resource.toString();
    else {
      const labelField = ResourceOption.getLabelField(key, executionPathState);
      return _get(resource, labelField);
    }
  };
  public static getLabelField = (key: string, executionPathState: IExecutionPathState): string => {
    if (executionPathState.hackContextSettings) {
      const config = executionPathState.hackContextSettings[key];
      if (!!config && config.label_field) return config.label_field;
    }
    return 'label';
  };

  /**********************************************************************/

  /************************ Validators **********************************/
  public isValid = (_executionPathState: IExecutionPathState): { isValid: boolean; isValidReason: string } => {
    return { isValid: true, isValidReason: '' };
  };

  public isAvailable = (
    _executionPathState: IExecutionPathState,
  ): { isAvailable: boolean; isAvailableReason: string } => {
    return { isAvailable: true, isAvailableReason: '' };
  };

  public isExecutable = (
    _executionPathState: IExecutionPathState,
  ): { isExecutable: boolean; isExecutableReason: string } => {
    return { isExecutable: true, isExecutableReason: '' };
  };

  /**********************************************************************/

  public choose = (
    executionPathState: IExecutionPathState,
    executionPathDispatch: React.Dispatch<IExecutionPathDispatchAction>,
  ): void | IExecutionPathState => {
    // Note: We could choose to store the "SelectedResource" in the .selected field for this step
    // (which would be more consistent with the rest of the design)
    // Currently, we choose to store it on a custom field on ChooseCommandOrResourceStep (.resource)
    // This is so that we can avoid looping through the steps when calculating availability
    // and deciding whether there is an active resource.
    // A workstream to cache various state properties during each clock-tick would solve this
    // setSelected(selectedResource) // just because
    const newStep = new ChooseCommandOrResourceStep(this);

    const steps = [...executionPathState.steps, newStep];

    const updatedState = {
      ...executionPathState,
      steps,
    };

    if (executionPathState.simulation) {
      return updatedState;
    } else {
      executionPathDispatch({ type: 'updateFulfillAndRebase', updatedState });
      return;
    }
  };

  public icon = (theme: ITheme, isFocused: boolean, _executionPathState: IExecutionPathState) => {
    const preserveSVG = !!this.getReservedField('__preserveSVG');

    const { color, opacity, useDefaultSVGColor } = this.iconColorBase(theme, isFocused);

    const style = { color, opacity, minWidth: ICON_SIZE };

    const userDefinedIcon = this.getReservedField('icon');

    if (!!userDefinedIcon) {
      return (
        <Icon
          icon={userDefinedIcon}
          default={<div style={{ minWidth: ICON_SIZE, display: 'inline-block' }} />}
          style={style}
          useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
        />
      );
    }

    return (
      <Icon
        icon={this.searchOptions?.icon || null}
        default={<div style={{ minWidth: ICON_SIZE, display: 'inline-block' }} />}
        style={style}
        useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
      />
    );
  };

  private getCommands = (executionPathState: IExecutionPathState) => {
    if (!this.cachedCommands) {
      this.cachedCommands = commandsForResource(this.category.contextKey, executionPathState);
    }
    return this.cachedCommands;
  };

  public getDefaultCommandId = () => {
    return this.searchOptions?.default_command_id;
  };

  public addOn = (theme: ITheme, isFocused: boolean, executionPathState: IExecutionPathState) => {
    if (!this.getDefaultCommandId() || !isFocused) {
      // It's somewhat computationally intensive to do getCommands, so avoid it if we can
      return null;
    }
    const commands = this.getCommands(executionPathState);
    const moreThanOneCommand = commands.length > 1;

    const tagColor = chroma('#fff');
    const tagStyle = { height: '20px', minWidth: '20px', padding: '0 4px' };

    if (moreThanOneCommand) {
      return (
        <TooltipWrapper
          overlay={
            <div
              style={{
                fontFamily: theme.main.fontFamily,
                fontSize: theme.main.fontSize,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div>Show all commands</div>
              <div>
                <Tag color={tagColor} style={tagStyle}>
                  {osControlKey()}
                </Tag>
                +
                <Tag color={tagColor} style={tagStyle}>
                  Enter
                </Tag>
              </div>
            </div>
          }
          placement="right"
          align={{ offset: [20, 0] }}
          visible={isFocused && !!this.getDefaultCommandId()}
          delay={1000}
        >
          <MultiCommandsIndicator commandsNumber={commands.length} isFocused={isFocused} />
        </TooltipWrapper>
      );
    }

    return null;
  };
}
