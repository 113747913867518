const EDITOR_SUMMON_COMMANDS = ['revelio:editor', 'sudo editor', 'open editor'];

interface sortableObject {
  id: number;
  sort_key: number | null;
}

export function compareObjsBySortkey<T extends sortableObject>(a: T, b: T): number {
  if (a.sort_key === b.sort_key) {
    return a.id - b.id; // lower ids first
  } else {
    if (b.sort_key === null) return -1;
    if (a.sort_key === null) return 1;
    return a.sort_key - b.sort_key; // lower sortKeys first
  }
}

export function compareObjsByMapping<T extends sortableObject>(map: { [id: string]: number }, a: T, b: T): number {
  const aVal = map[a.id.toString()];
  const bVal = map[b.id.toString()];

  if (aVal === bVal) return 0;
  if (bVal === undefined) return -1; // if one obj has no value, return the other as higher
  if (aVal === undefined) return 1;
  return bVal - aVal; // higher values first
}

type comparatorFunc<T extends sortableObject> = (a: T, b: T) => number;

export function compareByMultiplePriorityFns<T extends sortableObject>(funcs: comparatorFunc<T>[], a: T, b: T) {
  // Loop through all functions in order of priority
  //      Once once of them returns a diff, retun that diff
  for (const func of funcs) {
    const val = func(a, b);
    if (val !== 0) return val;
  }
  // Values are equal
  return 0;
}

export function isEditorBeingSummoned(text: string): boolean {
  return EDITOR_SUMMON_COMMANDS.includes(text.toLowerCase());
}

export function isPrimitive(a: any) {
  if (typeof a === 'string') return true;
  if (typeof a === 'number') return true;
  if (typeof a === 'boolean') return true;
  return false;
}
