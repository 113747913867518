import { IExecutionPathState, isSelectStep } from '../ExecutionPath';
import { Option, ParameterOption } from '../option';
import { MultiSelectStep, SelectStep, Step } from '../step';

const CREATED_FIELD_NAME = '_createdByCommandBar';

// Is the create feature turned on?
const isEnabled = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return isSelectStep(currentStep) && !!currentStep.argument.allow_create;
};

// Is a given record created by CommandBar?
const recordIsCreated = (record: any) => {
  return !!record[CREATED_FIELD_NAME];
};

// helper to create an option
const _createOption = (
  executionPathState: IExecutionPathState,
  currentStep: SelectStep | MultiSelectStep,
  newInput: string,
) => {
  return new ParameterOption(
    executionPathState,
    `Create new ${currentStep.argument?.allow_create_label ?? currentStep.argument.userDefinedName}`,
    {
      [CREATED_FIELD_NAME]: true,
      value: newInput,
    },
  );
};

// add a created option a given list
const addCreatedOptionToList = ({
  options,
  executionPathState,
  currentStep,
  inputValue,
}: {
  options: Option[];
  executionPathState: IExecutionPathState;
  currentStep: SelectStep | MultiSelectStep;
  inputValue: string;
}) => {
  if (!isEnabled(currentStep)) {
    return options;
  }
  if (options.length > 0 && !(options[0] instanceof ParameterOption)) {
    return options;
  }

  if (!inputValue.length) {
    return options;
  }

  // If an exact match of input (case sensitive) exists, don't show create option
  const hasMatch = options.find((o) => o.label === inputValue);
  if (hasMatch) {
    return options;
  } else {
    return [...options, _createOption(executionPathState, currentStep, inputValue)];
  }
};

export const SelectOrCreate = {
  isEnabled,
  recordIsCreated,
  addCreatedOptionToList,
};
