/** @jsx jsx */
import * as React from 'react';

import { AiOutlineCrown } from 'react-icons/ai';

import ExecutionPath, { IExecutionPathState, isArgumentClientSearchActive } from '../../../engine/ExecutionPath';
import { SuggestCommand } from '../footer/SuggestCommand';
import { ChooseCommandOrResourceStep } from '../../../engine/step';
import { useTheme } from 'emotion-theming';
import { InterpolationWithTheme, jsx } from '@emotion/core';
import { maybePluralize } from '@commandbar/internal/middleware/utils';
import { ITheme } from '@commandbar/internal/client/theme';

interface IProps {
  executionPathState: IExecutionPathState;
  emptyMessage: any;
  setDashboard: (dashboard: undefined | React.ReactNode) => void;
  openEditor: any;
  isInputEmpty: boolean;
}

const emptyMenuStyles = (theme: ITheme): InterpolationWithTheme<any> => ({
  color: theme.main.color,
  fontSize: theme.main.fontSize,
  fontFamily: theme.main.fontFamily,
});

// FIXME: allow client to specify default way users can suggest a query
// FIXME: allow generator queries to specify optional empty message ("Student has no submission")
// For example, codePost might prefer to open up Intercom instead of email client
const EmptyMenu = (props: IProps) => {
  const { theme } = useTheme();
  const { currentStep } = ExecutionPath.currentStepAndIndex(props.executionPathState);

  const numCommands = props.executionPathState.allCommands.length;
  const showLinkToEditor = numCommands === 0;
  const nudgeTestMode =
    numCommands > 0 &&
    currentStep instanceof ChooseCommandOrResourceStep &&
    props.executionPathState.isAdmin &&
    props.isInputEmpty &&
    !props.executionPathState.testMode;

  let content;

  if (showLinkToEditor) {
    content = (
      <div>
        <div>
          <div
            style={{
              textAlign: 'center',
              paddingTop: '12px',
              fontSize: '18px',
              fontWeight: 550,
              color: theme.base.fontColor,
            }}
          >
            Welcome to your shiny new <span style={{ fontWeight: 700, color: theme.base.primary }}>CommandBar</span>!
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'middle', padding: '16px' }}>
            <div
              css={(theme) => ({
                borderRadius: '4px',
                padding: '8px 20px',
                backgroundColor: theme.theme.base.primary,
                color: 'white',
                cursor: 'pointer',
                opacity: 0.9,
                transition: 'all 0.3s ease',
                '&:hover': {
                  opacity: 1,
                },
              })}
              onClick={() => window.open('https://app.commandbar.com/quickstart', '_blank')}
            >
              Read the docs
            </div>
            <div style={{ width: '12px' }} />
            <div
              css={(theme) => ({
                borderRadius: '4px',
                padding: '8px 20px',
                backgroundColor: theme.theme.base.primary,
                color: 'white',
                cursor: 'pointer',
                opacity: 0.9,
                transition: 'all 0.3s ease',
                '&:hover': {
                  opacity: 1,
                },
              })}
              onClick={props.openEditor}
            >
              Open the editor
            </div>
          </div>
        </div>
      </div>
    );
  } else if (nudgeTestMode) {
    content = (
      <div>
        <p style={{ textAlign: 'center', padding: '20px 25px' }}>
          Click the <AiOutlineCrown style={{ fontSize: 24, color: theme.main.color, position: 'relative', top: 5 }} />{' '}
          icon to enter <b>test mode</b> and see your unpublished or unavailable{' '}
          {maybePluralize(numCommands, 'command')}.
        </p>
      </div>
    );
  } else if (currentStep instanceof ChooseCommandOrResourceStep) {
    content = (
      <div>
        {props.emptyMessage ? (
          props.emptyMessage
        ) : (
          <SuggestCommand setDashboard={props.setDashboard} organization={props.executionPathState.organization} />
        )}
      </div>
    );
  } else if (props.isInputEmpty && isArgumentClientSearchActive(props.executionPathState)) {
    content = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginTop: 20, marginBottom: 20 }}>Type to search</div>
      </div>
    );
  } else {
    content = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginTop: 20, marginBottom: 20 }}>No results</div>
      </div>
    );
  }

  return <div css={emptyMenuStyles(theme)}>{content}</div>;
};

export default EmptyMenu;
