import { ITheme } from '@commandbar/internal/client/theme';
import React, { CSSProperties, ReactNode } from 'react';
import { IExecutionPathState, IExecutionPathDispatchAction } from '../ExecutionPath';
import { IFuseSearchMatchType } from '../useCommandBar';

export abstract class Option {
  public type: string;
  public label: string;
  public value: string;
  public optionDisabled: { isDisabled: boolean; isDisabledReason: string };
  public fuseMatches: IFuseSearchMatchType[];
  public fuseScore: number | undefined;

  constructor(
    executionPathState: IExecutionPathState,
    type: string,
    label: string,
    value: string,
    _searchKeys?: string[],
  ) {
    this.type = type;
    this.label = label;
    this.value = value;
    // Careful calling this 'disabled' or 'isDisabled'; React-Select will catch that as a prop
    this.optionDisabled = { isDisabled: false, isDisabledReason: '' };
    this.fuseMatches = [];
    this.fuseScore = undefined;
  }

  // Given a string and a matched substring, highlight the matched substring
  public static highlight = (
    labelToHighlight: string,
    text: string | React.ReactNode,
    matches: IFuseSearchMatchType[],
    matchSearchScore: number | undefined,
    theme: ITheme,
    emphasize: boolean,
  ) => {
    if (typeof text !== 'string') {
      return text;
    }
    if (!matches[0] || matches[0].key !== labelToHighlight) return text;
    const pairLength = (pair: number[]) => pair[1] - pair[0] + 1;
    const findBestPair = (indices: number[][]) => {
      // fuse doesn't provide search scores for pairs, but the recommended way is to use the longest match
      // https://github.com/krisk/Fuse/issues/409
      let longestMatch = undefined;
      for (const pair of indices) {
        if (pair.length > 0) {
          if (!longestMatch) longestMatch = pair;
          if (pairLength(pair) > pairLength(longestMatch)) longestMatch = pair;
        }
      }
      if (longestMatch) {
        return { start: longestMatch[0], end: longestMatch[1] };
      }
      return null;
    };

    const highlightStyle: CSSProperties = {
      color: 'inherit',
      textDecoration: theme.textSearchMatch.textDecoration,
      textUnderlinePosition: emphasize
        ? theme.textSearchMatch.emphasisTextUnderlinePosition
        : theme.textSearchMatch.textUnderlinePosition,
      fontWeight: emphasize ? theme.textSearchMatch.emphasisFontWeight : theme.textSearchMatch.fontWeight,
    };

    const nodes = [];
    // FIXME: magic # for the long string length
    if (text.length < 100) {
      // Short strings -- show multiple matches
      let start = 0;
      const MIN_LENGTH_TO_HIGHLIGHT = 2;
      for (const pair of matches[0].indices) {
        if (pairLength(pair) >= MIN_LENGTH_TO_HIGHLIGHT) {
          nodes.push(text.substring(start, pair[0]));
          nodes.push(<span style={highlightStyle}>{text.substring(pair[0], pair[1] + 1)}</span>);
          start = pair[1] + 1;
        }
      }
      nodes.push(text.substring(start, text.length));
    } else {
      // Long strings -- Only show the longest match
      const bestMatch: { start: number; end: number } | false | null = findBestPair(matches[0].indices);
      if (!bestMatch) return text;
      const stringStartIndex = bestMatch.start > 20 ? bestMatch.start - 10 : 0;
      nodes.push(stringStartIndex > 0 && '...');
      nodes.push(text.substring(stringStartIndex, bestMatch.start));
      nodes.push(<span style={highlightStyle}>{text.substring(bestMatch.start, bestMatch.end)}</span>);
      nodes.push(text.substring(bestMatch.end, text.length));
    }

    return <span style={{ color: 'inherit' }}>{nodes}</span>;
  };

  public setMatches = (matches: any[], score: number | undefined): Option => {
    this.fuseMatches = matches;
    this.fuseScore = score;
    return this;
  };

  abstract isValid(executionPathState: IExecutionPathState): { isValid: boolean; isValidReason: string };

  // FIXME: Currently, all availability calculations should happen at the CommandBar iframe level rather than the Proxy level
  //        because they need to be synchronous (Available() gets called as a step within ExecutionPathReducer
  //        where async side effects would be considered an antipattern)
  abstract isAvailable(executionPathState: IExecutionPathState): { isAvailable: boolean; isAvailableReason: string };
  abstract isExecutable(executionPathState: IExecutionPathState): { isExecutable: boolean; isExecutableReason: string };

  public _isDisabled = (executionPathState: IExecutionPathState): { isDisabled: boolean; isDisabledReason: string } => {
    const disabled = (msg: string) => {
      return { isDisabled: true, isDisabledReason: msg };
    };

    const enabled = () => {
      return { isDisabled: false, isDisabledReason: '' };
    };

    const { isValid, isValidReason } = this.isValid(executionPathState);
    if (!isValid) {
      this.optionDisabled = disabled(isValidReason);
      return this.optionDisabled;
    }

    const { isAvailable, isAvailableReason } = this.isAvailable(executionPathState);
    if (!isAvailable) {
      this.optionDisabled = disabled(isAvailableReason);
      return this.optionDisabled;
    }

    const { isExecutable, isExecutableReason } = this.isExecutable(executionPathState);
    if (!isExecutable) {
      this.optionDisabled = disabled(isExecutableReason);
      return this.optionDisabled;
    }

    return enabled();
  };

  /**
   * When a user clicks on an option
   */
  abstract choose(
    executionPathState: IExecutionPathState,
    executionPathDispatch: React.Dispatch<IExecutionPathDispatchAction>,
  ): void;

  /**
   * Render the react-select row for this option
   */
  abstract renderOption(theme: ITheme, isFocused: boolean, executionPathState: IExecutionPathState): ReactNode;

  public iconColorBase = (theme: ITheme, isFocused: boolean) => {
    let color = isFocused ? theme.optionSelected.iconColor : theme.option.iconColor;
    const useDefaultSVGColor = !color;

    if (useDefaultSVGColor) {
      color = theme.base.fontColor;
    }

    const opacity = isFocused ? theme.optionSelected.iconOpacity : theme.option.iconOpacity;

    return { color, opacity, useDefaultSVGColor };
  };
}
