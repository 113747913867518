/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import Block, { IBreadcrumb, BREADCRUMB_TYPE } from './Block';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';

interface IProps {
  content: IBreadcrumb[];
  instruction: string | undefined;
  onRollBack: () => void;
}
export enum BREADCRUMB_POSITION {
  LEFT = 'left',
  TOP = 'top',
}

const moveBarRight = (offset: number) => {
  const bar = document.getElementById('commandbar-wrapper');
  if (bar) {
    const dialog = bar.querySelectorAll('.rc-dialog');
    if (dialog[0]) {
      // @ts-expect-error: FIXME Element type
      dialog[0].style.left = `${offset}px`;
    }
  }
};

const MIN_BREADCRUMBS_TO_SHOW_LEFT = 3;
const StatusBar = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const windowWidth = useWindowSize().width;
  const hideInstructions = windowWidth < 700;
  const position: BREADCRUMB_POSITION =
    windowWidth > 850 && props.content.length > MIN_BREADCRUMBS_TO_SHOW_LEFT
      ? BREADCRUMB_POSITION.LEFT
      : BREADCRUMB_POSITION.TOP;

  React.useEffect(() => {
    if (position === BREADCRUMB_POSITION.TOP) {
      moveBarRight(0);
    } else {
      moveBarRight(60);
    }
  }, [position]);

  if (!props.instruction && !(props.content.length > 0)) return <div />;

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingTop: theme.blocksContainer.paddingTop,
          paddingBottom: theme.blocksContainer.paddingBottom,
          paddingLeft: theme.blocksContainer.paddingLeft,
          paddingRight: theme.blocksContainer.paddingRight,
        }}
      >
        <div className={`commandbar-breadcrumbs-container commandbar-breadcrumbs-container-${position}`}>
          {props.content.map((block, i) => {
            if (props.content.length <= 2 && i > 0) return <div />;
            return (
              <Block
                key={`block-${i}`}
                content={block}
                onRollback={props.onRollBack}
                position={position}
                windowWidth={windowWidth}
              />
            );
          })}
        </div>
        {!hideInstructions && (
          <div
            style={{
              minWidth: 0,
              ...(position === BREADCRUMB_POSITION.LEFT && { position: 'absolute', right: 0, top: 0 }),
            }}
          >
            {props.instruction && (
              <div
                id={
                  // This id is used for testbed to figure out how to navigate through steps
                  props.instruction.includes('tab')
                    ? 'execution-instructions-tab'
                    : props.instruction.includes('text')
                    ? 'execution-instructions-text'
                    : 'execution-intructions'
                }
              >
                <Block
                  key="instruction-text"
                  content={{ text: props.instruction, type: BREADCRUMB_TYPE.INSTRUCTION }}
                  onRollback={props.onRollBack}
                  position={position}
                  windowWidth={windowWidth}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusBar;
