import 'react-app-polyfill/ie9';
import 'proxy-polyfill';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import createEmotion, { Emotion } from 'create-emotion';

import polyfills from '@commandbar/internal/util/polyfills';
import { ErrorReporter } from './error/ErrorReporter';
import ErrorBoundary from './components/ErrorBoundary';
import { getSDK, getProxySDK } from '@commandbar/internal/client/globals';
import { dispose } from '@commandbar/internal/util/Disposable';
import { _configure, _configuration, _orgConfig } from '@commandbar/internal/client/symbols';
import { deploy, loadedFromStaticBundle } from './client_api/deployment';

// Initialize polyfills
polyfills();
// The error reporter also gets initialized via `deploy`, but in this case it's desirable to initialize it early to
// catch errors during application bootup. The second call to initialize within `deploy` will be a no-op.
getProxySDK();
ErrorReporter.initialize();

export interface CommandBarContainerProps {
  orgID: string;
  orgName?: string;
  userID?: string;
}

/**
 * This component takes care of initializing and mounting CommandBar within your application. At minimum an orgID is
 * required. If a userID is not provided here then window.CommandBar.boot('user-id') must be called before the
 * CommandBar will become active.
 */
export const CommandBarContainer: React.FC<CommandBarContainerProps> = (props: CommandBarContainerProps) => {
  const [emotion, setEmotion] = React.useState<Emotion | undefined>(undefined);

  // teardown logic
  useEffect(
    () => () => {
      dispose(getSDK());
      deploy();
    },
    [],
  );

  useEffect(() => {
    getSDK()[_configure](props.orgID);
  }, [props.orgID]);

  useEffect(() => {
    getProxySDK()[_orgConfig] = {
      name: props.orgName,
      ...getProxySDK()[_orgConfig],
    };
  }, [props.orgName]);

  useEffect(() => {
    if (props.userID) {
      getSDK().boot(props.userID);
    }
  }, [props.userID]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setEmotion(createEmotion({ key: 'commandbar', container: document.getElementById('commandbar')! }));
  }, [setEmotion]);

  if (!emotion) {
    return null;
  }

  return (
    <ErrorBoundary>
      <App emotion={emotion} />
    </ErrorBoundary>
  );
};

export const init = async (orgID: string, orgName?: string) => {
  const container = deploy();
  const sdk = getProxySDK();
  const api = sdk[_configuration]?.api ?? 'https://api.commandbar.com';

  const has_access = await fetch(`${api}/organizations/${orgID}/p`)
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data;
    })
    .catch(() => {
      return false;
    });

  if (has_access === true) {
    ReactDOM.render(<CommandBarContainer orgID={orgID} orgName={orgName} />, container);
    return container;
  } else {
    console.error('This organization is not yet authorized. Please message the CommandBar team.');
    return;
  }
};

if (!loadedFromStaticBundle()) {
  const sdk = getProxySDK();
  const uid = sdk[_configuration]?.uuid;
  const org = sdk[_orgConfig]?.name;
  const props: CommandBarContainerProps = {
    orgID: '42424242',
    orgName: 'test-org',
    userID: 'test-user',
  };

  if (uid === undefined) {
    if (process.env.NODE_ENV === 'production') {
      // In production mode it should not be possible to have a missing org ID since the api endpoint that serves this
      // bundle is supposed to prepend a snippet that prepares the CommandBar global before this script is loaded. The
      // only sensible thing to do here is clear the value and force the app to hang, otherwise the end user will see
      // the test org which is almost certainly not desirable in production.
      console.warn('CommandBar configuration is missing!');
      props.orgID = '';
    } else {
      console.info('CommandBar init with development defaults: orgID=42424242, orgName=test-org, userID=test-user');
    }
  } else {
    props.orgID = uid;
    props.orgName = org;
    // The host application must call `boot()` to provide a user ID; nothing will render until that happens.
    props.userID = undefined;
  }

  // Final sanity check on the org ID: if it's falsy/undefined the application will never render and this is an
  // unrecoverable situation. This can happen if a) an injected snippet sets an invalid orgID because of a bug in the
  // API server, or b) this script is somehow run in production mode without a snippet at all.
  if (!props.orgID) {
    console.warn('CommandBar org ID is invalid; please contact CommandBar for assistance.', props.orgID);
  }

  const container = deploy();
  ReactDOM.render(<CommandBarContainer {...props} />, container);
}

export default init;
