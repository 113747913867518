/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

/* other library imports */
import 'rc-dialog/assets/index.css';
import Dialog from 'rc-dialog';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import chroma from 'chroma-js';

/* Internal imports + */
import Dashboard from './Dashboard';
import CommandSelect from './select/CommandSelect';
import KeyboardShortcutCheatsheet from './KeyboardShortcutCheatsheet';

import { ICommandBarState, ICommandBarDispatch } from '../engine/useCommandBar';

import { useTheme } from 'emotion-theming';

import ExecutionPath, {
  IExecutionPathState,
  isTimeStep,
  isFunctionStep,
  isSelectStep,
  isContextFunctionStep,
  isCommandOfActiveObject,
} from '../engine/ExecutionPath';
import { Option, CommandOption, ParameterOption, ResourceOption, UnfurledCommandOption } from '../engine/option';
import { SelectStep, MultiSelectStep, TextInputStep, ChooseCommandOrResourceStep } from '../engine/step';
import DateTime from '../engine/predefinedTypes/DateTime/DateTime';

import {
  ICommandType,
  isFunctionArgument,
  ICommandCategoryType,
  isContextArgument,
} from '@commandbar/internal/middleware/types';
import AnalyticsAPI from '../analytics/Analytics';
import Reporting from '../analytics/Reporting';
import Z from '@commandbar/internal/client/Z';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import ClientSearch from '../engine/ClientSearch';
import { hasInitialValueFnDefined } from '../engine/option/OptionValidate';
import { SelectOrCreate } from '../engine/features/selectOrCreate';
import { closeBarAndReset } from '../engine/stateUtils';
import { isEditorBeingSummoned } from '../client_api/utils';
import { getSDK } from '@commandbar/internal/client/globals';
import { _loadEditor } from '@commandbar/internal/client/symbols';
import InlineBar, { getFormFactor, BarFormFactor, INLINE_ROOT_ID } from './InlineBar';
import { debounce } from 'lodash';

/*********************************************************************************/

interface IProps {
  state: ICommandBarState;
  dispatch: ICommandBarDispatch;
  onClose: () => void;
}

enum ANIMATION_CLASS {
  opened = 'commandbar-opened', // show open animation
  selected = 'commandbar-selected', // show selected animation
}

const getDefaultCommandForCurrentStep = (executionPathState: IExecutionPathState) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);

  const activeResource = currentStep instanceof ChooseCommandOrResourceStep && currentStep.resource;
  if (!activeResource) {
    return;
  }

  const defaultCommandId = activeResource.getDefaultCommandId();

  // FLAG: can use the current options
  return executionPathState.options.find(
    (option) => option instanceof CommandOption && option.command.id === defaultCommandId,
  );
};

const CommandBar = (props: IProps) => {
  const [filteredOptions, setFilteredOptions] = React.useState(props.state.executionPathState.options);
  const [isCmdEnterPressed, setIsCmdEnterPressed] = React.useState(false);

  // Deadend tracking
  const [_cachedInput, _setCachedInput] = React.useState<string>(''); // Cached search input to report as deadend
  const [_isBackspacing, _setIsBackspacing] = React.useState(false); // Was the last keypress a backspace?
  const unmounting = React.useRef(false);
  const windowWidth = useWindowSize().width;

  React.useEffect(
    () => () => {
      unmounting.current = true;
    },
    [],
  );

  //////////////////////////////////////////////////////////////////////////
  // Click Outside
  //////////////////////////////////////////////////////////////////////////

  React.useLayoutEffect(() => {
    // Update fuse options
    props.dispatch.search.setCollection(props.state.executionPathState.options);
    // Special cases to handle dynamic option changes during typing
    handleAvailableOptionsChange();
  }, [props.state.executionPathState.options, props.state.searchFilter]);

  React.useLayoutEffect(() => {
    tryToAutoExecute(props.state.executionPathState);
  }, [props.state.executionPathState.options]);

  const tryToAutoExecute = (executionPathState: IExecutionPathState) => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);

    const activeResource = currentStep instanceof ChooseCommandOrResourceStep && currentStep.resource;
    if (!activeResource) return;

    const singleCommand = executionPathState.options.length === 1 && executionPathState.options[0];
    if (singleCommand) {
      singleCommand.choose(props.state.executionPathState, props.dispatch.executionPathDispatch);
      return;
    }

    const defaultCommand = getDefaultCommandForCurrentStep(executionPathState);
    if (defaultCommand && !isCmdEnterPressed) {
      defaultCommand.choose(props.state.executionPathState, props.dispatch.executionPathDispatch);
      return;
    }

    return;
  };

  const isSearchCallback = (callbackKey: string) => callbackKey.includes('commandbar-search-');

  const handleAvailableOptionsChange = () => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);

    // If pre-selected text input, set the input text
    if (currentStep instanceof TextInputStep && currentStep.selected?.data) {
      props.dispatch.setInputText(currentStep.selected?.data);
      setFilteredOptions(filterOptions(props.state.executionPathState.options, props.state.inputText));
    }

    if (isTimeStep(currentStep)) {
      const datetimeOptions = generateTimeOptions(props.state.inputText, currentStep);
      setFilteredOptions(datetimeOptions);
    } else if (isFunctionStep(currentStep) || isContextFunctionStep(currentStep, props.state.executionPathState)) {
      /**
       * ASYNC FUNCTION CODE. To-do: move this elsewhere
       */
      setFilteredOptions([]);
      getAsyncOptions(currentStep);
      handleAsyncPreselect(props.state.executionPathState);
    } else {
      // We only update options if the input is empty (to avoid jumpiness during typing) or if addSearch is defined
      if (props.state.inputText.length === 0) {
        // Empty
        setFilteredOptions(filterOptions(props.state.executionPathState.options, props.state.inputText));
      } else {
        // addSearch is defined
        if (Object.keys(props.state.executionPathState.callbacks).find(isSearchCallback)) {
          let newOptions = filterOptions(
            props.dispatch.search.search(props.state.inputText, props.state.executionPathState),
            props.state.inputText,
          );

          if (SelectOrCreate.isEnabled(currentStep)) {
            newOptions = SelectOrCreate.addCreatedOptionToList({
              executionPathState: props.state.executionPathState,
              options: newOptions,
              inputValue: props.state.inputText,
              currentStep: currentStep,
            });
          }
          setFilteredOptions(newOptions);
        }
      }
    }
  };

  const getAsyncOptions = async (currentStep: SelectStep | MultiSelectStep) => {
    let fnName;
    let asyncFn;

    if (isFunctionArgument(currentStep.argument)) {
      fnName = currentStep.argument.value;
      asyncFn = props.state.executionPathState.callbacks[fnName];
    }

    if (isContextArgument(currentStep.argument)) {
      fnName = `commandbar-initialvalue-${currentStep.argument.value}`;
      asyncFn = props.state.executionPathState.callbacks[fnName];
    }

    if (asyncFn !== undefined) {
      props.dispatch.setLoading(true);
      const chosenValues = ExecutionPath.selections(props.state.executionPathState);
      try {
        const optionValues = await asyncFn(chosenValues);
        const parameterOptions = optionValues.map((obj: any) =>
          ParameterOption.convertParameterToOption(props.state.executionPathState, obj, currentStep.argument),
        );

        props.dispatch.search.setCollection(parameterOptions);
        setFilteredOptions(parameterOptions);
      } finally {
        props.dispatch.setLoading(false);
      }
    }
  };

  // FIXME: Handler to update preselected values for steps when preselected values are async
  // This is a quick solution, we should probably just handle all preselection in the same place
  // Doesn't need to be done when commands are chosen
  const handleAsyncPreselect = async (executionPathState: IExecutionPathState) => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    if (!isSelectStep(currentStep)) {
      return;
    }
    if (!!currentStep?.argument?.preselected_key) {
      if (hasInitialValueFnDefined(currentStep?.argument?.preselected_key, executionPathState)) {
        // Abstract this out
        const fnName = `commandbar-initialvalue-${currentStep?.argument?.preselected_key}`;
        const asyncFn = props.state.executionPathState.callbacks[fnName];
        const chosenValues = ExecutionPath.selections(props.state.executionPathState);
        const results = await asyncFn(chosenValues);
        currentStep.setSelections(results);
      }
    }
  };

  /******************************************************************/
  /* Animations
  /******************************************************************/
  // We need to use use effects because the animation to show is dependent
  //   on previous state. If the foobar is open, and we remove an active command
  //   we don't want to re-show the opened animation
  const [animationClass, setAnimationClass] = React.useState<ANIMATION_CLASS | ''>(ANIMATION_CLASS.opened);

  React.useEffect(() => {
    // on every open, trigger the opened animation
    setAnimationClass(ANIMATION_CLASS.opened);
  }, [props.state.executionPathState.visible]);

  const handlePulse = () => {
    setAnimationClass(ANIMATION_CLASS.selected);
    setTimeout(() => {
      if (unmounting.current) return console.warn('attempted to set animation class after unmount');
      setAnimationClass('');
    }, 400);
  };

  const { currentStepIndex } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);
  React.useEffect(() => {
    handlePulse();
  }, [currentStepIndex]);

  /******************************************************************/
  /* Availability
  /******************************************************************/
  React.useEffect(() => {
    // Update the last_visible time of commands
    // Need to do this on every open, because changed variables (callbacks, context, urls) change availability
    if (
      props.state.executionPathState.visible &&
      !props.state.executionPathState.testMode &&
      filteredOptions.length > 0
    ) {
      const availableCommands = props.state.executionPathState.options
        .filter((opt) => opt instanceof CommandOption)
        .map((c: Option) => (c as CommandOption).command.id);
      AnalyticsAPI.availability(availableCommands);
    }
  }, [props.state.executionPathState.visible]);

  // On open run any function loaders that are defined for keys where quickfind is on
  // FIXME: consolidate this with the clientSearchHandler logic
  React.useEffect(() => {
    Object.keys(props.state.executionPathState.callbacks).forEach(async (callbackKey: string) => {
      if (callbackKey.startsWith('commandbar-initialvalue-')) {
        const key = callbackKey.split('commandbar-initialvalue-')[1];
        // FIXME: Testing just-in-time loaders for CH
        // https://cmd-k.slack.com/archives/G01ESNP86UT/p1620268566049500
        // const isQuickFindOn = props.state.executionPathState.hackContextSettings[key]?.search;
        // if (isQuickFindOn) {
        try {
          const results = await props.state.executionPathState.callbacks[callbackKey]();
          getSDK().addContext(key, results);
        } catch (e) {
          console.error(`Function loader caused an error. Key: ${key}`);
        }
      }
    });
  }, [props.state.executionPathState.visible]);

  React.useEffect(() => {
    if (!props.state.executionPathState.visible) {
      // clear search results on close
      clearSearchResults(props.state.executionPathState);
    }
  }, [props.state.executionPathState.visible]);

  /******************************************************************/
  /* React select handlers
  /******************************************************************/
  /*
    If client has defined advanced search, then the functions will be listed in CommandBar.callbacks
    with the key commandbar-search-{contextKey}

    For each search functions defined, call the function and add the result to context
   */

  const clearSearchResults = (executionPathState: IExecutionPathState) => {
    Object.keys(executionPathState.callbacks).forEach(async (callbackKey: string) => {
      if (callbackKey.includes('commandbar-search-')) {
        const contextKey = callbackKey.split('commandbar-search-')[1];
        ClientSearch.clear(contextKey);
        // Clear any debounced calls currently in progress from happening with a void call
        debouncedClientSearchCall(callbackKey, (_input: string) => [], '');
      }
    });
    props.dispatch.setLoading(false);
  };

  const clientSearchHandler = (executionPathState: IExecutionPathState, inputText: string) => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    // Search if:
    //    1. Top level
    //    2. Active argument is a searchable context key
    const isTopLevel =
      (currentStep instanceof ChooseCommandOrResourceStep && currentStep.resource === null) ||
      currentStep === undefined;
    const searchableFromTopLevel = Object.keys(executionPathState.hackContextSettings).filter(
      (key: string) => !!executionPathState.hackContextSettings[key].search,
    );

    const activeContextKey =
      isSelectStep(currentStep) && isContextArgument(currentStep.argument) ? currentStep.argument.value : undefined;

    Object.keys(executionPathState.callbacks).map(async (callbackKey: string) => {
      if (callbackKey.includes('commandbar-search-')) {
        const contextKey = callbackKey.split('commandbar-search-')[1];

        if (isTopLevel && !searchableFromTopLevel.includes(contextKey)) {
          return;
        }

        if (!isTopLevel && activeContextKey !== contextKey) {
          return;
        }

        if (inputText.length === 0) {
          // Clear search results if input text is empty
          ClientSearch.clear(contextKey);
          // Clear any debounced calls currently in progress from happening with a void call
          debouncedClientSearchCall(callbackKey, (_input: string) => [], inputText);
          props.dispatch.setLoading(false);
        } else {
          props.dispatch.setLoading(true);
          try {
            const searchResults = await debouncedClientSearchCall(
              callbackKey,
              executionPathState.callbacks[callbackKey],
              inputText,
            );
            ClientSearch.set(contextKey, searchResults);
          } catch (e) {}
          props.dispatch.setLoading(false);
        }
      }
    });
  };

  const debouncedClientSearchCall = React.useCallback(
    AwesomeDebouncePromise(
      async (key: string, fn: (input: string, pagination?: any) => any[], inputText: string, pagination?: any) =>
        fn(inputText, pagination),
      150,
      {
        onlyResolvesLast: true, // only resolves the last promise called
        // key required if we have multiple object searches happening
        key: (key: string, _fn: (input: string, pagination?: any) => any[], _inputText: string) => key,
      },
    ),
    [],
  );

  const onInputChange = (newInput: string, event: { action: string } | undefined) => {
    if (event?.action === 'input-blur' || event?.action === 'menu-close') {
      return;
    }
    const { currentStep } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);
    // By default, set-value is triggered when enter is pressed in the bar
    // In multiselect scenarios, we don't want to change the text or update the options when enter is pressed
    // Explanation: https://www.loom.com/share/648a4f1550f44309a80a30f3fc95af8e
    if (currentStep instanceof MultiSelectStep && event?.action === 'set-value') {
      return;
    }

    // Reporting
    ifClosedReportDeadend(newInput, event);
    ifBackspacedReportDeadend(newInput);

    if (!props.state.onboardingState.isActive) {
      reportKeystroke(newInput);
    }
    props.dispatch.setInputText(newInput);

    let newOptions: Option[];
    if (isEditorBeingSummoned(newInput)) {
      newOptions = [];
      setFilteredOptions(newOptions);
    } else if (currentStep !== undefined && currentStep instanceof TextInputStep) {
      newOptions = [createCustomTextOption(currentStep, newInput)];
      setFilteredOptions(newOptions);
    } else if (isTimeStep(currentStep)) {
      newOptions = generateTimeOptions(newInput, currentStep);
      setFilteredOptions(newOptions);
    } else {
      // Base case, do a search
      if (props.dispatch.search.getCollection().length > 500) {
        searchOptionsDebounced(newInput, props.state.executionPathState);
      } else {
        searchOptions(newInput, props.state.executionPathState);
      }
    }
    clientSearchHandler(props.state.executionPathState, newInput);
  };

  const searchOptions = (input: string, executionPathState: IExecutionPathState) => {
    let newOptions = filterOptions(props.dispatch.search.search(input, executionPathState), input);
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    if (SelectOrCreate.isEnabled(currentStep)) {
      newOptions = SelectOrCreate.addCreatedOptionToList({
        executionPathState: executionPathState,
        options: newOptions,
        inputValue: input,
        currentStep: currentStep,
      });
    }
    setFilteredOptions(newOptions);
  };
  const searchOptionsDebounced = React.useMemo(() => debounce(searchOptions, 150), []);

  const categoriesWithSettingHideBeforeSearch = React.useCallback(() => {
    return props.state.categories
      .filter((category: ICommandCategoryType) => {
        return category.setting_hide_before_search;
      })
      .map((category: ICommandCategoryType) => {
        return category.id;
      });
  }, [props.state.categories]);

  // General filter condiitons
  //      - Don't show resources in empty state
  const filterOptions = (options: Option[], currentInput: string) => {
    const categoriesToHideBeforeSearch = categoriesWithSettingHideBeforeSearch();

    const { currentStep } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);

    if (props.state.searchFilter) {
      return props.state.searchFilter.filter(options);
    }

    return options.filter((opt) => {
      if (isCommandOfActiveObject(opt, props.state.executionPathState.activeObjects)) {
        return true;
      }
      if (opt instanceof UnfurledCommandOption && currentInput.length === 0) {
        // Case: We are at the top-level; When the input is empty, we should not show UnfurledCommands
        // unless they are specified by the ActiveObject
        return false;
      }
      if (opt instanceof ResourceOption) {
        // Empty state (don't show resources)
        if (
          !opt.searchOptions?.showResources &&
          currentStep instanceof ChooseCommandOrResourceStep &&
          currentStep.resource === null &&
          currentInput.length === 0
        ) {
          return false;
        }
        return true;
      }

      if (opt instanceof CommandOption && currentInput.length === 0) {
        if (
          !!opt.command.category &&
          categoriesToHideBeforeSearch.includes(opt.command.category) &&
          !opt.isRecommended
        ) {
          return false;
        }
      }

      return true;
    });
  };

  /**
   * HANDLER: ON KEY DOWN EVENT
   */
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && !!getTriggerKey(e)) {
      const { currentStep } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);
      if (currentStep instanceof MultiSelectStep) {
        // If current arg is multi-select, execute it
        executeIfMultiSelect(e);
      } else {
        // If not, select the current option
        setIsCmdEnterPressed(true);
        props.state.refContainer.current?.selectCurrentOption();
      }
      e.stopPropagation();
      e.preventDefault();
    } else {
      setIsCmdEnterPressed(false);
    }

    if (e.key === 'Backspace') {
      if (!_isBackspacing) {
        _setIsBackspacing(true);
        _setCachedInput(props.state.inputText);
      }
    } else {
      // Reset any dead end backspace tracking
      if (_isBackspacing) _setIsBackspacing(false);
      if (_cachedInput) _setCachedInput('');
    }
  };

  /******************************************************************/
  /* Handler utility functions
  /******************************************************************/

  /* Options changing */

  const createCustomTextOption = (currentStep: TextInputStep, input: string) => {
    return new ParameterOption(props.state.executionPathState, currentStep.argument.userDefinedName, input);
  };

  const generateTimeOptions = (newInput: string, currentStep: SelectStep | MultiSelectStep) => {
    // @ts-expect-error FIXME argument types
    return DateTime.search({ inputValue: newInput, type: currentStep.argument.dateTimeArgumentTypeId }).map(
      (suggestion: any) => {
        return new ParameterOption(props.state.executionPathState, suggestion.label, suggestion);
      },
    );
  };

  /* Reporting */

  const ifClosedReportDeadend = (newInput: string, event: any) => {
    const didInputClear = newInput.length === 0 && props.state.inputText.length > 0;
    const didMenuClose = event?.action === 'menu-close';

    if (didInputClear && didMenuClose) {
      Reporting.deadend(props.state.inputText, 'Resetting search', props.state.executionPathState);
    }
  };

  const ifBackspacedReportDeadend = (newInput: string) => {
    if (newInput.length === 0 && _isBackspacing && _cachedInput.length > 0) {
      Reporting.deadend(_cachedInput, 'Backspaced', props.state.executionPathState);
      _setCachedInput('');
    }
  };

  const reportKeystroke = React.useCallback(
    AwesomeDebouncePromise((input: string) => {
      Reporting.search.inputChange(input, props.state.executionPathState);
    }, 500),
    [],
  );

  /* Editor opening */

  const checkForEditor = () => {
    return document.getElementById('commandbar-editor-drawer');
  };

  const clickEditorHandle = () => {
    const drawerToggle = document.getElementById('commandbar-editor-drawer')?.querySelector('.drawer-handle');
    if (drawerToggle) {
      (drawerToggle as HTMLElement).click();
    }
  };

  const openEditor = () => {
    const isCommandBarCom = window.location.href.includes('app.commandbar.com');

    if (isCommandBarCom && props.state.inputText !== 'revelio:editor') {
      props.dispatch.setDashboard(
        <div>
          <div>Whoa, whoa there!</div>
          <div>{"How about loading the editor on your own site, why don't ya 😃"}</div>
        </div>,
      );
      return;
    }

    if (checkForEditor()) {
      clickEditorHandle();
      props.dispatch.executionPathDispatch({ type: 'setVisible', visible: false });
      return;
    }
    // Poll for the editor element, close the bar once found
    const interval = setInterval(() => {
      if (unmounting.current) return console.warn('attempted to check for editor after unmount');
      const foundEditor = checkForEditor();
      if (!!foundEditor) {
        clearInterval(interval);

        setTimeout(() => {
          if (unmounting.current) return console.warn('attempted to show editor after unmount');
          clickEditorHandle();
          props.dispatch.setDashboard(undefined);
          props.dispatch.executionPathDispatch({ type: 'setVisible', visible: false });
        }, 500);
      }
    }, 500);

    let dashboard;
    if (chroma(theme.main.background).luminance() > 0.8) {
      dashboard = (
        <div>
          <img alt="light paperplane" src="https://staticassets.commandbar.com/commandbar/paperplane_white_300.gif" />
        </div>
      );
    } else {
      dashboard = (
        <div>
          <img alt="dark paperplane" src="https://staticassets.commandbar.com/commandbar/paperplane_300.gif" />
        </div>
      );
    }

    // While waiting for the editor, play an animation
    props.dispatch.setDashboard(dashboard);

    // If the editor doesn't show up within MAX_WAIT_TIME, then show an error message
    const MAX_WAIT_TIME = 6000; // ms
    setTimeout(() => {
      if (unmounting.current) return console.warn('attempted to show editor loading error after unmount');
      if (!checkForEditor()) {
        clearInterval(interval);
        props.dispatch.setDashboard(<div>Something went wrong... Please try again.</div>);
      }
    }, MAX_WAIT_TIME);

    // Let the user enjoy the animation for a hot second before loading the editor :)
    setTimeout(() => {
      if (unmounting.current) return console.warn('attempted to load editor after unmount');
      getSDK()[_loadEditor]();
    }, 500);
  };

  /* Execution */

  const executeIfMultiSelect = (e: any) => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(props.state.executionPathState);
    if (currentStep instanceof MultiSelectStep) {
      props.dispatch.executionPathDispatch({
        type: 'updateFulfillAndRebase',
        updatedState: props.state.executionPathState,
      });
      // React-select auto updates inputText on select, but here we need to manually trigger it
      //   because we're manually triggering an execution
      // If we don't do this, we'll have a bug where the inputText doesn't reset across steps,
      //   and the options won't be refreshed because we only refresh options if the input is empty.
      props.dispatch.setInputText('');

      // The default tab action is to unfocus the input. If there are more args, we want to keep the input focused
      e.preventDefault();
      e.stopPropagation();
    }
  };

  /******************************************************************/

  const getCommandOptionCommands = (options: Option[]): ICommandType[] => {
    return options
      .filter((option: Option) => {
        return option instanceof CommandOption;
      })
      .map((option: Option) => {
        return (option as CommandOption).command;
      });
  };

  const { theme } = useTheme();

  let container: HTMLElement | false = false;
  if (document.getElementById('commandbar-container') !== null) {
    container = document.getElementById('commandbar-container') ?? false;
  }

  const formFactor: BarFormFactor = getFormFactor(props.state.executionPathState.organization);

  const hideMenu =
    !!props.state.dashboard || (formFactor === BarFormFactor.INLINE && !props.state.executionPathState.visible);

  const content = (
    <div
      id="commandbar-home"
      // Stop propagation of any key events that originate from the bar, to avoid triggering user keyboard listeners
      // Note: This stops any listeners that are set in the bubbling phase (the default), not the capturing phase
      // There isn't a way for us to intercept events before capturing listeners that are set prior to when our listeners are set
      onKeyUp={(e: any) => {
        e.stopPropagation();
      }}
      onKeyPress={(e: any) => {
        e.stopPropagation();
      }}
      onKeyDown={(e: any) => {
        e.stopPropagation();
      }}
    >
      {!props.state.dashboard ? (
        <CommandSelect
          organization={props.state.executionPathState.organization}
          executionPathState={props.state.executionPathState}
          executionPathDispatch={props.dispatch.executionPathDispatch}
          inputText={props.state.inputText}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          options={filteredOptions}
          menuIsOpen={!hideMenu}
          loading={props.state.loading}
          emptyMessage={props.state.emptyMessage}
          endUser={props.state.endUser}
          openEditor={openEditor}
          categories={props.state.categories}
          searchFilter={props.state.searchFilter}
          clearSearchFilter={() => props.dispatch.setSearchFilter(undefined)}
          autoFocus={formFactor !== BarFormFactor.INLINE}
          dispatch={props.dispatch}
          ref={props.state.refContainer}
        />
      ) : (
        <Dashboard onClose={() => closeBarAndReset(props.state, props.dispatch)}>{props.state.dashboard}</Dashboard>
      )}
    </div>
  );

  switch (formFactor) {
    case BarFormFactor.INLINE:
      const rootElement = document.getElementById(INLINE_ROOT_ID);
      if (rootElement) {
        return (
          <InlineBar
            close={props.onClose}
            open={() => {
              props.dispatch.executionPathDispatch({ type: 'setVisible', visible: true });
            }}
            rootElement={rootElement}
          >
            {content}
          </InlineBar>
        );
      }
    // Design decision: if no inlineContainer is found, render the modal
    // eslint-disable-next-line no-fallthrough
    case BarFormFactor.MODAL:
    default:
      return (
        <>
          <Dialog
            visible={props.state.previewMode ? true : props.state.executionPathState.visible}
            closable={false}
            destroyOnClose={true}
            keyboard={false}
            maskStyle={{ backgroundColor: 'rgba(55, 55, 55, 0.95)' }}
            bodyStyle={{
              backgroundColor: theme.main.background,
              borderRadius: theme.main.borderRadius,
              padding: '0px 0px 0px 0px',
              boxShadow: theme.main.boxShadow,
              zIndex: 1,
              margin: '0px 5px', // mobile screen padding
            }}
            wrapClassName={'commandbar-modal'}
            style={{
              fontFamily: theme.main.fontFamily,
              backgroundColor: 'transparent',
              top: theme.bar.top,
              width: windowWidth < parseInt(theme.bar.width, 10) ? undefined : theme.bar.width,
            }}
            className={`commandbar-dialog ${animationClass}`}
            zIndex={Z.Z_EDITOR}
            getContainer={container}
            mask={props.state.onboardingState.isActive}
            onClose={props.onClose}
          >
            {content}
          </Dialog>
          {props.state.showKeyboardShortcutCheatsheet && (
            <KeyboardShortcutCheatsheet
              visible={props.state.showKeyboardShortcutCheatsheet}
              setVisible={props.dispatch.setShowKeyboardShortcutCheatsheet}
              commands={getCommandOptionCommands(props.state.executionPathState.options)}
              availableCommands={props.state.executionPathState.options.map((opt: any) =>
                opt.command ? opt.command : undefined,
              )}
              categories={props.state.categories}
            />
          )}
        </>
      );
  }
};

export default CommandBar;
