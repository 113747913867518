import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import OptionGroup from '../../../engine/OptionGroup';
import { MdExpandMore } from 'react-icons/md';
import { ITheme } from '@commandbar/internal/client/theme';
interface IProps {
  group: OptionGroup;
  themeContext: ITheme;
  addTopPadding: boolean;

  // group expansion
  expandedGroupKeys: string[];
  toggleExpansion: (key: string) => void;
}

const OptionGroupHeader = (props: IProps) => {
  const { group, themeContext, toggleExpansion, expandedGroupKeys } = props;

  return (
    <div
      style={{
        paddingTop: themeContext.categoryHeader.paddingTop,
        paddingBottom: themeContext.categoryHeader.paddingBottom,
        paddingLeft: themeContext.categoryHeader.paddingLeft,
        paddingRight: themeContext.categoryHeader.paddingRight,
        color: themeContext.categoryHeader.color,
        fontSize: themeContext.categoryHeader.fontSize,
        opacity: themeContext.categoryHeader.opacity,
        fontWeight: themeContext.categoryHeader.fontWeight,
        lineHeight: themeContext.categoryHeader.lineHeight,
        background: themeContext.categoryHeader.background,
        borderTop: themeContext.categoryHeader.borderTop,
        marginTop: props.addTopPadding ? themeContext.menu.spaceBetweenCategories : 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        userSelect: 'none',
        maxHeight: OptionGroup.getDefaultHeaderHeight(themeContext, props.addTopPadding),
      }}
      key={group.key}
    >
      <div style={{ display: 'flex', color: 'inherit' }}>
        {group.type === 'RECOMMENDED' && (
          <span>
            <AiFillStar
              style={{
                fontSize: parseInt(themeContext.categoryHeader.fontSize, 10) - 1,
                color: themeContext.base.primary,
                marginRight: 5,
              }}
            />
          </span>
        )}
        <span style={{ height: '100%', color: 'inherit' }}>{group.name}</span>
      </div>
      {group.isOverLimit() && (
        <span
          style={{ display: 'flex', cursor: 'pointer', color: 'inherit' }}
          onClick={() => {
            toggleExpansion && toggleExpansion(group.key);
          }}
        >
          <span
            style={{
              fontWeight: 400,
              color: 'inherit',
            }}
          >
            {group.canExpand(expandedGroupKeys) ? 'See more' : 'See less'}
          </span>
          <span style={{ marginLeft: 2 }}>
            <MdExpandMore
              className={
                group.canExpand(expandedGroupKeys) ? 'commandbar-category-expand' : 'commandbar-category-collapse'
              }
            />
          </span>
          <span
            style={{
              marginLeft: 10,
              color: 'inherit',
            }}
          >
            <span style={{ fontWeight: 700 }}>{group.size}</span>{' '}
          </span>
        </span>
      )}
    </div>
  );
};

export default OptionGroupHeader;
