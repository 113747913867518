/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { AiOutlineClose } from 'react-icons/ai';
import Tooltip from 'rc-tooltip';
import Tag from '../../Tag';
import chroma from 'chroma-js';
import { BREADCRUMB_POSITION } from './StatusBar';
import './Breadcrumb.css';
import { ITheme } from '@commandbar/internal/client/theme';

export interface IBreadcrumb {
  text: string;
  type: BREADCRUMB_TYPE;
}

export enum BREADCRUMB_TYPE {
  SELECTED,
  LAST_SELECTED,
  CURRENT_ARGUMENT,
  PLACEHOLDER,
  INSTRUCTION,
}

const getBlockSuffix = (type: BREADCRUMB_TYPE, rollback: () => void): any => {
  switch (type) {
    case BREADCRUMB_TYPE.LAST_SELECTED:
      return (
        <div style={{ padding: '6px 0px' }}>
          <span
            style={{
              marginRight: 5,
              display: 'flex',
              alignItems: 'center',
              width: 10,
              zIndex: 2,
              position: 'relative',
            }}
          >
            <Tooltip
              prefixCls="commandbar-tooltip"
              overlay={
                <span>
                  <Tag style={{ height: 12, padding: '4px 4px' }} color={chroma('rgb(174, 177, 221)')}>
                    ESC
                  </Tag>{' '}
                  to remove last selection
                </span>
              }
              placement="top"
              align={{ offset: [0, -15] }}
            >
              <AiOutlineClose style={{ cursor: 'pointer', fontSize: 11, position: 'absolute' }} onClick={rollback} />
            </Tooltip>
          </span>
        </div>
      );
    default:
      return <div />;
  }
};

const getMaxWidth = (type: BREADCRUMB_TYPE, position: BREADCRUMB_POSITION): number => {
  switch (type) {
    case BREADCRUMB_TYPE.INSTRUCTION:
      return 250;
    default:
      if (position === BREADCRUMB_POSITION.LEFT) {
        return 150;
      } else {
        return 250;
      }
  }
};

export const getBlockBackgroundStyle = (
  theme: { theme: ITheme },
  type: BREADCRUMB_TYPE,
  position: BREADCRUMB_POSITION,
): any => {
  switch (type) {
    case BREADCRUMB_TYPE.INSTRUCTION:
      return {
        marginRight: 0,
        marginBottom: 0,
      };
    case BREADCRUMB_TYPE.LAST_SELECTED:
    case BREADCRUMB_TYPE.SELECTED:
      return {
        boxShadow: position === BREADCRUMB_POSITION.LEFT && 'rgba(0, 0, 0, 0.2) 0px 5px 30px',
        border: theme.theme.blocks.border,
      };
    case BREADCRUMB_TYPE.CURRENT_ARGUMENT:
      return {
        boxShadow: position === BREADCRUMB_POSITION.LEFT && 'rgba(0, 0, 0, 0.2) 0px 5px 30px',
        border: `1px solid ${theme.theme.base.primary}`,
      };
    case BREADCRUMB_TYPE.PLACEHOLDER:
      return {
        boxShadow: position === BREADCRUMB_POSITION.LEFT && 'rgba(0, 0, 0, 0.2) 0px 5px 30px',
        border: `1px solid grey`,
      };
  }
};

export const getBlockStyle = (theme: { theme: ITheme }, type: BREADCRUMB_TYPE, _position: BREADCRUMB_POSITION): any => {
  switch (type) {
    case BREADCRUMB_TYPE.LAST_SELECTED:
    case BREADCRUMB_TYPE.SELECTED:
      return {
        color: theme.theme.blocks.color,
        backgroundColor: theme.theme.blocks.background,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BREADCRUMB_TYPE.CURRENT_ARGUMENT:
      return {
        color: chroma(theme.theme.base.primary).get('lab.l') < 70 ? 'white' : 'black',
        backgroundColor: theme.theme.base.primary,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BREADCRUMB_TYPE.PLACEHOLDER:
      return {
        backgroundColor: '#dddddd',
        color: 'grey',
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
    case BREADCRUMB_TYPE.INSTRUCTION:
      return {
        backgroundColor: theme.theme.instruction.background,
        color: theme.theme.instruction.fontColor,
        fontSize: theme.theme.blocks.fontSize,
        paddingTop: theme.theme.blocks.paddingTop,
        paddingBottom: theme.theme.blocks.paddingBottom,
        paddingLeft: theme.theme.blocks.paddingLeft,
        paddingRight: theme.theme.blocks.paddingRight,
        fontWeight: theme.theme.blocks.fontWeight,
      };
  }
};

const FadeOut = (props: { text: string }) => {
  return (
    <Tooltip
      prefixCls="commandbar-tooltip"
      overlay={<span>{props.text}</span>}
      align={{ offset: [0, -7] }}
      placement="top"
    >
      <span
        css={(theme) => ({
          position: 'absolute',
          left: 0,
          zIndex: 1,
          width: '100%',
          height: '100%',
          background: `linear-gradient(
  to right,
  ${chroma(theme.theme.main.background).alpha(0).css()},
  ${chroma(theme.theme.main.background).alpha(0).css()} 75%,
  ${chroma(theme.theme.main.background).alpha(0.2).css()} 80%,
  ${chroma(theme.theme.main.background).alpha(0.9).css()} 100%
)`,
        })}
      />
    </Tooltip>
  );
};

const Breadcrumb = (props: {
  content: IBreadcrumb;
  onRollback: () => void;
  position: BREADCRUMB_POSITION;
  windowWidth: number;
}) => {
  const ref = React.useRef<any>(undefined);
  const [showFade, setShowFade] = React.useState(false);

  React.useEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowFade(true);
    } else {
      setShowFade(false);
    }
  }, [props.content.text, props.position, props.windowWidth]);

  const { content, onRollback } = props;
  const maxWidth = getMaxWidth(content.type, props.position);
  return (
    <div
      className={`commandbar-animation-fade-in commandbar-breadcrumb-background-${props.position}`}
      css={(theme) => getBlockBackgroundStyle(theme, content.type, props.position)}
      key={`${content.text}-background`}
    >
      {showFade && <FadeOut text={content.text} />}
      <div className="commandbar-breadcrumb" css={(theme) => getBlockStyle(theme, content.type, props.position)}>
        <div>{getBlockSuffix(content.type, onRollback)}</div>
        <span style={{ maxWidth, minWidth: 0, color: 'inherit' }} ref={ref}>
          {content.text}
        </span>
      </div>
    </div>
  );
};

export default Breadcrumb;
