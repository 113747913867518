import { ITheme } from '@commandbar/internal/client/theme';
import { ICommandCategoryType, IResourceSettingsByContextKey } from '@commandbar/internal/middleware/types';
import { IActiveObjectConfig, isCommandOfActiveObject } from './ExecutionPath';
import { Option, CommandOption, ParameterOption, ResourceOption, UnfurledCommandOption } from './option';

type OptionGroupType = 'RECOMMENDED' | 'OBJECT' | 'PARAMETER' | 'COMMAND_CATEGORY' | 'NONE' | 'ACTIVEOBJECT';

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
class OptionGroup {
  public name: string;
  public key: string;
  public type: OptionGroupType;
  public limit?: number | null;
  public sortFunction?: (a: any, b: any) => any;
  public size: number;

  constructor({
    name,
    type,
    limit,
    key,
    sortFunction,
  }: {
    name: string;
    type: OptionGroupType;
    key: string;
    limit?: number | null;
    sortFunction?: (a: any, b: any) => any;
  }) {
    this.name = name;
    this.type = type;
    this.key = key;
    this.limit = limit;
    this.sortFunction = sortFunction;
    this.size = 0;
  }

  public static getKey(type: OptionGroupType, id: string | number) {
    return `${type}-${id.toString()}`;
  }

  public static getObjectGroupContextKey(group: OptionGroup): null | string {
    if (group.type !== 'OBJECT') {
      return null;
    } else {
      return group.key.replace('OBJECT-', '');
    }
  }

  public static getGroupOfOption(
    option: Option,
    categories: ICommandCategoryType[],
    contextSettings: IResourceSettingsByContextKey,
    activeObjects: Record<string, IActiveObjectConfig>,
  ): OptionGroup {
    const activeObjectConfig = isCommandOfActiveObject(option, activeObjects);
    if (!!activeObjectConfig) {
      return activeObjectGroup(activeObjectConfig.categoryName || option.label);
    }

    if (option instanceof CommandOption || option instanceof UnfurledCommandOption) {
      const categoryID = option.command?.category;
      if (option.isRecommended) {
        return recommendedGroup();
      }
      if (!!categoryID) {
        const category = categories.find((obj) => obj.id === categoryID);
        if (category) {
          return new OptionGroup({
            name: category.name,
            key: OptionGroup.getKey('COMMAND_CATEGORY', category.id),
            type: 'COMMAND_CATEGORY',
            limit: category?.setting_max_options_count,
          });
        }
      }
    } else if (option instanceof ResourceOption) {
      const categoryName = option.searchOptions?.name || `Search: ${capitalize(option.category.contextKey)}`;
      return new OptionGroup({
        name: categoryName,
        key: OptionGroup.getKey('OBJECT', option.category.contextKey),
        type: 'OBJECT',
        limit: option.searchOptions?.max_options_count,
        sortFunction: option.searchOptions?.sortFunction,
      });
    } else if (option instanceof ParameterOption) {
      const category = option.getReservedField('category') || '';

      return new OptionGroup({
        name: category,
        key: OptionGroup.getKey('PARAMETER', category),
        type: 'PARAMETER',
        // No limit on parameter lists
        limit: undefined,
        sortFunction: option.searchOptions?.sortFunction,
      });
    }
    return noneGroup();
  }

  public static getDefaultHeaderHeight(themeContext: ITheme, addPaddingTop: boolean) {
    return (
      parseInt(themeContext.categoryHeader.paddingBottom, 10) +
      parseInt(themeContext.categoryHeader.paddingTop, 10) +
      parseInt(themeContext.categoryHeader.fontSize, 10) +
      (addPaddingTop ? parseInt(themeContext.menu.spaceBetweenCategories) : 0) +
      1 // 1 px border which isn't specified by a theme, but always present
    );
  }

  public isOverLimit() {
    if (!this.limit || !this.size || this.limit >= this.size) return false;
    return true;
  }

  public isExpanded(expandedGroupKeys: string[]) {
    return expandedGroupKeys.includes(this.key);
  }

  public canExpand(expandedGroupKeys: string[]) {
    return this.isOverLimit() && !this.isExpanded(expandedGroupKeys);
  }

  public allowMoreOptions(expandedGroupKeys: string[]) {
    if (!this.limit || !this.size) return true;
    if (this.limit > this.size) return true;
    if (!expandedGroupKeys) return true;
    return this.isExpanded(expandedGroupKeys);
  }

  public incrementSize() {
    this.size += 1;
  }
}

export const recommendedGroup = (): OptionGroup =>
  new OptionGroup({
    name: 'Recommended',
    key: 'RECOMMENDED-',
    type: 'RECOMMENDED',
  });

export const noneGroup = (): OptionGroup =>
  new OptionGroup({
    name: '',
    key: 'NONE-',
    type: 'NONE',
  });

export const activeObjectGroup = (name: string): OptionGroup =>
  new OptionGroup({
    name,
    key: 'ACTIVEOBJECT-',
    type: 'ACTIVEOBJECT',
  });

export default OptionGroup;
