/*
 * State tracking the groups expanded by the user
 */

/*******************************************************************************/
/* Imports
/*******************************************************************************/
import React from 'react';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const useExpandedGroups = () => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const toggleExpansion = (header: string) => {
    const index = expanded.findIndex((_header: string) => _header === header);
    const _expanded = [...expanded];
    if (index > -1) {
      _expanded.splice(index, 1);
    } else {
      _expanded.push(header);
    }
    setExpanded(_expanded);
  };

  return { expandedGroupKeys: expanded, toggleGroupExpansion: toggleExpansion };
};

export default useExpandedGroups;
