/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import Z from '@commandbar/internal/client/Z';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { Organization } from '@commandbar/internal/middleware/organization';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IWidget {
  offsets: { [type: string]: [number, number] };
  selectors: string[];
}

const WIDGETS: { [name: string]: IWidget } = {
  INTERCOM: {
    offsets: {
      minimal: [66, -10],
      alternate: [64, -1],
    },
    selectors: ['.intercom-launcher-frame', '.intercom-lightweight-app', '#intercom-frame', '.intercom-launcher'],
  },
  DRIFT: {
    offsets: {
      minimal: [84, 1],
      alternate: [85, 9],
    },
    selectors: ['#drift-widget', '#drift-widget-container'],
  },
  HUBSPOT: {
    offsets: {
      minimal: [58, -15],
      alternate: [61, -6],
    },
    selectors: ['#hubspot-messages-iframe-container'],
  },
};
const useLauncherPosition = (isAdmin: boolean, organization: IOrganizationType) => {
  // if (organization.launcher_type === 'inline') {
  //   return {
  //     display: 'inline-block',
  //   };
  // } else {

  ////////////////////////////////////////////
  // autoPosition
  ////////////////////////////////////////////

  const [offsetX, setOffsetX] = React.useState(organization.launcher_offset_x);
  const [offsetY, setOffsetY] = React.useState(organization.launcher_offset_y);

  const autoPosition = (isAdmin: boolean, org: IOrganizationType) => {
    if (!shouldAutoPosition(isAdmin, org)) {
      return;
    }

    let offset = [0, 0];
    Object.keys(WIDGETS).map((widget: string) => {
      return WIDGETS[widget].selectors.map((selector: string) => {
        const el = document.querySelector(selector);
        if (!!el) {
          const o = WIDGETS[widget]?.offsets?.[org.launcher_type];
          if (!!o) {
            offset = o;
          }
        }
        return null;
      });
    });

    const [launcher_offset_x, launcher_offset_y] = offset;

    if (launcher_offset_x === 0 && launcher_offset_y === 0) {
      return;
    }

    Organization.update({
      ...organization,
      launcher_offset_x,
      launcher_offset_y,
    }).then((org: IOrganizationType) => {
      setOffsetX(org.launcher_offset_x);
      setOffsetY(org.launcher_offset_y);
    });

    return;
  };

  const shouldAutoPosition = (isAdmin: boolean, org: IOrganizationType) => {
    if (!isAdmin) {
      return false;
    }

    if (org.launcher_position !== 'bottomRight') {
      return false;
    }

    if (!(org.launcher_offset_x === 0 && org.launcher_offset_y === 0)) {
      return false;
    }

    if (!['minimal', 'alternate'].includes(organization.launcher_type)) {
      return false;
    }

    return true;
  };

  React.useEffect(() => {
    setTimeout(() => {
      autoPosition(isAdmin, organization);
    }, 500);
  }, [isAdmin]);

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  React.useEffect(() => {
    setOffsetX(organization.launcher_offset_x);
    setOffsetY(organization.launcher_offset_y);
  }, [organization.launcher_offset_x, organization.launcher_offset_y]);

  const marginX = 35 + offsetX;
  const marginY = 35 + offsetY;

  const fixedStyles = {
    zIndex: Z.Z_LAUNCHER,
    position: 'fixed',
    userSelect: 'none',
  };

  let variableStyles = {};

  switch (organization.launcher_position) {
    case 'topRight':
      variableStyles = {
        top: marginY,
        right: marginX,
      };
      break;
    case 'topLeft':
      variableStyles = {
        top: marginY,
        left: marginX,
      };
      break;
    case 'bottomRight':
      variableStyles = {
        bottom: marginY,
        right: marginX,
      };
      break;
    case 'bottomLeft':
      variableStyles = {
        bottom: marginY,
        left: marginX,
      };
      break;
  }

  return {
    ...fixedStyles,
    ...variableStyles,
  };
  // }
};

export default useLauncherPosition;
