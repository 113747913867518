/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import { ITheme } from '@commandbar/internal/client/theme';
import * as React from 'react';
interface IProps {
  label: string | React.ReactNode;
  color: string;
  type: 'command' | 'resource' | 'parameter';
  isFocused: boolean;
  theme: ITheme;
  icon?: React.ReactNode;
  draft?: React.ReactNode;
  description?: React.ReactNode;
  shortcut?: React.ReactNode;
  goForward?: React.ReactNode;
  extra?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const OptionWrapper = (props: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      className={`commandbar-option commandbar-option-${props.type}`}
    >
      {props.icon}
      <div
        style={{
          marginLeft: '18px',
          color: props.color,
          maxWidth: '90%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          lineHeight: 1.6,
        }}
      >
        {props.breadcrumbs && (
          <div
            style={{
              fontSize: props.theme.option.breadcrumbsFontSize,
              color: props.isFocused
                ? props.theme.optionSelected.breadcrumbsColor
                : props.theme.option.breadcrumbsColor,
              marginBottom: props.theme.option.breadcrumbsMarginBottom,
              fontWeight: props.theme.option.breadcrumbsFontWeight,
            }}
          >
            {props.breadcrumbs}
          </div>
        )}
        {props.draft}
        {props.label}
        {!!props.description && (
          <div
            style={{
              fontSize: props.theme.option.descriptionFontSize,
              marginTop: props.theme.option.descriptionMarginTop,
              color: props.isFocused
                ? props.theme.optionSelected.descriptionColor
                : props.theme.option.descriptionColor,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.description}
          </div>
        )}
        {!!props.extra && <div>{props.extra}</div>}
      </div>
      <div style={{ flexGrow: 1 }} />
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        {props.shortcut}
        {props.goForward}
      </div>
    </div>
  );
};

export default OptionWrapper;
