/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';
import { jsx } from '@emotion/core';

import { ICommandType, ICommandCategoryType } from '@commandbar/internal/middleware/types';
import { OS, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import KeyboardShortcut from './KeyboardShortcut';
import RightSidePane, { headerStyle, rowStyle, SearchBar } from './RightSidePane';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  visible: boolean;
  setVisible: any;
  commands: ICommandType[];
  categories: ICommandCategoryType[];
  availableCommands: ICommandType[];
}

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const getHighlightedText = (text: string, highlight: string, isInputFocused: boolean) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

  if (!isInputFocused) {
    return parts.map((part: string, index: number) => (
      <span key={`part-${part}-${index}`} style={{ opacity: 1 }}>
        {part}
      </span>
    ));
  }

  return parts.map((part: string, index: number) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={`part-${part}-${index}`} style={{ opacity: 1 }}>
        {part}
      </span>
    ) : (
      <span key={`part-${part}-${index}`} style={{ opacity: 0.7 }}>
        {part}
      </span>
    ),
  );
};

const KeyboardShortcutCheatsheet = (props: IProps) => {
  const os = React.useMemo(getOperatingSystem, []);

  const [searchTerm, setSearchTerm] = React.useState('');
  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleKeydown = (e: any) => {
    if (e.key === 'Escape') {
      props.setVisible(false);
    }
  };

  const isCommandUnavailable = (command: ICommandType) => {
    return props.availableCommands.find((c) => c.id === command.id) === undefined;
  };

  React.useEffect(() => {
    // We only want to add this event listener if the pane is visible,
    // so we don't overwrite the client keydown events
    if (props.visible) {
      document.addEventListener('keydown', handleKeydown);
    } else {
      document.removeEventListener('keydown', handleKeydown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [props.visible]);

  const filteredCommands = props.commands.filter((command: ICommandType) => {
    if (command === undefined) {
      return false;
    }
    const search = searchTerm.toLowerCase();

    let hasShortcut = false;
    if ([OS.MAC].includes(os)) {
      hasShortcut = command.shortcut_mac.length > 0;
    } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
      hasShortcut = command.shortcut_win.length > 0;
    }

    const commandMatchesSearch = search === '' || command.text.toLowerCase().includes(search);

    const matchedCategories = props.categories
      .filter((category: ICommandCategoryType) => {
        return search === '' || category.name.toLowerCase().includes(search);
      })
      .map((category: ICommandCategoryType) => {
        return category.id;
      });

    const categoryMatchesSearch = command.category !== null && matchedCategories.includes(command.category);

    return command.is_live && ((hasShortcut && commandMatchesSearch) || (hasShortcut && categoryMatchesSearch));
  });

  const otherCommands = filteredCommands.filter((command: ICommandType) => {
    return command.category === null;
  });

  const commandBarKeys = [OS.MAC].includes(os)
    ? ['command', 'k']
    : [OS.WINDOWS, OS.LINUX].includes(os)
    ? ['ctrl', 'k']
    : [];

  const commandBarRow = (
    <div key={`command-bar-shortcut`} style={rowStyle}>
      <div>{getHighlightedText('Open Command Bar', searchTerm, isInputFocused)}</div>
      <div>
        <KeyboardShortcut keys={commandBarKeys} />
      </div>
    </div>
  );

  const showSearchBar = true;

  return (
    <RightSidePane onClose={() => props.setVisible(false)} visible={props.visible}>
      <div style={{ fontWeight: 500, fontSize: 20, display: 'flex' }}>Keyboard Shortcuts</div>
      {showSearchBar && (
        <div style={{ marginTop: 15 }}>
          <SearchBar searchTerm={searchTerm} onSearch={onSearch} onFocus={handleInputFocus} onBlur={handleInputBlur} />
        </div>
      )}
      <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
        <br />
        {commandBarRow}
        {props.categories.map((category: ICommandCategoryType) => {
          const thisCategoryCommands = filteredCommands.filter((command: ICommandType) => {
            return command.category === category.id;
          });
          if (thisCategoryCommands.length === 0) {
            return null;
          }

          return (
            <div key={`category-${category.id}`}>
              <div style={headerStyle}>{category.name}</div>
              {thisCategoryCommands.map((command: ICommandType) => {
                let keys: string[] = [];
                if ([OS.MAC].includes(os)) {
                  keys = command.shortcut_mac;
                } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
                  keys = command.shortcut_win;
                }

                return (
                  <div
                    key={`category-${category.id}-command-${command.id}`}
                    style={{ ...rowStyle, ...(isCommandUnavailable(command) ? { opacity: 0.6 } : {}) }}
                  >
                    <div>{getHighlightedText(command.text, searchTerm, isInputFocused)}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <KeyboardShortcut keys={keys} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        {otherCommands.length === 0 ? null : (
          <div>
            {props.categories.length > 0 ? <div style={headerStyle}>other</div> : <br />}
            {otherCommands.map((command: ICommandType) => {
              let keys: string[] = [];
              if ([OS.MAC].includes(os)) {
                keys = command.shortcut_mac;
              } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
                keys = command.shortcut_win;
              }

              return (
                <div
                  key={`other-${command.id}`}
                  style={{ ...rowStyle, ...(isCommandUnavailable(command) ? { opacity: 0.6 } : {}) }}
                >
                  <div>{getHighlightedText(command.text, searchTerm, isInputFocused)}</div>
                  <div>
                    <KeyboardShortcut keys={keys} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </RightSidePane>
  );
};

export default KeyboardShortcutCheatsheet;
