import {
  ICommandCategoryType,
  IEndUserType,
  IOrganizationType,
  IResourceSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import partition from 'lodash.partition';
import { CommandOption, ParameterOption, Option, UnfurledCommandOption } from './option';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import OptionGroup, { activeObjectGroup, noneGroup, recommendedGroup } from './OptionGroup';
import { IActiveObjectConfig } from './ExecutionPath';
import { Step } from './step';

const _groupItems = (
  options: Option[],
  categories: ICommandCategoryType[],
  hackContextSettings: IResourceSettingsByContextKey,
  activeObjects: Record<string, IActiveObjectConfig>,
  expandedGroupKeys: string[],
): Map<string, { group: OptionGroup; children: Option[] }> => {
  return options.reduce(
    (acc: Map<string, { group: OptionGroup; children: Option[] }>, item: Option) => {
      const groupOfThisOption = OptionGroup.getGroupOfOption(item, categories, hackContextSettings, activeObjects);

      const group = acc.get(groupOfThisOption.key)?.group || groupOfThisOption;
      const children = acc.get(groupOfThisOption.key)?.children || [];

      if (group.allowMoreOptions(expandedGroupKeys)) {
        children.push(item);
      }
      group.incrementSize();
      acc.set(group.key, { group, children });
      return acc;
    },
    // We use a map instead of an object because a map remembers insertion order
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
    new Map(),
  );
};

const _orderGroups = (args: {
  groups: Map<string, { group: OptionGroup; children: Option[] }>;
  isEmptyState: boolean;
  categories: ICommandCategoryType[];
  hackContextSettings: IResourceSettingsByContextKey;
}): string[] => {
  const { groups, isEmptyState, categories, hackContextSettings } = args;
  let orderedGroups: string[] = Array.from(groups.keys());

  // If emopty state, order groups by the default ordering
  if (isEmptyState) {
    orderedGroups = Array.from(
      new Set([
        activeObjectGroup('').key,
        recommendedGroup().key,
        noneGroup().key,
        ...categories.sort(compareObjs).map((c) => OptionGroup.getKey('COMMAND_CATEGORY', c.id)),
        ...Object.keys(hackContextSettings).map((key) => OptionGroup.getKey('OBJECT', key)),
        ...orderedGroups,
      ]),
    );
  }

  // Handle pinned to bottom categories
  const _pinnedToBottomKeys = categories
    .filter((c) => c.setting_pin_to_bottom)
    .sort(compareObjs)
    .map((c) => OptionGroup.getKey('COMMAND_CATEGORY', c.id));

  return [
    ...orderedGroups.filter((groupKey: string) => !_pinnedToBottomKeys.includes(groupKey)),
    ..._pinnedToBottomKeys,
  ];
};

/**
 * Apply a custom sort within a group
 */

const reorderSelectedToBeginning = (options: Option[], currentStep: Step) => {
  return [...options].sort((a: Option, b: Option) => {
    if (!(a instanceof ParameterOption) || !(b instanceof ParameterOption)) return 0;
    const isASelected = ParameterOption.isSelected(a, currentStep);
    const isBSelected = ParameterOption.isSelected(b, currentStep);
    return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
  });
};

const _applyCustomSortOverrides = (
  groups: Map<string, { group: OptionGroup; children: Option[] }>,
  currentStep?: Step,
) => {
  Array.from(groups.keys()).forEach((groupKey: string) => {
    const group = groups.get(groupKey)?.group;
    const children = groups.get(groupKey)?.children;
    if (!group || !children) {
      return;
    }
    const compareFunction = group.sortFunction;
    if (!!compareFunction) {
      const newChildren = [...children].sort((a: Option, b: Option) => {
        if (!(a instanceof ParameterOption) || !(b instanceof ParameterOption)) return 0;
        return compareFunction(
          { value: a.parameter, searchMatches: a.fuseMatches },
          { value: b.parameter, searchMatches: b.fuseMatches },
        );
      });
      groups.set(groupKey, { group, children: newChildren });
    } else if (group.key === recommendedGroup().key) {
      // Order recommended group by recommend_sort_key
      const newChildren = [...children].sort((a: Option, b: Option) => {
        if (!(a instanceof CommandOption) || !(b instanceof CommandOption)) return 0;
        return compareObjs(
          { sort_key: a.command.recommend_sort_key, id: 0 },
          { sort_key: b.command.recommend_sort_key, id: 0 },
        );
      });
      groups.set(groupKey, { group, children: newChildren });
    } else if (currentStep?.selected?.data) {
      const newChildren = reorderSelectedToBeginning(children, currentStep);
      groups.set(groupKey, { group, children: newChildren });
    }
  });
  return groups;
};

const _orderedOptionsIncludingHeaders = (
  groups: Map<string, { group: OptionGroup; children: Option[] }>,
  orderedGroupKeys: string[],
) => {
  return orderedGroupKeys.reduce((acc: (Option | OptionGroup)[], id: string) => {
    const mapValue = groups.get(id);
    if (mapValue) {
      const { children, group } = mapValue;
      return [...acc, group, ...children];
    } else return acc;
  }, []);
};

const _sortInEmptyState = (
  options: Option[],
  categories: ICommandCategoryType[],
  organization: IOrganizationType | undefined,
  user: IEndUserType | undefined,
  hackContextSettings: IResourceSettingsByContextKey,
  activeObjects: Record<string, IActiveObjectConfig>,
  expandedGroupKeys: string[],
  currentStep: Step | undefined,
): (Option | OptionGroup)[] => {
  // Fetch recommendations and move them out of the list
  const recommended = recommend({ options, numRecommendations: 4, organization, user });
  recommended.forEach((c: CommandOption) => (c.isRecommended = true));

  // Group options
  const groups = _applyCustomSortOverrides(
    _groupItems(options, categories, hackContextSettings, activeObjects, expandedGroupKeys),
    currentStep,
  );
  const orderedGroupKeys: string[] = _orderGroups({ groups, categories, hackContextSettings, isEmptyState: true });
  return _orderedOptionsIncludingHeaders(groups, orderedGroupKeys);
};

const _sortInSearchState = (
  options: Option[],
  categories: ICommandCategoryType[],
  hackContextSettings: IResourceSettingsByContextKey,
  activeObjects: Record<string, IActiveObjectConfig>,
  expandedGroupKeys: string[],
): (Option | OptionGroup)[] => {
  const groups = _applyCustomSortOverrides(
    _groupItems(options, categories, hackContextSettings, activeObjects, expandedGroupKeys),
  );
  const orderedGroupKeys: string[] = _orderGroups({ groups, categories, hackContextSettings, isEmptyState: false });
  return _orderedOptionsIncludingHeaders(groups, orderedGroupKeys);
};

const sort = (
  options: Option[],
  state: {
    organization: IOrganizationType | undefined;
    categories: ICommandCategoryType[];
    user: IEndUserType | undefined;
    inputText: string;
    hackContextSettings: IResourceSettingsByContextKey;
    activeObjects: Record<string, IActiveObjectConfig>;
    expandedGroupKeys: string[];
    currentStep: Step | undefined;
  },
) => {
  if (state.inputText.length === 0)
    return _sortInEmptyState(
      options,
      state.categories,
      state.organization,
      state.user,
      state.hackContextSettings,
      state.activeObjects,
      state.expandedGroupKeys,
      state.currentStep,
    );
  else
    return _sortInSearchState(
      options,
      state.categories,
      state.hackContextSettings,
      state.activeObjects,
      state.expandedGroupKeys,
    );
};

const recommend = ({
  options,
  numRecommendations,
  organization,
  user,
}: {
  options: Option[];
  numRecommendations?: number;
  organization: IOrganizationType | undefined;
  user: IEndUserType | undefined;
}): CommandOption[] => {
  if (organization?.recommendations_type === 'None') {
    return [];
  }

  const USE_ALGORITHM = organization?.recommendations_type === 'Algorithm';
  const commands: CommandOption[] = options.filter(
    (o: Option) => o instanceof CommandOption && !(o instanceof UnfurledCommandOption),
  ) as CommandOption[];
  // eslint-disable-next-line prefer-const
  let [customRecommended, otherCommands] = partition(commands, (c: CommandOption) => c.isRecommended);

  if (USE_ALGORITHM && user) {
    otherCommands.sort(CommandOption.sort.bind({}, user.usage));
  } else {
    otherCommands = [];
  }

  const toRecommend = [...customRecommended, ...otherCommands].slice(0, numRecommendations);
  return toRecommend;
};

const OptionList = {
  sort,
  recommend,
};

export default OptionList;
