/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { SyntheticEvent } from 'react';
import { jsx } from '@emotion/core';

import { useTheme } from 'emotion-theming';

import 'rc-dialog/assets/index.css';
import Dialog from 'rc-dialog';
import Z from '@commandbar/internal/client/Z';
import { AiOutlineClose } from 'react-icons/ai';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  visible: boolean;
  onClose: () => void;
  children: any;
}

const RightSidePane = (props: IProps) => {
  const { theme } = useTheme();

  let container: HTMLElement | false = false;
  if (document.getElementById('commandbar-home') !== null) {
    container = document.getElementById('commandbar-home') ?? false;
  }

  return (
    <Dialog
      visible={props.visible}
      closable={false}
      destroyOnClose={true}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
      bodyStyle={{
        backgroundColor: theme.main.background,
        color: theme.main.color,
        height: '100vh',
        width: '375px',
        padding: '32px 40px 32px 32px',
      }}
      wrapClassName={'commandbar-keboardcheatsheet'}
      style={{
        fontFamily: theme.main.fontFamily,
        width: '375px',
        margin: '0px 0px 0px auto',
      }}
      zIndex={Z.Z_KEYBOARD_SHORTCUTS}
      getContainer={container}
      mask={true}
      maskClosable={true}
      onClose={props.onClose}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AiOutlineClose
          style={{ position: 'absolute', top: 12, right: 12, fontSize: 14, cursor: 'pointer', color: theme.main.color }}
          onClick={props.onClose}
        />
        {props.children}
      </div>
    </Dialog>
  );
};

export default RightSidePane;

export const headerStyle = {
  marginTop: 24,
  fontSize: 16,
  fontWeight: 500,
  borderBottom: '1px solid #c1c7cd',
  padding: '0 0 8px 2px',
};

export const rowStyle = {
  fontSize: 14,
  borderBottom: '1px solid grey',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '6px',
  alignItems: 'center',
};

interface SearchBarProps {
  searchTerm: string;
  onSearch: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
}

export const SearchBar = (props: SearchBarProps) => {
  const { theme } = useTheme();

  return (
    <input
      type="text"
      name="search"
      value={props.searchTerm}
      onChange={props.onSearch}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder="Search..."
      autoComplete="off"
      autoFocus={true}
      css={{
        width: '100%',
        color: theme.main.color,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.base.primary}`,
        borderRadius: 4,
        padding: 6,
        fontSize: 14,
        '&:focus': {
          outline: 'none',
        },
      }}
    />
  );
};
