/*******************************************************************************/
/* Imports
/*******************************************************************************/

import { ICommandType } from '@commandbar/internal/middleware/types';

/*******************************************************************************/

export const adminOnlyCommands = (_options: ICommandType[]): ICommandType[] => [
  // {
  //   id: 99999, // push to bottom of command list
  //   organization: '',
  //   text: 'Add new command',
  //   arguments: {},
  //   template: { type: 'admin', value: 'commandbar-admin-addCommand' },
  //   tags: ['admin', 'editor', 'create'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
  // {
  //   id: 99998,
  //   organization: '',
  //   text: 'Edit command',
  //   arguments: {
  //     command: {
  //       type: 'set',
  //       value: options.map((o) => {
  //         return { label: o.text, value: o.id };
  //       }),
  //       order_key: 1,
  //     },
  //   },
  //   template: { type: 'admin', value: 'commandbar-admin-editCommand' },
  //   tags: ['admin', 'editor', 'update'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
  // {
  //   id: 99997,
  //   organization: '',
  //   text: 'Record command',
  //   arguments: {
  //     command: {
  //       type: 'set',
  //       value: options
  //         .filter((o: ICommandType) => {
  //           return ['click', 'clickBySelector', 'clickByXpath'].includes(o.template.type);
  //         })
  //         .map((o) => {
  //           return { label: o.text, value: o.id };
  //         }),
  //       order_key: 1,
  //     },
  //   },
  //   template: { type: 'admin', value: 'commandbar-admin-recordCommand' },
  //   tags: ['admin', 'editor', 'click'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
  // {
  //   id: 99996,
  //   organization: '',
  //   text: 'Add new context',
  //   arguments: {},
  //   template: { type: 'admin', value: 'commandbar-admin-addContext' },
  //   tags: ['admin', 'editor', 'set', 'window'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
  // {
  //   id: 99995,
  //   organization: '',
  //   text: 'Add new guide',
  //   arguments: {},
  //   template: { type: 'admin', value: 'commandbar-admin-addGuide' },
  //   tags: ['admin', 'editor', 'nudge', 'tooltip'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
  // {
  //   id: 99994,
  //   organization: '',
  //   text: 'Toggle editor',
  //   arguments: {},
  //   template: { type: 'admin', value: 'commandbar-admin-toggleEditor' },
  //   tags: ['admin', 'editor', 'open'],
  //   availability: [],
  //   confirm: '',
  //   shortcut: [],
  //   shortcut_mac: [],
  //   shortcut_win: [],
  //   explanation: '',
  //   is_live: true,
  //   category: null,
  //   sort_key: null,
  //   icon: null,
  // },
];
