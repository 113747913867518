export interface SearchParams {
  inputValue: string; // this string to parsed
  type: number;
}

export interface Suggestion {
  link?: string;
  date?: moment.Moment;
  type: string;
  label: string;
}

export type SpecialUnitHandler = () => { link?: string; date?: moment.Moment; type: string };

export const WORDS_TO_AUTOCOMPLETE = ['next', 'this', 'tomorrow', 'today'];
export const DATES_TO_SUGGEST = ['1st', '2nd', '3rd'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FIXED_DATES = ['tomorrow', 'tonight', 'today', 'evening', 'yesterday', 'weekend'];
export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const THIS_NEXT_UNITS = ['week', 'weekend', 'month'];
export const IN_UNITS_PLURAL = ['minutes', 'hours', 'days', 'weeks', 'months', 'years'];
export const IN_UNITS_SINGULAR = ['minute', 'hour', 'day', 'week', 'month', 'year'];
export const DATE_ONLY_IN_UNITS_SINGULAR = ['hour', 'day', 'week', 'month', 'year'];
export const DATE_ONLY_IN_UNITS_PLURAL = ['hours', 'days', 'weeks', 'months', 'years'];
export const AT_SUGGESTIONS = ['today at 3pm', 'tomorrow at 4pm', 'Friday at 5pm'];

export const DEFAULTS_TIME_OPTIONS = {
  hour: 8,
  minute: 0,
};

export const NUMBERS: Record<string, string> = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  15: 'fifteen',
  20: 'twenty',
  30: 'thirty',
};

export const IN_SUGGESTIONS = [
  'in a few minutes',
  'in an hour',
  'in a minute',
  'in a day',
  'in a week',
  'in two weeks',
  'in a month',
];

export const ONLY_DATE_IN_SUGGESTIONS = ['in a day', 'in a week', 'in two weeks', 'in a month'];

export const DEFAULT_SUGGESTIONS = [...IN_SUGGESTIONS, ...FIXED_DATES];
export const DEFAULT_DATE_ONLY_SUGGESTIONS = [...ONLY_DATE_IN_SUGGESTIONS, ...FIXED_DATES];
export const DEFAULTS = ['tomorrow at 8am', 'on Friday at 12:30', 'next week', 'in an hour'];
