import { ITheme } from '@commandbar/internal/client/theme';
import React from 'react';
import { IFuseSearchMatchType } from '../../../engine/useCommandBar';

// Don't show matches of these tyeps
const MATCH_TYPES_TO_HIDE = [
  'label',
  'command.tags',
  'command.explanation',
  'description',
  'category.label',
  'parameter.synonyms',
  'breadcrumbs',
];

export const showTopSearchMatch = (matches: IFuseSearchMatchType[]) => {
  return matchesToShow(matches).length > 0;
};

const matchesToShow = (matches: IFuseSearchMatchType[]): { key: string; value: string }[] => {
  if (!matches || matches.length === 0) return [];
  // The first match is the most relevant. If it should be hidden return an empty array
  if (MATCH_TYPES_TO_HIDE.includes(matches[0].key)) return [];
  return matches.filter((match) => !MATCH_TYPES_TO_HIDE.includes(match.key));
};

const SearchMatchFormatter = (props: {
  matches: IFuseSearchMatchType[];
  theme: ITheme;
  formatKey?: (searchKey: string, searchValue: string) => React.ReactNode | string;
  formatValue?: (searchValue: string) => React.ReactNode | string;
}) => {
  const matches = matchesToShow(props.matches);
  if (matches.length === 0) return null;

  const formattedKey = props.formatKey ? props.formatKey(matches[0].key, matches[0].value) : matches[0].key;
  const formattedValue = props.formatValue ? props.formatValue(matches[0].value) : matches[0].value;
  return (
    <span
      style={{
        fontSize: 11,
        width: 175,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {formattedKey}
      {formattedValue}
    </span>
  );
};

export default SearchMatchFormatter;
