/** @jsx jsx */
/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { jsx } from '@emotion/core';
import { useErrorReporter } from '../error/useErrorReporter';
import { ErrorCode } from '../error/ErrorCode';

interface IProps {
  children: React.ReactNode | undefined;
  onClose: () => void;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const Dashboard = (props: IProps) => {
  let refContainer: any = React.useRef(null);
  const reporter = useErrorReporter();

  React.useEffect(() => {
    // Handle non-react html children
    if (refContainer && props.children && !React.isValidElement(props.children)) {
      if (props.children instanceof Element || props.children instanceof Node) {
        refContainer.appendChild(props.children);
      } else {
        reporter.exception(ErrorCode.DASHBOARD);
      }
    }
  }, [props.children]);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.onClose();
    }
  };

  const closeOnOutsideClick = (e: MouseEvent) => {
    const parent = document.getElementById('commandbar-dashboard');
    if (parent && e.target instanceof Element) {
      const isClickOutside = !parent.contains(e.target);
      if (isClickOutside) {
        props.onClose();
      }
    }
  };

  React.useEffect(() => {
    if (props.children) {
      document.addEventListener('keydown', handleEscape, true);
      document.addEventListener('click', closeOnOutsideClick);
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, true);
      document.removeEventListener('click', closeOnOutsideClick);
    };
  }, [props.children]);

  if (props.children === undefined) {
    return null;
  }

  return (
    <div
      id="commandbar-dashboard"
      ref={(node) => {
        refContainer = node;
      }}
      css={(theme) => ({
        textAlign: 'center',
        padding: '30px 50px',
        backgroundColor: theme.theme.main.background,
        borderRadius: theme.theme.main.borderRadius,
        fontFamily: theme.theme.main.fontFamily,
        color: theme.theme.main.color,
      })}
    >
      {React.isValidElement(props.children) && props.children}
    </div>
  );
};

export default Dashboard;
