/** @jsx jsx */
import { jsx } from '@emotion/core';

import OptionGroup from '../../../engine/OptionGroup';
import { Option } from '../../../engine/option';

import { ICommandCategoryType } from '../../../../../internal/middleware/types';
import { IExecutionPathState } from '../../../engine/ExecutionPath';
import EmptyMenu from './EmptyMenu';
import MenuList from './MenuList';

interface IProps {
  categories: ICommandCategoryType[];
  expandedGroupKeys: string[];
  executionPathState: IExecutionPathState;
  focusedIndex: number;
  isLoading: boolean;
  onInputSelect: (opt: Option) => void;
  onOptionHover: (optIndex: number) => void;
  sortedOptions: (Option | OptionGroup)[];
  toggleGroupExpansion: (toggledKey: string) => void;
  setDashboard: any;
  openEditor: any;
  emptyMessage: any;
  isInputEmpty: any;
  getNextPageResults: (contextKey?: string) => void;
}

const MenuWrapper = (props: IProps) => {
  return (
    <div>
      <MenuList
        categories={props.categories}
        sortedOptions={props.sortedOptions}
        isLoading={props.isLoading}
        executionPathState={props.executionPathState}
        onInputSelect={props.onInputSelect}
        focusedIndex={props.focusedIndex}
        onOptionHover={props.onOptionHover}
        expandedGroupKeys={props.expandedGroupKeys}
        toggleGroupExpansion={props.toggleGroupExpansion}
        getNextPageResults={props.getNextPageResults}
        emptyMenu={
          <EmptyMenu
            executionPathState={props.executionPathState}
            emptyMessage={props.emptyMessage}
            setDashboard={props.setDashboard}
            openEditor={props.openEditor}
            isInputEmpty={props.isInputEmpty}
          />
        }
      />
    </div>
  );
};

export default MenuWrapper;
