import React from 'react';
import { ICommandType } from '@commandbar/internal/middleware/types';
import { IExecutionPathDispatchAction, IExecutionPathState } from '../ExecutionPath';

import { Option, ResourceOption, CommandOption } from '../option';
import { ITheme } from '@commandbar/internal/client/theme';

export class UnfurledCommandOption extends CommandOption {
  public resource: ResourceOption;
  constructor(
    executionPathState: IExecutionPathState,
    command: ICommandType,
    parent: CommandOption,
    resource: ResourceOption,
  ) {
    super(executionPathState, command, parent);
    this.resource = resource;
  }

  public shortcut = (): React.ReactNode => {
    return null;
  };

  public renderLabel = (theme: ITheme) => {
    return (
      <span>
        {Option.highlight('label', this.label, this.fuseMatches, this.fuseScore, theme, false)}
        {this.resource && <span style={{ margin: '0px 5px' }}>{'>'}</span>}
        <span style={{ opacity: 0.6 }}>
          {this.resource &&
            Option.highlight('resource.label', this.resource.label, this.fuseMatches, this.fuseScore, theme, false)}
        </span>
      </span>
    );
  };

  public choose = (
    executionPathState: IExecutionPathState,
    executionPathDispatch?: React.Dispatch<IExecutionPathDispatchAction>,
    triggeredByShortcut?: boolean,
  ): void | IExecutionPathState => {
    return CommandOption.chooseCommandOption(
      this,
      executionPathState,
      this.resource,
      executionPathDispatch,
      triggeredByShortcut,
    );
  };
}
