/* eslint-disable react-app/jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React, { useState } from 'react';
import { AiOutlineMessage, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import { jsx } from '@emotion/core';

import { useTheme } from 'emotion-theming';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import SVG from '@commandbar/internal/util/SVG';
import Tooltip from 'rc-tooltip';
import Game from '../../../util/TRexRunner/Game';
import { translate } from '../../../util/languages';
import Reporting from '../../../analytics/Reporting';

import Button from '../../Button';
import chroma from 'chroma-js';

interface IProps {
  organization?: IOrganizationType;
  setDashboard: (dashboard: undefined | React.ReactNode) => void;
  isIcon?: boolean;
}

export const SuggestCommand = (props: IProps) => {
  const [active, setActive] = useState(false);

  const { theme } = useTheme();

  const showDinoGame = props.organization?.id !== 'ec420f00';
  const toggleVisible = () => {
    if (active) {
      props.setDashboard(undefined);
      setActive(false);
    } else {
      props.setDashboard(<SubmissionForm setDashboard={props.setDashboard} />);
      setActive(true);
    }
  };

  if (props.isIcon) {
    return (
      <Tooltip
        prefixCls="commandbar-tooltip"
        overlay={<span style={{ fontFamily: theme.main.fontFamily }}>{translate('Give feedback')}</span>}
        placement="bottom"
      >
        {!!props?.organization?.icon_suggest ? (
          <SVG
            htmlstring={props.organization.icon_suggest}
            height="20px"
            width="20px"
            style={{ cursor: 'pointer', color: theme.main.color }}
            onClick={toggleVisible}
          />
        ) : (
          <AiOutlineMessage
            onClick={toggleVisible}
            style={{ color: theme.main.color, fontSize: 20, cursor: 'pointer' }}
          />
        )}
      </Tooltip>
    );
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0px' }}>
        <span style={{ marginBottom: 10 }}>{translate("Can't find what you're looking for?")}</span>
        <Button onClick={toggleVisible} style={{ marginBottom: 20 }}>
          {translate('Give feedback')}
        </Button>
        {showDinoGame && (
          <a
            onClick={() =>
              props.setDashboard(
                <div>
                  <Game />
                </div>,
              )
            }
            style={{ paddingLeft: 200 }}
          >
            {translate('...or blow off some steam')}
          </a>
        )}
      </div>
    );
  }
};

const SubmissionForm = (props: { setDashboard: (dashboard: undefined | React.ReactNode) => void }) => {
  const [text, setText] = useState('');
  const { theme } = useTheme();
  const textArea: any = React.useRef(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    Reporting.suggestion(text);
    props.setDashboard(<span style={{ fontSize: theme.base.fontSize }}>{translate('Thanks for the feedback!')}</span>);

    setTimeout(() => props.setDashboard(undefined), 2500);
  };

  React.useEffect(() => {
    const handleKeydown = (e: any) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(e);
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  });

  React.useEffect(() => {
    textArea.current.focus();
  }, []);

  const handleTextChange = (e: any) => {
    const value = e.target.value;
    setText(value);
  };

  const goBack = () => {
    props.setDashboard(undefined);
  };

  return (
    <div style={{ margin: '-15px -35px' }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',

          '& Button': {
            display: 'flex',
            alignItems: 'center',
            fontSize: theme.base.fontSize,
            fontWeight: 500,
            height: 24,
            padding: '0 4px',
            border: 'none',
            borderRadius: 4,
          },
        }}
      >
        <Button
          onClick={() => {
            return;
          }}
          css={{
            color: theme.base.fontColor,
            background: chroma(theme.base.fontColor).alpha(0.14).css(),
            '&:hover': {
              color: theme.base.fontColor,
              background: chroma(theme.base.fontColor).alpha(0.1).css(),
            },
            cursor: 'default',
          }}
        >
          {translate('Give feedback')}
          <AiOutlinePlus
            onClick={goBack}
            style={{ transform: 'rotate(45deg)', marginTop: 2, marginLeft: 4, opacity: 0.55, cursor: 'pointer' }}
          />
        </Button>

        <Button
          onClick={handleSubmit}
          disabled={!text}
          css={{
            color: theme.base.background,
            background: chroma(theme.base.fontColor).alpha(0.75).css(),
            '&:hover': {
              color: theme.base.background,
              background: chroma(theme.base.fontColor).alpha(0.5).css(),
            },
          }}
        >
          {translate('Submit')}
          <BsArrowRightShort style={{ fontSize: '20px', marginLeft: 4 }} />
        </Button>
      </div>

      <textarea
        name="title"
        value={text}
        ref={textArea}
        onChange={handleTextChange}
        placeholder={translate('Give feedback or suggest a new command')}
        rows={8}
        css={{
          display: 'flex',
          width: '100%',
          padding: 16,
          margin: '15px 0 0 0',
          color: theme.main.color,
          fontFamily: theme.main.fontFamily,
          backgroundColor: chroma(theme.base.fontColor).alpha(0.03).css(),
          border: `1px solid ${chroma(theme.base.fontColor).alpha(0.2).css()}`,
          borderRadius: 8,
          fontSize: theme.base.fontSize,
          lineHeight: '16px',
          fontWeight: 500,
          resize: 'none',
          '&:focus': {
            outline: 'none',
          },
        }}
      />
    </div>
  );
};
