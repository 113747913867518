import { BrowserClient, Hub, Event, EventHint, Integrations } from '@sentry/react';
import AnalyticsAPI, { isSilentMode } from '../analytics/Analytics';
import { detect } from 'detect-browser';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { isErrorCode } from './ErrorCode';
import { _reporter } from '@commandbar/internal/client/symbols';
import { getProxySDK } from '@commandbar/internal/client/globals';
import { SentryReporter } from '@commandbar/internal/client/SentryReporter';

/** Number of entries to keep in the event history buffer; this has no effect in production. */
const HISTORY_BUFFER = 100;
export const PROD_DSN = 'https://ebf252b62885436e858a66d157aee9ea@o451734.ingest.sentry.io/5608348';

type ExtraData = Record<string, unknown>;

export interface UserScopeOptions {
  configuration: {
    uuid?: string;
    api: string;
    editor: string;
    proxy: string;
    session: string;
  };
  organization?: IOrganizationType;
  organizationId: string | number;
  organizationName?: string;
  session: string;
  userId?: string;
}

/** A class that initializes a Sentry Hub and forwards unhandled exceptions to Sentry (in production mode). */
export class ErrorReporter extends SentryReporter {
  private client: BrowserClient;
  private history: { event: Event; hint?: EventHint }[];
  private hub: Hub;

  /**
   * Get the global singleton instance of this class. The singleton will be initialized if not already available.
   *
   * Note that use of this function is discouraged; to ease future maintenance use the useErrorReporter hook instead.
   */
  static get() {
    return this.initialize(false);
  }

  /**
   * Initialize a global instance of the ErrorReporter class; this should be called as early as possible in the
   * application lifecycle.
   */
  static initialize(production?: boolean) {
    const proxy = getProxySDK();
    let instance: SentryReporter | undefined = proxy[_reporter];

    if (instance === undefined || !(instance instanceof ErrorReporter)) {
      instance = new ErrorReporter(production);
      proxy[_reporter] = instance;
    }

    return instance;
  }

  constructor(production = process.env.NODE_ENV === 'production') {
    super();
    this.history = [];
    const allowUrls = !!production
      ? [/^https?:\/\/.*\.?commandbar.com/]
      : [/^webpack-internal/, /^https?:\/\/.*\.?commandbar.com/];
    let environment = process.env.REACT_APP_BUILD_TARGET || 'dev';
    if (environment !== 'dev' && window.location.href.includes('localhost')) {
      environment = `customer-dev__${environment}`;
    }
    const client = new BrowserClient({
      allowUrls,
      attachStacktrace: true,
      autoSessionTracking: true,
      beforeSend: (event, hint) => {
        if (hint && hint.originalException) {
          AnalyticsAPI.error(
            typeof hint.originalException === 'string' ? hint.originalException : hint.originalException.message,
            event.extra,
          );
        }

        // https://github.com/getsentry/sentry-javascript/issues/2039#issuecomment-519439950
        if (!production || isSilentMode()) {
          if (!production) {
            this.history.push({ event, hint });
            this.history = this.history.slice(-HISTORY_BUFFER);
          }
          return null;
        }

        return event;
      },
      debug: !production,
      defaultIntegrations: false,
      denyUrls: [
        // Reference: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
        /pagead\/js/i,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      dsn: PROD_DSN,
      environment,
      ignoreErrors: [
        // Reference: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        '_avast_submit',
      ],
      integrations: [
        new Integrations.InboundFilters(),
        new Integrations.FunctionToString(),
        // NOTE: Carefully evaluate whether this includes sensitive customer information before turning this one on:
        // https://docs.sentry.io/platforms/javascript/configuration/integrations/default/#breadcrumbs
        // new Integrations.Breadcrumbs(),
        new Integrations.LinkedErrors(),
        new Integrations.UserAgent(),
        new Integrations.Dedupe(),
      ],
      maxBreadcrumbs: 300,
      release: process.env.REACT_APP_NPM_VERSION || 'dev',
    });

    this.client = client;
    this.hub = new Hub(client);
    this.breadcrumb = this.hub.addBreadcrumb.bind(this.hub);

    const browser = detect();
    if (browser !== null) {
      this.hub.configureScope((scope: any) => {
        scope.setTag('is_customer_dev', window.location.href.includes('localhost'));
        scope.setTag('os', browser.os);
        scope.setTag('browser', browser.name);
        scope.setTag('browser version', browser.version);
        scope.setTag('browser type', browser.type);
        scope.setContext('browser', browser ?? {});
        scope.setContext('site', window.location);
        scope.setContext('allowUrls', { urls: allowUrls.toString() });
      });
    }

    // if (!production) (window as any).__SENTRY__.logger.enable();
  }

  dispose() {
    this.client.close();
    super.dispose();
  }

  /**
   * Report an error to Sentry. The first argument must either be a string or error object, and optionally an error
   * object and extra data (in the form of an object) may be provided as well.
   *
   * If a string message is provided instead of an Error object a new Error will be generated. This will cause the
   * stacktrace to begin at this function; for that reason it is **strongly** recommended to provide an error object
   * so that the original stack trace can be preserved (as originalException).
   */
  exception(message: string, err?: Error, extra?: ExtraData): void;
  exception(message: string, extra?: ExtraData): void;
  exception(err: Error, extra?: ExtraData): void;
  exception(a: Error | string, b?: Error | ExtraData, c?: ExtraData): void {
    const message = typeof a === 'string' ? a : undefined;
    const originalException = (b instanceof Error && b) || (a instanceof Error && a) || undefined;
    const extra = c !== undefined || b instanceof Error ? c : b;
    const err = message === undefined ? originalException : new Error(message);

    if (isErrorCode(message)) {
      console.error('CB error', message, originalException);
    }

    this.hub.withScope((scope) => {
      if (extra) {
        try {
          const { tags, ...extraRest } = extra;
          if (!!tags && typeof tags === 'object') {
            // @ts-expect-error: { [key: string]: Primitive; }
            scope.setTags(tags);
          }
          scope.setExtras(extraRest);
        } catch (err) {
          scope.setExtras(extra);
        }
      }
      this.hub.captureException(err, { originalException });
      if (process.env.NODE_ENV === 'development') console.error(err);
      if (process.env.REACT_APP_BUILD_TARGET === 'test') {
        throw err;
      }
    });
  }

  /**
   * Assigns various scope properties related to the currently active user and organization so that error events can
   * be easily associated with them.
   */
  setUserScope(opts: UserScopeOptions) {
    this.hub.configureScope((scope) => {
      scope.setContext('configuration', opts.configuration);
      scope.setContext('organization', opts.organization || null);
      scope.setTag('organization', opts.organizationId);
      scope.setTag('organization_name', opts.organizationName);
      scope.setTag('session', opts.session);
      scope.setUser({
        cb_organization_name: opts.organizationName,
        cb_organization: opts.organizationId,
        cb_session: opts.session,
        id: opts.userId,
      });
    });
  }
}
