/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import './style/App.css';
import './style/animations.css';

/* Internal imports */
import CommandBar from './components/CommandBar';

import ThemeContext, { defaultTheme } from './contexts/ThemeContext';

import useCommandBar from './engine/useCommandBar';
import { closeBarAndReset, openBarWithOptionalText } from './engine/stateUtils';
import setUpShortcuts from './util/shortcutUtils';
import { organizationService } from './services/organizationService';

import useDidUpdate from './util/useDidUpdate';
import useHotkey from '@commandbar/internal/util/useHotkey';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';

import Reporting, { SEARCH_TRIGGER } from './analytics/Reporting';

import { Emotion } from 'create-emotion';
import { CacheProvider } from '@emotion/core';

import useCustomTheme from './contexts/useCustomTheme';
import { ThemeProvider } from 'emotion-theming';
import Launcher from './components/launcher/LauncherContainer';

import Confetti from './components/Confetti';
import ExecutionPath from './engine/ExecutionPath';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { ExecuteStep } from './engine/step';
import { useErrorReporter } from './error/useErrorReporter';
import { getSDK } from '@commandbar/internal/client/globals';
import { _configuration, _user } from '@commandbar/internal/client/symbols';
import { ErrorReporter } from './error/ErrorReporter';
import { ErrorCode } from './error/ErrorCode';

/*********************************************************************************/

const App = (props: { emotion: Emotion }) => {
  const reporter = useErrorReporter();
  const { state, dispatch } = useCommandBar();
  const {
    executionPathState: { organization, themeSource, baseTheme },
  } = state;

  const setLogo = (svgString: string) => {
    dispatch.executionPathDispatch({ type: 'setLogo', logo: svgString });
  };

  const theme = useCustomTheme({
    userTheme: organization?.theme,
    baseTheme,
    themeSource,
    organization: organization,
    setLogo,
  });

  useEffect(() => {
    organizationService.setOrganization(organization);
  }, [organization]);

  /**
   * [Shorcuts]: set hotkeys on each change of context, callbacks, commands
   **/
  React.useEffect(() => {
    /*
    NOTE: How this can go wrong? (can move to a doc)

    This is using our existing strategy that we might consider rethinking
    We are setting the keybinds for _all_ the commands (whether or not they are admin/drafts/available/etc)

    A protective measure we take is we check availability of a shortcut command before executing it.
    Where does the preventDefault() go?

    Decision: If a keybind (say cmd-d) is set in the host app _and_ in CB, which should fire? Should this be a setting? If CB, we definitely need to exclude draft commands

    Decision: If a command isn't available in the current state, should it preventDefault?

    Mousetrap handles duplicate keybinds by only running the first one.
    How would we handle the following situation:
      - Two commands programmed to never be available at the same time
      - Attached the same keybind (because they are conceptually similar)
      - In this case, we would need to limit shortcut setup to available commands
      - Example (although there's a more elegant solution): "Toggle Dark", "Toggle Light"
     */
    setUpShortcuts(state, dispatch);
  }, [state.executionPathState.allCommands, state.executionPathState.organization]);

  /**
   * Parse url for CommandBar
   * We need to trigger this AFTER commands have been loaded so an empty foobar doesn't show up
   **/
  React.useEffect(() => {
    if (state.executionPathState.options.length > 0) {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('CommandBar')) {
          const inputStr = urlParams.get('CommandBar') || undefined;
          openBarWithOptionalText(state, dispatch, SEARCH_TRIGGER.URL, { startingInput: inputStr });
        }
      } catch (e) {}
    }
  }, [state.executionPathState.options]);

  const reportEndSession = () => {
    Reporting.session.end(state.onboardingState);
  };

  React.useEffect(() => {
    // Tricky stuff. If we want to report when onboarding is exited, we need to pass
    //  the most up to date state to the onload function.
    //  This means we need to redefine the onload function on every onboarding state change
    window.addEventListener('beforeunload', reportEndSession);
    return () => window.removeEventListener('beforeunload', reportEndSession);
  }, [state.onboardingState.isActive, state.onboardingState.currentStepIndex]);

  const refocusInputOnVisibilityChange = () => {
    if (state.active) {
      // FIXME: need to wait for mount to focus
      if (state.refContainer.current !== null) {
        if (state.executionPathState.visible) {
          state.refContainer.current.focus();
        } else {
          state.refContainer.current.blur();
        }
      }
    }
  };
  /* Refresh CommandBar every visibility change after mount */
  useDidUpdate(refocusInputOnVisibilityChange, [state.executionPathState.visible]);
  useEffect(() => {
    if (state.executionPathState.visible) {
      dispatchCustomEvent('CommandBar.opened', { detail: { open: true } });
    }
  }, [state.executionPathState.visible]);

  /**
   * Open / Close / CmdK logic
   **/
  const handleOnClose = () => {
    closeBarAndReset(state, dispatch);
  };

  React.useEffect(() => {
    // Explanation for use of keyup, keydown, and keypress event handlers:
    //   https://www.loom.com/share/ea2e91b99ce545d3a30a25da3823992a

    const handleKeyUp = (e: any) => {
      const parentNode = e.target?.parentNode;
      if (parentNode && parentNode.className === 'commandbar__input') {
        if (e.key === 'Escape') {
          const { currentStepIndex } = ExecutionPath.currentStepAndIndex(state.executionPathState);
          const lastStep = ExecutionPath.lastStep(state.executionPathState);
          e.preventDefault();
          e.stopPropagation();
          if (currentStepIndex === 0) {
            handleOnClose();
          } else if (lastStep.type === 'execute' && (lastStep as ExecuteStep).triggeredByShortcut) {
            handleOnClose();
          } else {
            // Rollback and reset search only if we're not on the start screen, where escape is used to close the bar
            dispatch.executionPathDispatch({ type: 'rollback' });
            state.refContainer?.current?.onInputChange('', undefined);
          }
        }
      }
    };

    const handleKeyDownAndPress = (e: any) => {
      const parentNode = e.target?.parentNode;
      if (parentNode && parentNode.className === 'commandbar__input') {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };

    document.addEventListener('keyup', handleKeyUp, true);
    document.addEventListener('keydown', handleKeyDownAndPress, true);
    document.addEventListener('keypress', handleKeyDownAndPress, true);

    return () => {
      document.removeEventListener('keyup', handleKeyUp, true);
      document.removeEventListener('keydown', handleKeyDownAndPress, true);
      document.removeEventListener('keypress', handleKeyDownAndPress, true);
    };
  }, [state.executionPathState.steps]);

  const onToggle = (trigger: SEARCH_TRIGGER) => {
    if (state.active) {
      if (state.executionPathState.visible) {
        handleOnClose();
      } else {
        openBarWithOptionalText(state, dispatch, trigger);
      }
    }
  };

  const handleOnCMDK = () => {
    if (state.onboardingState.isActive && state.executionPathState.visible) {
      dispatch.onboardingDispatch({ type: 'CMDK' });
    } else {
      onToggle(SEARCH_TRIGGER.KEYBOARD);
      // dispatch an event so clients know when cmd+k is pressed
      dispatchCustomEvent('commandbar-shortcut-executed', {
        detail: { keys: `${osControlKey().toLowerCase() === 'ctrl' ? 'ctrl' : 'command'}+k` },
      });
    }
  };

  const isTextSelected = () => {
    try {
      if (typeof window.getSelection != 'undefined') {
        return (window.getSelection() || '').toString().length > 0;
      }
      return false;
    } catch (err) {
      ErrorReporter.get().exception(ErrorCode.BAR_OPEN, err);
      return false;
    }
  };

  const revert = () => dispatch.executionPathDispatch({ type: 'rollback' });
  useHotkey(
    'k',
    (e: KeyboardEvent) => {
      // Check if text is currently selected in an input - allows customers to keep their cmd+k to 'add link' in text
      // Check if state is active, so we don't override cmd+k behavior if CommandBar is not turned on for a user
      if (!isTextSelected() && state.active) {
        e.stopPropagation();
        e.preventDefault();
        handleOnCMDK();
      }
    },
    // Prevent e.stopPropagation from child elements affecting cmd-k
    // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues
    { useCapture: true, stopPropagation: false, preventDefault: false },
  );
  useHotkey('k', revert, { shift: true });

  //******************************************************************************************/
  // Launcher configuration defined in org model
  // If org hasn't loaded, or failed to load, don't show the launcher
  let launcher;
  const onLauncherClick = () => onToggle(SEARCH_TRIGGER.LAUNCHER);
  if (state.executionPathState.organization) {
    launcher = (
      <div>
        <Launcher
          isAdmin={state.executionPathState.isAdmin}
          organization={state.executionPathState.organization}
          toggle={onLauncherClick}
        />
      </div>
    );
  }

  React.useEffect(() => {
    if (state.active) {
      const sdk = getSDK();
      reporter.setUserScope({
        configuration: sdk[_configuration],
        organization: state.executionPathState.organization,
        organizationId: sdk[_configuration].uuid,
        organizationName: state.executionPathState.organization?.name,
        session: sdk[_configuration].session,
        userId: sdk[_user],
      });
    }
  }, [state.active, state.executionPathState.organization]);

  /******************************************************************/
  /* Render
  /******************************************************************/
  if (theme.theme === undefined) {
    return null;
  }

  return (
    <CacheProvider value={props.emotion.cache}>
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={defaultTheme}>
          {state.active ? (
            <div>
              <CommandBar state={state} dispatch={dispatch} onClose={handleOnClose} />
              {launcher}
              <Confetti />
            </div>
          ) : null}
        </ThemeContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default hot(module)(App);
