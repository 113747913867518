/** @jsx jsx */
import * as React from 'react';
import { useTheme } from 'emotion-theming';
import { jsx } from '@emotion/core';

import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import OptionGroup from '../../../engine/OptionGroup';
import { Option } from '../../../engine/option';

import { useStickyHeader, StickyHeader } from './StickyHeader';
import OptionGroupHeader from './OptionGroupHeader';
import SelectOption from './SelectOption';
import { ICommandCategoryType } from '../../../../../internal/middleware/types';
import { IExecutionPathState } from '../../../engine/ExecutionPath';
import { InterpolationWithTheme } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';

interface IProps {
  categories: ICommandCategoryType[];
  expandedGroupKeys: string[];
  executionPathState: IExecutionPathState;
  focusedIndex: number;
  isLoading: boolean;
  onInputSelect: (opt: Option) => void;
  onOptionHover: (optIndex: number) => void;
  sortedOptions: (Option | OptionGroup)[];
  toggleGroupExpansion: (toggledKey: string) => void;
  emptyMenu: React.ReactNode;
  getNextPageResults: (contextKey?: string) => void;
}

const menuListStyles = (_theme: ITheme): InterpolationWithTheme<any> => {
  return {
    scrollbarWith: 'none' /* Firefox */,
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    ':focus': {
      outline: 'none',
    },
  };
};

function hideItem(index: number, list: (Option | OptionGroup)[]) {
  const opt = list[index];
  const isEmptyHeaderAtTop = opt instanceof OptionGroup && index === 0 && opt.name.length === 0;
  return isEmptyHeaderAtTop;
}

function isItemHeader(index: number, list: (Option | OptionGroup)[]) {
  return list[index] instanceof OptionGroup;
}

function getMinItemHeight(index: number, list: (Option | OptionGroup)[], theme: ITheme) {
  if (hideItem(index, list)) return 0;
  const isHeader = isItemHeader(index, list);
  const defaultMinHeight = isHeader
    ? OptionGroup.getDefaultHeaderHeight(theme, index > 0)
    : parseInt(theme.option.minHeight, 10);
  // Use theme option minHeight instead of cache default minHeight
  return defaultMinHeight;
}

const cache = new CellMeasurerCache({
  // Requires a default height
  defaultHeight: 43,
  fixedWidth: true,
});

const MenuList = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const listRef = React.useRef<any>(undefined);

  const firstVisibleRowIndexRef = React.useRef<number | null>(null);
  const blockAutoScrollRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    cache.clearAll();
    listRef.current?.recomputeRowHeights();
  }, [props.sortedOptions]);

  /*********************************** CALCULATIONS **************************************/
  const itemsToRender = props.sortedOptions;

  const listDOMElement = React.useMemo(
    () => document.getElementById('commandbar-menu-virtualized-list'),
    [listRef.current],
  );

  const getDOMListOfItems = () => {
    return listDOMElement?.children[0]?.children;
  };

  /**
   * CALCULATION: Get the menu height
   */
  const maxMenuHeight = React.useMemo(() => parseInt(theme.bar.menuHeight, 10), [theme.bar.menuHeight]);
  const getMenuHeight = () => {
    let menuHeight = maxMenuHeight;
    if (listRef.current) {
      const DOMListOfItems = getDOMListOfItems();
      if (!!DOMListOfItems) {
        const getItemHeight = (index: number) => {
          // Measure each DOM element. But this can be potentially unreliable because it relies on items to be rendered
          // Use the DOM element if it exists. If not, use an estimate.
          const DOMitem = DOMListOfItems[index];
          return DOMitem ? DOMitem.getBoundingClientRect().height : getMinItemHeight(index, itemsToRender, theme);
        };

        let totalHeight = 0;
        for (let i = 0; i < itemsToRender.length; i++) {
          const rowHeight = getItemHeight(i);
          totalHeight += rowHeight;
          if (totalHeight > maxMenuHeight) {
            break;
          }
        }
        menuHeight = Math.min(maxMenuHeight, totalHeight);
      }
    }
    return menuHeight + parseInt(theme.menu.paddingBottom, 10) + parseInt(theme.menu.paddingTop);
  };

  /**
   * CALCULATION: Autoscrolling logic
   */
  const { stickyHeader, onVisibleRowChange } = useStickyHeader(itemsToRender);

  const itemIndexToAutoScrollTo = React.useMemo(() => {
    if (blockAutoScrollRef.current) {
      blockAutoScrollRef.current = false;
      return undefined;
    }

    //  Because of sticky headers, we add a top buffer
    if (firstVisibleRowIndexRef.current !== null && !!stickyHeader) {
      if (props.focusedIndex <= firstVisibleRowIndexRef.current + 1) {
        return Math.max(props.focusedIndex - 1, 0);
      }
    }
    return props.focusedIndex;
  }, [props.focusedIndex]);

  const lastGroup: OptionGroup | null = React.useMemo(() => {
    for (let i = itemsToRender.length - 1; i >= 0; i--) {
      if (itemsToRender[i] instanceof OptionGroup) {
        return itemsToRender[i] as OptionGroup;
      }
    }
    return null;
  }, [props.sortedOptions]);

  /*********************************** RENDER **************************************/

  const onMouseMoveTurnOffAutoScroll = () => {
    if (!blockAutoScrollRef.current) blockAutoScrollRef.current = true;
  };

  const isEmptyLoadingState = props.isLoading && props.sortedOptions.length === 0;
  if (isEmptyLoadingState) {
    return <div />;
  }

  const isEmptyState = props.sortedOptions.length === 0;
  if (isEmptyState) {
    return <div>{props.emptyMenu}</div>;
  }
  const rowRenderer = ({ key, index, parent, style }: any) => {
    const opt = itemsToRender[index];
    // Always use auto-height to make sure that nodes are rendered in correct size by a browser itself,
    // but limit it by min-height according to theme options
    const minHeight = getMinItemHeight(index, itemsToRender, theme);
    const recalculatedStyle = { ...style, height: 'auto', minHeight };

    return (
      <CellMeasurer cache={cache} key={key} columnIndex={0} parent={parent} rowIndex={index}>
        {hideItem(index, itemsToRender) ? (
          <div />
        ) : (
          <div style={recalculatedStyle}>
            {opt instanceof OptionGroup ? (
              <OptionGroupHeader
                themeContext={theme}
                expandedGroupKeys={props.expandedGroupKeys}
                group={opt}
                toggleExpansion={(key: string) => {
                  props.toggleGroupExpansion(key);
                  blockAutoScrollRef.current = true;
                }}
                addTopPadding={index > 0}
              />
            ) : (
              <SelectOption
                option={opt}
                onOptionHover={() => {
                  props.onOptionHover(index);
                  onMouseMoveTurnOffAutoScroll();
                }}
                isFocused={props.focusedIndex === index}
                themeContext={theme}
                executionPathState={props.executionPathState}
                onSelect={() => props.onInputSelect(opt)}
              />
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  const isEndOfPage = (currentIndex: number) => {
    return currentIndex === itemsToRender.length - 1;
  };

  const isLastGroupExpandedRecordGroup = (): boolean => {
    return !!lastGroup && props.expandedGroupKeys.includes(lastGroup.key);
  };

  const getContextKeyOfLastGroup = (): string | null => {
    if (!lastGroup) {
      return null;
    }
    return OptionGroup.getObjectGroupContextKey(lastGroup);
  };

  return (
    <div style={{ marginTop: theme.menu.marginTop, marginBottom: theme.menu.marginBottom }}>
      <StickyHeader
        header={stickyHeader}
        themeContext={theme}
        expandedGroupKeys={props.expandedGroupKeys}
        toggleExpansion={props.toggleGroupExpansion}
        paddingTop={theme.menu.paddingTop}
      />
      <List
        id="commandbar-menu-virtualized-list"
        ref={listRef}
        style={{ width: '100%', paddingTop: theme.menu.paddingTop, paddingBottom: theme.menu.paddingBottom }}
        css={menuListStyles(theme)}
        width={parseInt(theme.bar.width, 10)}
        height={getMenuHeight()}
        rowHeight={cache.rowHeight}
        deferredMeasurementCache={cache}
        rowCount={itemsToRender.length}
        rowRenderer={rowRenderer}
        onRowsRendered={({ startIndex, stopIndex }: any) => {
          if (isEndOfPage(stopIndex)) {
            let contextKey: string | undefined;
            if (isLastGroupExpandedRecordGroup()) {
              contextKey = getContextKeyOfLastGroup() || undefined;
            }
            props.getNextPageResults(contextKey);
          }
          onVisibleRowChange(startIndex);
          firstVisibleRowIndexRef.current = startIndex;
        }}
        scrollToIndex={itemIndexToAutoScrollTo}
        scrollToAlignment={'auto'}
      />
    </div>
  );
};

export default MenuList;
