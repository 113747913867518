import React from 'react';
import { Step, IStepSelectionParameter } from './Step';
import { IExecutionPathState } from '../ExecutionPath';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { Option, ParameterOption } from '../option';
import { isPrimitive } from '../../client_api/utils';
import _get from 'lodash.get';
import DateTime from '../predefinedTypes/DateTime/DateTime';
import moment from 'moment';
import { ICON_SIZE } from '@commandbar/internal/client/Icon';

export class SelectStep extends Step {
  public argument: IStepArgumentType;
  public selected: IStepSelectionParameter | null;

  constructor(argument: IStepArgumentType) {
    super('select');
    this.argument = argument;
    this.selected = null;
  }

  public setSelections = (selection: any) => {
    if (Array.isArray(selection)) return;
    this.selected = {
      type: 'parameter',
      category: null,
      data: selection,
    };
  };

  // IMPORTANT NOTE:
  // Throughout the ExecutionPath state machine, we are extra careful to
  // avoid mutating the state variables outside of the reducer logic.
  // This is following React best practices which help prevent hard-to-debug issues
  // in the reducer logic.
  //
  // In this case, creating a new step triggers calculation cascades that might shove us into an infinite loop.
  // After testing, there don't seem to be side effects of the Step mutation here. But it is something that we
  // should either 1) confirm is acceptable or 2) refactor if bugs start appearing in Select selections.
  //
  // This method is used when we want to set selections _during_ the Step (e.g. async preselect loaders)
  public select = (option: Option): Step => {
    if (!(option instanceof ParameterOption)) {
      return this;
    }

    const newStep = new SelectStep(this.argument);

    let data = option.parameter;
    if (option.isDateTimeOption()) {
      data = DateTime.clientReturnValue(option.parameter.date);
    }

    newStep.selected = {
      type: 'parameter',
      category: null,
      data,
    };
    newStep.completed = this.completed;

    return newStep;
  };

  public selection = () => {
    if (this.selected === null) {
      return {};
    }

    if (this.selected.type !== 'parameter') {
      return {};
    }
    return { [this.argument.userDefinedValue]: this.selected.data };
  };

  public placeholder = () => {
    return `${this.argument.userDefinedName}`;
  };

  public breadcrumb = (shouldIncludeBreadcrumb: boolean): string | undefined => {
    if (!shouldIncludeBreadcrumb) {
      return undefined;
    }

    if (!this.completed || this.selected === null) {
      return this.argument.userDefinedName;
    }

    if (this.selected.type !== 'parameter') {
      return this.argument.userDefinedName;
    }

    // Note: This block simply tries to catch the different possibilities
    // Since these fields are defined by us,
    // we should try to agree to a standard data structure for these selected elements
    if (Array.isArray(this.selected.data)) {
      throw new Error('Selected must be an object');
    } else {
      const labelField = this.argument.label_field || 'label';
      if (isPrimitive(this.selected.data)) return this.selected.data.toString();
      else if (this.selected.data instanceof Date) {
        // @ts-expect-error: FIXME argument type definitions
        return DateTime.display(moment(this.selected.data.toString()), this.argument.dateTimeArgumentTypeId, false);
      } else {
        const val = _get(this.selected.data, labelField) ?? '';
        return val.toString();
      }
    }
  };

  public fulfill = (_executionPathState: IExecutionPathState) => {
    this.completed = true;
    return this;
  };

  public static defaultIcon = (color: any, opacity: any) => {
    return (
      <div
        style={{ border: `1px solid ${color}`, opacity, borderRadius: '50%', height: ICON_SIZE, width: ICON_SIZE }}
      />
    );
  };
}
