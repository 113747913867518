/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React, { Dispatch, SetStateAction } from 'react';

import { IGuideType, IEndUserType, ICommandCategoryType } from '@commandbar/internal/middleware/types';

import { ExecutionPathReducer, initialExecutionPathState } from './ExecutionPathReducer';
import ExecutionPath, { IExecutionPathState, IExecutionPathDispatchAction } from './ExecutionPath';
import { Option } from '../engine/option';

import OnboardingReducer, {
  initialOnboardingState,
  IOnboardingState,
  IOnboardingDispatch,
} from './onboarding/OnboardingReducer';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import Search, { ISearch } from './Search';
import { ExecuteStep } from './step';
import { getSDK } from '@commandbar/internal/client/globals';
import { _loadEditor } from '@commandbar/internal/client/symbols';
import { initSDK } from '../client_api/sdk';

export interface IFuseResult {
  item: Option;
  score: number;
  matches: IFuseSearchMatchType[];
  refIndex: number;
}

export interface IFuseSearchMatchType {
  key: string;
  value: string;
  indices: number[][];
}

export interface ISearchFilter {
  name: string;
  filter: (options: Option[]) => Option[];
}

export interface ICommandBarState {
  loading: boolean;
  inputText: string;
  dashboard: undefined | React.ReactNode;
  emptyMessage: undefined | string;
  refContainer: any;
  previewMode: boolean;
  active: boolean;
  guides: IGuideType[];
  activeGuide: IGuideType;
  showKeyboardShortcutCheatsheet: boolean;
  categories: ICommandCategoryType[];
  endUser: IEndUserType | undefined;
  onboardingState: IOnboardingState;
  executionPathState: IExecutionPathState;
  searchFilter: ISearchFilter | undefined;
}

export interface ICommandBarDispatch {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDashboard: Dispatch<SetStateAction<undefined | React.ReactNode>>;
  setEmptyMessage: Dispatch<SetStateAction<undefined | string>>;
  setInputText: Dispatch<SetStateAction<string>>;
  setPreviewMode: Dispatch<SetStateAction<boolean>>;
  setActive: Dispatch<SetStateAction<boolean>>;
  setGuides: Dispatch<SetStateAction<IGuideType[]>>;
  setActiveGuide: Dispatch<SetStateAction<IGuideType>>;
  setShowKeyboardShortcutCheatsheet: Dispatch<SetStateAction<boolean>>;
  setCategories: Dispatch<SetStateAction<ICommandCategoryType[]>>;
  setEndUser: Dispatch<SetStateAction<IEndUserType | undefined>>;
  onboardingDispatch: Dispatch<IOnboardingDispatch>;
  executionPathDispatch: Dispatch<IExecutionPathDispatchAction>;
  setSearchFilter: Dispatch<SetStateAction<ISearchFilter | undefined>>;
  search: ISearch;
}

const useCommandBar = (): { state: ICommandBarState; dispatch: ICommandBarDispatch } => {
  /******************************************************************/
  /* Set by client
  /******************************************************************/
  const [previewMode, setPreviewMode] = React.useState(false);

  const [active, setActive] = React.useState(false);

  /******************************************************************/
  /* Internal
  /******************************************************************/

  /* are we performing some async action? */
  const [loading, setLoading] = React.useState(false);

  // A minimum loading window, so we don't have a flashing loader for fast requests
  const [inMinLoadingWindow, setInMinLoadingWindow] = React.useState(false);
  const minLoadingTimeout = React.useRef<any>(undefined);
  const unmounting = React.useRef(false);

  React.useEffect(
    () => () => {
      unmounting.current = true;
    },
    [],
  );

  React.useEffect(() => {
    if (loading) {
      setInMinLoadingWindow(true);
      // Clear old timeout so that a previous timeout doesn't complete and turn off loading
      if (minLoadingTimeout) clearTimeout(minLoadingTimeout.current);
      minLoadingTimeout.current = setTimeout(() => {
        if (unmounting.current) return console.warn('attempted to set state after unmount');
        setInMinLoadingWindow(false);
      }, 1000);
    }
  }, [loading]);

  const [endUser, setEndUser] = React.useState<IEndUserType | undefined>(undefined);

  const init = () => {
    return initialExecutionPathState;
  };
  const [executionPathState, executionPathDispatch]: [
    IExecutionPathState,
    React.Dispatch<IExecutionPathDispatchAction>,
  ] = React.useReducer(ExecutionPathReducer, initialExecutionPathState, init);

  const initOnboarding = () => {
    return initialOnboardingState;
  };
  const [onboardingState, onboardingDispatch]: [IOnboardingState, React.Dispatch<IOnboardingDispatch>] =
    React.useReducer(OnboardingReducer, initialOnboardingState, initOnboarding);

  const search: ISearch = Search;

  // If the commands haven't loaded yet, set a loading state
  React.useEffect(() => {
    if (!executionPathState.commandsLoaded) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [executionPathState.commandsLoaded]);

  ////////////////////////////////////

  /* what has the user typed into the Input? */
  const [inputText, setInputText] = React.useState('');

  /* Search filters */
  const [searchFilter, setSearchFilter] = React.useState<ISearchFilter | undefined>(undefined);

  /* dashboard content */
  const [dashboard, setDashboard] = React.useState(undefined as undefined | React.ReactNode);

  /* Message display if generator returns no queries */
  const [emptyMessage, setEmptyMessage] = React.useState(undefined as undefined | string);

  /* The guides for the organization */
  const [guides, setGuides] = React.useState<IGuideType[]>([]);

  /* The categories for the organization */
  const [categories, setCategories] = React.useState<ICommandCategoryType[]>([]);

  /* Message to be displayed via tooltip to user */
  const [activeGuide, setActiveGuide] = React.useState<IGuideType>({
    id: -1,
    organization: '',
    event: '',
    nudge: '',
    guidance: '',
    preview: false,
  });

  // For setting focus
  const refContainer: any = React.useRef(null);

  /* Keyboard shortcut cheatsheet */
  const [showKeyboardShortcutCheatsheet, setShowKeyboardShortcutCheatsheet] = React.useState(false);
  const toggleKeyboardShortcutCheatsheet = () => {
    setShowKeyboardShortcutCheatsheet(!showKeyboardShortcutCheatsheet);
  };

  /* Callbacks that should be auto-added */
  const builtInCallbacks = {
    'commandbar-builtin-shortcuts': toggleKeyboardShortcutCheatsheet,
    'commandbar-noop': function (args: any) {
      alert(`This is a demo command!\n\nYou entered:\n\n${JSON.stringify(args, null, 2)}`);
    },
  };
  React.useEffect(() => {
    executionPathDispatch({
      type: 'addCallbacks',
      callbacks: builtInCallbacks,
    });
  }, []);

  React.useEffect(() => {
    // Dashboard was turned off
    if (dashboard === undefined && inputText !== '' && executionPathState.allCommands.length > 0) {
      setInputText('');
      executionPathDispatch({ type: 'reset' });
    }
  }, [dashboard]);

  /******************************************************************/
  /* ComponentDidUpdate logic
  /******************************************************************/

  React.useEffect(() => {
    const showEditor = LocalStorage.get('editor', '');
    if (!!showEditor) {
      getSDK()[_loadEditor]();
    }
  }, []);

  React.useEffect(() => {
    if (executionPathState.isAdmin) {
      if (executionPathState.testMode) {
        LocalStorage.set('testMode', true);
      } else {
        LocalStorage.remove('testMode');
      }
    }
  }, [executionPathState.testMode]);
  /******************************************************************/
  // eslint-disable-next-line unused-imports/no-unused-vars
  const isOpenedByShortcut = React.useMemo(() => {
    const lastStep = ExecutionPath.lastStep(executionPathState);
    return !!(lastStep.type === 'execute' && (lastStep as ExecuteStep).triggeredByShortcut);
  }, [executionPathState.steps]);

  const state = {
    loading: loading || inMinLoadingWindow,
    inputText,
    dashboard,
    emptyMessage,
    refContainer,
    previewMode,
    active,
    guides,
    activeGuide,
    showKeyboardShortcutCheatsheet,
    categories,
    endUser,

    onboardingState,

    executionPathState,
    searchFilter,
  };
  const dispatch = {
    setLoading,
    setInputText,
    setDashboard,
    setEmptyMessage,
    setPreviewMode,
    setActive,
    setGuides,
    setActiveGuide,
    setShowKeyboardShortcutCheatsheet,
    setCategories,
    setEndUser,

    onboardingDispatch,

    executionPathDispatch,
    search,
    setSearchFilter,
  };

  initSDK(state, dispatch);

  return { state, dispatch };
};

export default useCommandBar;
