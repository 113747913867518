import { Step, IStepSelectionCommand } from './Step';
import { IExecutionPathState } from '../ExecutionPath';
import { Option, CommandOption, ResourceOption } from '../option';

export class ChooseCommandOrResourceStep extends Step {
  public selected: IStepSelectionCommand | null;
  public resource: ResourceOption | null;

  constructor(resource: ResourceOption | null) {
    super('chooseCommandOrResource');
    this.selected = null;
    this.resource = resource;
  }

  public select = (option: Option): Step => {
    if (option instanceof CommandOption) {
      // immutable
      const newStep = new ChooseCommandOrResourceStep(this.resource);

      newStep.selected = {
        type: 'command',
        label: option.label,
        category: option.command.category,
        data: option.command,
      };
      newStep.completed = this.completed;

      return newStep;
    }

    return this;
  };

  public selection = () => {
    return {};
  };

  public breadcrumb = (shouldIncludeBreadcrumb: boolean): string | undefined => {
    if (!shouldIncludeBreadcrumb) {
      return undefined;
    }

    if (this.selected !== null && this.selected.type === 'command') {
      return this.selected.label;
    }

    if (this.resource) {
      return this.resource.label;
    }

    return undefined;
  };

  public fulfill = (_executionPathState: IExecutionPathState) => {
    this.completed = true;
    return this;
  };
}
