import React from 'react';

import { Step, IStepSelectionParameter } from './Step';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { IExecutionPathState } from '../ExecutionPath';
import { Option, ParameterOption } from '../option';
import { isPrimitive } from '../../client_api/utils';
import _get from 'lodash.get';
import isEqual from 'lodash.isequal';
import DateTime from '../predefinedTypes/DateTime/DateTime';
import Icon, { ICON_SIZE } from '@commandbar/internal/client/Icon';
import { CgCheck } from 'react-icons/cg';
import { ITheme } from '@commandbar/internal/client/theme';

export class MultiSelectStep extends Step {
  public argument: IStepArgumentType;
  public selected: IStepSelectionParameter | null;

  constructor(argument: IStepArgumentType) {
    super('multiselect');
    this.argument = argument;
    this.selected = null;
  }

  // IMPORTANT NOTE:
  // Throughout the ExecutionPath state machine, we are extra careful to
  // avoid mutating the state variables outside of the reducer logic.
  // This is following React best practices which help prevent hard-to-debug issues
  // in the reducer logic.
  //
  // In this case, creating a new step triggers calculation cascades that might shove us into an infinite loop.
  // After testing, there don't seem to be side effects of the Step mutation here. But it is something that we
  // should either 1) confirm is acceptable or 2) refactor if bugs start appearing in MultiSelect selections.
  //
  // This method is used when we want to set selections _during_ the Step (e.g. async preselect loaders)
  public setSelections = (selections: any[]) => {
    let _selections = selections;
    if (!Array.isArray(selections)) _selections = [selections];
    this.selected = {
      type: 'parameter',
      category: null,
      data: [..._selections],
    };
  };

  public select = (option: Option): Step => {
    if (!(option instanceof ParameterOption)) {
      return this;
    }

    // Add created flag if applicable
    let newParam = option.parameter;
    if (option.isDateTimeOption()) {
      newParam = DateTime.clientReturnValue(option.parameter.date);
    }

    // For a multiselect step, toggle whether the parameter is selected
    //     If it's already selected, remove it. If not, add it.
    const newSelections: any[] = this.selected?.data ? [...this.selected.data] : [];
    const index = newSelections.findIndex((s: any) => isEqual(s, newParam));
    if (index > -1) {
      newSelections.splice(index, 1);
    } else {
      newSelections.push(newParam);
    }

    const newStep = new MultiSelectStep(this.argument);
    newStep.selected = {
      type: 'parameter',
      category: null,
      data: newSelections,
    };
    newStep.completed = this.completed;

    return newStep;
  };

  public selection = () => {
    // Return empty array if none are selected
    if (this.selected === null) {
      return { [this.argument.userDefinedValue]: [] };
    }

    if (this.selected.type !== 'parameter') {
      return {};
    }

    return { [this.argument.userDefinedValue]: this.selected.data };
  };

  public placeholder = () => {
    return `${this.argument.userDefinedName}`;
  };

  public breadcrumb = (shouldIncludeBreadcrumb: boolean): string | undefined => {
    if (!shouldIncludeBreadcrumb) {
      return undefined;
    }

    if (this.selected === null) {
      return this.argument.userDefinedName;
    }

    if (this.selected.type !== 'parameter') {
      return this.argument.userDefinedName;
    }

    // Note: This block simply tries to catch the different possibilities
    // Since these fields are defined by us,
    // we should try to agree to a standard data structure for these selected elements
    if (!Array.isArray(this.selected.data)) {
      throw new Error('Multi select selected must be an array');
    } else {
      const labelField = this.argument.label_field || 'label';
      const stringified = this.selected.data.map((val: any) => {
        return isPrimitive(val) ? val.toString() : _get(val, labelField)?.toString();
      });
      return stringified.join(', ');
    }
  };

  public fulfill = (_executionPathState: IExecutionPathState) => {
    this.completed = true;
    return this;
  };

  public static selectedIcon = (
    theme: ITheme,
    iconOptions: {
      userDefinedIcon: any;
      useDefaultSVGColor: boolean;
    },
  ) => {
    const selectedColor = theme.optionSelected.iconColor;
    if (!!iconOptions.userDefinedIcon) {
      return (
        <Icon
          icon={iconOptions.userDefinedIcon}
          default={<div style={{ minWidth: ICON_SIZE, display: 'inline-block' }} />}
          style={{
            minWidth: ICON_SIZE,
            padding: 1,
            border: `2px solid ${selectedColor}`,
          }}
          useDefaultSVGColor={iconOptions.useDefaultSVGColor}
        />
      );
    } else {
      return MultiSelectStep.defaultSelectedIcon(selectedColor);
    }
  };

  public static defaultSelectedIcon = (color: any) => {
    return (
      <div
        style={{
          border: `2px solid ${color}`,
          borderRadius: '50%',
          height: ICON_SIZE,
          width: ICON_SIZE,
          display: 'inline-block',
        }}
      >
        <CgCheck size={`${ICON_SIZE - 4}px`} style={{ color }} />
      </div>
    );
  };

  public static defaultIcon = (color: any, opacity: any) => {
    return (
      <div
        style={{
          border: `1px solid ${color}`,
          opacity,
          borderRadius: '50%',
          height: ICON_SIZE,
          width: ICON_SIZE,
          display: 'inline-block',
        }}
      />
    );
  };
}
