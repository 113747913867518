import React from 'react';
import OptionGroup from '../../../engine/OptionGroup';
import OptionGroupHeader from './OptionGroupHeader';

interface IHeader {
  group: OptionGroup;
  index: number;
}

export const useStickyHeader = (itemsToRender: any[] = []) => {
  const [headersInReverseOrder, setHeadersInReverseOrder] = React.useState<IHeader[]>([]);
  const [stickyHeader, setStickyHeader] = React.useState<IHeader | undefined>(undefined);
  // Storing the last visible row as a ref to limit re-renders
  const visibleRowIndex = React.useRef<number | null>(null);

  React.useEffect(() => {
    setHeadersInReverseOrder(getHeadersInReverseOrder());
  }, [itemsToRender]);

  React.useEffect(() => {
    if (visibleRowIndex.current !== null) {
      recalculateStickyHeader(visibleRowIndex.current);
    }
  }, [headersInReverseOrder]);

  const onVisibleRowChange = (rowIndex: number) => {
    if (rowIndex !== visibleRowIndex.current) {
      recalculateStickyHeader(rowIndex);
      visibleRowIndex.current = rowIndex;
    }
  };

  const recalculateStickyHeader = (firstVisibleRowIndex: number) => {
    setStickyHeader(findStickyHeader(firstVisibleRowIndex));
  };

  const getHeadersInReverseOrder = (): IHeader[] => {
    const headers = itemsToRender.reduce((acc: IHeader[], value: any, index: number) => {
      if (value instanceof OptionGroup) {
        return [...acc, { group: value, index }];
      } else {
        return acc;
      }
    }, []);

    return headers.reverse();
  };

  const findStickyHeader = (startIndex: number): IHeader | undefined => {
    const header = headersInReverseOrder.find((headerPosition) => headerPosition.index <= startIndex);
    return !!header?.group.name ? header : undefined;
  };

  return { stickyHeader, onVisibleRowChange };
};

export const StickyHeader = (props: {
  header: IHeader | undefined;
  themeContext: any;
  expandedGroupKeys: string[];
  toggleExpansion: (key: string) => void;
  paddingTop: string;
}) => {
  const { header, ...headerProps } = props;
  if (!header) {
    return null;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          position: 'absolute',
          paddingTop: props.paddingTop,
          top: 0,
          zIndex: 10,
          width: '100%',
          background: props.themeContext.main.background,
        }}
      >
        <div style={{ position: 'relative' }}>
          <OptionGroupHeader key={`sticky-header`} group={header.group} addTopPadding={false} {...headerProps} />
        </div>
      </div>
    </div>
  );
};
