/**
 * When a client defines a search function:
 *   - The "initial values" defined are added to context, indexed by the plain context key
 *   - The "dynamic search vales" are hot added to and cleared from context, indexed by the special key 'commandbar-search-{context_key}'
 *
 *  See example here: https://www.loom.com/share/2f3178f641c747899a0d1ebbac5af6d2
 */
import { getActiveArgumentClientSearchKey, IExecutionPathState } from './ExecutionPath';
import { getSDK } from '@commandbar/internal/client/globals';
import { SearchResultsPaginated } from '@commandbar/internal/client/AddContextOptions';

const CONTEXT_PREFIX = 'commandbar-search-';
const prependPrefix = (key: string, prefix: string) => `${prefix}${key}`;

const paginationMap = new Map<string, any>();

const get = (key: string, executionPathState: IExecutionPathState) => {
  const searchContextKey = prependPrefix(key, CONTEXT_PREFIX);
  const searchResults = executionPathState.context[searchContextKey];
  const initialValue = executionPathState.context[key];

  if (!!searchResults && !Array.isArray(searchResults)) {
    console.log(`Client search results for ${key} must be an array`);
  }

  return searchResults || initialValue;
};

const isPaginatedResult = (results: unknown[] | SearchResultsPaginated): results is SearchResultsPaginated => {
  return results.hasOwnProperty('data');
};

const standardizeResults = (
  results: unknown[] | SearchResultsPaginated,
): {
  data: unknown[];
  pagination: unknown;
} => {
  if (isPaginatedResult(results)) {
    const data = Array.isArray(results.data) ? results.data : [];
    const pagination = results.hasOwnProperty('pagination') ? results.pagination : null;
    return { data, pagination };
  } else {
    const data = Array.isArray(results) ? results : [];
    return { data, pagination: null };
  }
};

const set = (key: string, results: any) => {
  const searchContextKey = prependPrefix(key, CONTEXT_PREFIX);
  const { data, pagination } = standardizeResults(results);
  paginationMap.set(searchContextKey, pagination);
  getSDK().addContext(searchContextKey, data);
};

const append = (key: string, results: any) => {
  const searchContextKey = prependPrefix(key, CONTEXT_PREFIX);
  const { data, pagination } = standardizeResults(results);

  const existingData = getSDK().shareContext()[searchContextKey];
  if (Array.isArray(existingData)) {
    getSDK().addContext(searchContextKey, [...existingData, ...data]);
  } else {
    getSDK().addContext(searchContextKey, data);
  }
  paginationMap.set(searchContextKey, pagination);
};

const clear = (key: string) => {
  const searchContextKey = prependPrefix(key, CONTEXT_PREFIX);
  getSDK().removeContext(searchContextKey);
  paginationMap.delete(searchContextKey);
};

const isDefined = (key: string, executionPathState: IExecutionPathState) => {
  return Object.keys(executionPathState.callbacks).some((callbackKey: string) =>
    callbackKey.includes(`commandbar-search-${key}`),
  );
};

const getNextPageResults = async (
  executionPathState: IExecutionPathState,
  inputText: string,
  setLoading: (loading: boolean) => void,
  contextKey?: string,
) => {
  const key = contextKey || getActiveArgumentClientSearchKey(executionPathState);
  if (!!key) {
    const searchContextKey = prependPrefix(key, CONTEXT_PREFIX);
    const pagination = paginationMap.get(searchContextKey);

    if (!!pagination) {
      setLoading(true);
      try {
        paginationMap.delete(searchContextKey);
        const fn = executionPathState.callbacks[searchContextKey];
        if (fn) {
          const searchResults = await fn(inputText, pagination);
          ClientSearch.append(key, searchResults);
        }
      } catch (e) {}
      setLoading(false);
    }
  }
};

const ClientSearch = {
  isDefined,
  set,
  append,
  get,
  clear,
  getNextPageResults,
};

export default ClientSearch;
