import { ICommandType } from '@commandbar/internal/middleware/types';
import { ICommandBarDispatch, ICommandBarState } from './useCommandBar';
import Reporting, { SEARCH_TRIGGER } from '../analytics/Reporting';
import { markGuideAsSeen } from '../components/launcher/LauncherNudgeCache';
import { ChooseCommandOrResourceStep } from '../engine/step';
import { CommandOption } from '../engine/option';
import { getSDK } from '@commandbar/internal/client/globals';

/*************************************************************************/

interface IOpenBarOptions {
  startingInput?: string;
  categoryFilterID?: number;
}

export const openBarWithOptionalText = (
  state: ICommandBarState,
  dispatch: ICommandBarDispatch,
  trigger: SEARCH_TRIGGER,
  meta?: IOpenBarOptions,
) => {
  // Make command bar visible, reset dashboard
  dispatch.setDashboard(undefined);
  dispatch.executionPathDispatch({ type: 'setVisible', visible: true });

  // Send start signal to onboarding dispatch. The onboarding reducer will ignore signals if already active
  dispatch.onboardingDispatch({ type: 'Start', data: { trigger: 'new-user-open' } });

  // Mark guides as seen so they don't show again
  // FIXME: Guides are disabled
  markGuideAsSeen('showcase');
  markGuideAsSeen(state.activeGuide.event);

  // If there is text passed in, set the text in the bar
  if (meta?.startingInput) {
    state.refContainer?.current?.onInputChange(meta.startingInput, undefined);
  }

  // Set the search filter for the category
  if (meta?.categoryFilterID) {
    const categoryToFilter = state.categories.find((cat) => cat.id === meta.categoryFilterID);
    if (categoryToFilter) {
      const optionFilter = (options: any[]) => options.filter((o) => o?.command?.category === meta.categoryFilterID);
      dispatch.setSearchFilter({ name: categoryToFilter.name, filter: optionFilter });
    }
  }

  // Report the new search
  Reporting.search.start(state.onboardingState, trigger);
};

/*************************************************************************/

export const closeBarAndReset = (state: ICommandBarState, dispatch: ICommandBarDispatch) => {
  // Report the end of the search. Do it before resetting state
  Reporting.search.end(state.inputText, state.executionPathState, state.onboardingState);

  // @ts-expect-error: FIXME dashboard type definition
  if (state.dashboard?.type?.name === 'SubmissionForm') {
    dispatch.setDashboard(undefined);
    return;
  }

  // Close the bar and clean up
  dispatch.executionPathDispatch({ type: 'setVisible', visible: false });
  dispatch.setDashboard(undefined);
  dispatch.setSearchFilter(undefined);

  // Input value does not blink when command bar closes
  setTimeout(() => {
    dispatch.setInputText('');
  }, 100);

  dispatch.setActiveGuide({ id: -1, organization: '', event: '', nudge: '', guidance: '' });

  // Send a close signal to the onboarding reducerp
  dispatch.onboardingDispatch({ type: 'Exit' });
};

export const setCommands = (dispatch: ICommandBarDispatch, commands: any[]) => {
  dispatch.executionPathDispatch({
    type: 'setCommands',
    commands: commands,
  });
};

/*************************************************************************/

export const executeCommand = (
  command: ICommandType,
  state: ICommandBarState,
  dispatch: ICommandBarDispatch,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  // Pseudo state
  const pseudoExecutionPathState = {
    ...state.executionPathState,
    context: getSDK().shareContext(),
    callbacks: getSDK().shareCallbacks(),
    steps: [new ChooseCommandOrResourceStep(null)],
  };

  const commandOption = new CommandOption(pseudoExecutionPathState, command);
  const { isDisabled } = commandOption.optionDisabled;

  if (!isDisabled) {
    commandOption.choose(pseudoExecutionPathState, dispatch.executionPathDispatch, true);
    onSuccess && onSuccess();
  } else {
    onFail && onFail();
  }

  return isDisabled;
};
