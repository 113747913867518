import ExecutionPath, { IExecutionPathState } from '../engine/ExecutionPath';
import _get from 'lodash.get';
import { InternalError } from './Errors';

/******************************************************************/
/* Constants
/******************************************************************/

// Interpolate regexps: matches inclusive of syntax, e.g., {{context.value}}
// Extract regexps: matches exclusive of syntax, e.g., value
export const COMMANDBAR_VARIABLE_REGEXP_INTERPOLATE = /{{([^{{]+)}}+/g;
export const COMMANDBAR_CONTEXT_REFERENCE_REGEXP_INTERPOLATE = /{{context.([^{{]+)}}+/g;
export const COMMANDBAR_CONTEXT_REFERENCE_REGEXP_EXTRACT = /((?!{{)context\.)([^{{]+)(?=}})+/g;

export const MAX_UNFURL_LENGTH = 5000;

export const INTERNAL_FIELD_PREFIX = '_cb';

/******************************************************************/
/******************************************************************/

/*
 * Given an object, extract object.key, if it exists.
 */
export const _getValue = (
  value: string | number | { [arg: string]: any } | undefined | any[],
  key: string | undefined,
  throwErrorIfUndefined?: boolean,
  executionPathState?: IExecutionPathState,
) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  switch (typeof value) {
    case 'string':
    case 'number':
      return value;
    case 'object':
      if (Array.isArray(value)) {
        return value.toString();
      }

      /* value is an object */
      if (key) {
        const toRet = _get(value, key);
        if (toRet === undefined && throwErrorIfUndefined) {
          throw new InternalError(`Cannot interpolate ${key} from context`, executionPathState);
        }
        return toRet;
      }

      return '';
  }
};

export const _replaceString = (str: string, match: any, fn: any) => {
  let curCharStart = 0;
  let curCharLen = 0;

  if (str === '') {
    return [''];
  }

  let re = match;
  re = new RegExp(re, 'gi');

  const result = str.split(re);

  // Apply fn to all odd elements
  for (let i = 1, length = result.length; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = fn(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
};

/**
 * Interpolate variables in strings
 * @input www.google.com/{{context.var}}/{{arg.fieldName}}
 * @output www.google.com/foo/bar
 */
export const interpolate = (
  s: string,
  executionPathState: IExecutionPathState,
  interpolateContext: boolean,
  interpolateArgs: boolean,
  throwErrorIfUndefined?: boolean,
): string => {
  let toRet = s;
  // First interpolate: www.google.com/{{context.var}}
  if (interpolateContext) {
    toRet = _replaceString(s, COMMANDBAR_CONTEXT_REFERENCE_REGEXP_INTERPOLATE, (match: string) => {
      const contextValue = _getValue(executionPathState.context, match, throwErrorIfUndefined, executionPathState);
      // If the context value is an object return obj.value
      return _getValue(contextValue, 'value', throwErrorIfUndefined, executionPathState);
    }).join('');
  }
  if (interpolateArgs) {
    // Then interpolate: www.google.com/foo/{{arg.fieldName}}
    toRet = _replaceString(toRet, COMMANDBAR_VARIABLE_REGEXP_INTERPOLATE, (match: string) => {
      const arg = match.split('.')[0];
      const key = match.split('.').slice(1).join('.');
      const selections = ExecutionPath.selections(executionPathState);
      if (selections.hasOwnProperty(arg)) {
        return _getValue(selections[arg], key || 'value');
      }

      return '';
    }).join('');
  }

  return toRet;
};
