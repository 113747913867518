/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import Z from '@commandbar/internal/client/Z';
import { IOrganizationType } from '@commandbar/internal/middleware/OrganizationV';
import { useTheme } from 'emotion-theming';
import * as React from 'react';
import ReactDOM from 'react-dom';

export enum BarFormFactor {
  MODAL,
  INLINE,
}

export const INLINE_ROOT_ID = 'commandbar-inline-root';

function showInlineBar(organization: IOrganizationType | undefined) {
  // Custom flag for gusto
  return organization?.id === '5725a2a3';
}

export function getFormFactor(organization: IOrganizationType | undefined): BarFormFactor {
  return showInlineBar(organization) ? BarFormFactor.INLINE : BarFormFactor.MODAL;
}

/*********************************************************************************/
interface IProps {
  close: () => void;
  open: () => void;
  rootElement: Element;
  children: React.ReactNode;
}

const InlineBar = (props: IProps) => {
  const { theme } = useTheme();
  const inlineBar = (
    <div style={{ position: 'relative', width: '100%' }} onBlur={props.close} onClick={props.open}>
      <div
        style={{
          backgroundColor: theme.main.background,
          borderRadius: theme.main.borderRadius,
          border: `1px solid ${theme.main.borderColors}`,
          width: 'inherit',
          zIndex: Z.Z_EDITOR,
          boxShadow: theme.main.boxShadow,
          position: 'absolute',
          top: 0,
        }}
      >
        {props.children}
      </div>
    </div>
  );
  return ReactDOM.createPortal(inlineBar, props.rootElement);
};

export default InlineBar;
