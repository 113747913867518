import { Step } from './Step';
import { ICommandType } from '@commandbar/internal/middleware/types';
import ExecutionPath, { IExecutionPathState } from '../ExecutionPath';
import { Option } from '../option';

import { interpolate } from '../../engine/Interpolate';
import { clickElement, clickElementsWithPause, checkSelector } from '../../util/dom';
import { InternalError } from '../Errors';
import Reporting from '../../analytics/Reporting';

import { releaseConfetti } from '../../components/Confetti';
import { ErrorReporter } from '../../error/ErrorReporter';
import { ErrorCode } from '../../error/ErrorCode';

// Error handler for click-based commands
const reportClickFailure = (command: ICommandType, selector: string) => {
  ErrorReporter.get().exception(ErrorCode.CLICK, { command: command.id, selector });
};

// Error handler for callbacks attached to commands
const runAndReportFailure = (f: (arg1: any, arg2: any) => any, arg1: any, arg2: any, command: ICommandType) => {
  try {
    return f(arg1, arg2);
  } catch (e) {
    ErrorReporter.get().exception(ErrorCode.CALLBACK, e, {
      errorMsg: 'Command execution failed.',
      command: command.id,
      commandText: command.text,
    });
    // REVIEW: This might cause a duplicate error report?
    throw e;
  }
};

// Never use eval()!
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
const dangerouslyRunJS = (script: string, _args: any, _context: any) => {
  const executable = '"use strict";return ( function(args, context){' + script + '})';
  // eslint-disable-next-line no-new-func
  return Function(executable)()(_args, _context);
};

const isValidURL = (str: string): boolean => {
  // FIXME: Handle situations like url = {{selected.url}}
  if (str.length === 0) return true;

  const URLpattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  const mailpattern = new RegExp('mailto:.*');
  const pathPattern = new RegExp('\\/.*');
  const interpolatePattern = new RegExp('^{{.*}}$');
  return !!URLpattern.test(str) || !!mailpattern.test(str) || !!pathPattern.test(str) || !!interpolatePattern.test(str);
};

export class ExecuteStep extends Step {
  public command: ICommandType;
  public selected: null;
  public triggeredByShortcut: boolean;

  constructor(command: ICommandType, triggeredByShortcut: boolean) {
    super('execute');
    this.command = command;
    this.selected = null;
    this.triggeredByShortcut = triggeredByShortcut;
  }

  public select = (_option: Option): Step => {
    return this;
  };

  public selection = () => {
    return {};
  };

  public breadcrumb = (_shouldIncludeBreadcrumb: boolean): string | undefined => {
    return undefined;
  };

  public fulfill = (executionPathState: IExecutionPathState): Step => {
    if (this.completed) {
      return this;
    }

    const getExecutable = (command: ICommandType, _executionPathState: IExecutionPathState): (() => any) => {
      switch (command.template.type) {
        case 'link':
          // Is the interpolated URL valid?
          const url = interpolate(command.template.value, _executionPathState, true, true, true);
          if (command.template.operation !== 'router' && !isValidURL(url)) {
            throw new InternalError(`URL to execute is invalid: ${url}`, _executionPathState);
          }

          switch (command.template.operation) {
            case 'router':
              const routerFunc = _executionPathState.callbacks['commandbar-router'];
              if (routerFunc) {
                return () => routerFunc(url);
              } else {
                throw new InternalError('Link is of router type, but router is not defined.', _executionPathState);
              }
            case 'self':
              return () => window.open(url, '_self');
            case 'blank':
              return () => window.open(url, '_blank');
            default:
              return () => window.open(url, '_blank');
          }

          break;
        case 'admin':
        case 'builtin':
        case 'callback':
          const callbackName = command.template.value;
          const callback = executionPathState.callbacks[callbackName];
          if (!callback) {
            throw new InternalError(`Callback is not available: [${callbackName}]`, _executionPathState);
          }
          return () => this.executeCallback(_executionPathState, callback);
        case 'clickByXpath':
        case 'clickBySelector':
        case 'click':
          const values = command.template.value.map((el: any) => interpolate(el, _executionPathState, true, true));
          if (!checkSelector(values[0])) {
            throw new InternalError(`Cannot find element to click: [${values[0]}]`, _executionPathState);
          }

          if (command.template.value.length === 1) {
            return () => clickElement(values[0], reportClickFailure.bind({}, command));
          } else {
            return () => clickElementsWithPause(0, values, reportClickFailure.bind({}, command));
          }
        case 'webhook':
          return () => {
            const webhook = command.template.value.toString();
            const context = _executionPathState.context;
            const args = ExecutionPath.selections(_executionPathState);

            const payload = {
              args,
              context,
            };

            fetch(webhook, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });
          };
        case 'script':
          return () => {
            let script = '';
            const org_uid = _executionPathState?.organization?.id
              ? _executionPathState?.organization?.id.toString()
              : '';

            const context = _executionPathState.context;
            const args = ExecutionPath.selections(_executionPathState);

            // FIXME: feature flag
            // catchandrelease
            if (['8eafe599'].includes(org_uid)) {
              script = command.template.value.toString();
            }
            dangerouslyRunJS(script, args, context);
          };
        default:
          throw new InternalError('Invalid command execute type.', _executionPathState);
      }
    };

    const executable = getExecutable(this.command, executionPathState);

    // FIXME
    // Executing a command may have side-effects on the underlying app
    // The most relevant side-effect is the underlying app calling other
    // Command Bar APIs (like addContext)
    //
    // This timeout allows the ExecutionPathReducer to complete it's current
    // step before updating anything else.
    //
    // A more robust solution here would be to set a flag to wait for all Executes to
    // finish before allowing any API calls (add them to a pending queue while the flag is on)
    // That flag would probably live on window (we can't set it in the Reducer)
    //
    // This will probably also break if we are chaining multiple Execute steps together
    if (!executionPathState.simulation) {
      // @REPORTING: don't have access to input text here
      const meta = this.triggeredByShortcut ? { shortcut: true } : {};
      Reporting.execution(this.command, '', meta);

      if (!!this.command.celebrate) {
        releaseConfetti(this.command.celebrate);
      }

      setTimeout(() => {
        executable();
      }, 5);
    }

    this.completed = true;
    return this;
  };

  private executeCallback = (executionPathState: IExecutionPathState, callback: any) => {
    const argValues = ExecutionPath.selections(executionPathState);

    if (this.command.confirm) {
      if (window.confirm(this.command.confirm)) {
        runAndReportFailure(callback, argValues, executionPathState.context, this.command);
      }
    } else {
      runAndReportFailure(callback, argValues, executionPathState.context, this.command);
    }
  };
}
