/** @jsx jsx */

import { ITheme } from '@commandbar/internal/client/theme';
import { jsx } from '@emotion/react';
import * as React from 'react';
import { IExecutionPathState } from '../../../engine/ExecutionPath';
import { Option } from '../../../engine/option';
interface IProps {
  option: Option;
  onOptionHover?: () => void;
  isFocused: boolean;
  themeContext: any;
  executionPathState: IExecutionPathState;
  onSelect: () => void;
}

const selectOptionStyles = (state: { isDisabled: boolean; isFocused: boolean }, theme: ITheme): any => {
  let borderHighlightStyles: React.CSSProperties = {
    borderLeft: '4px solid transparent',
  };

  if (state.isFocused) {
    if (theme.optionSelected.borderHighlight?.includes('gradient')) {
      borderHighlightStyles = {
        borderLeft: '4px solid',
        borderImage: `${theme.optionSelected.borderHighlight} 1`,
        borderImageWidth: '0px 0px 0px 4px',
      };
    } else {
      borderHighlightStyles = {
        borderLeft: `4px solid ${theme.optionSelected.borderHighlight}`,
      };
    }
  }

  return {
    ...borderHighlightStyles,
    userSelect: 'none',
    transition: 'background-color 0s',
    borderRadius: theme.option.borderRadius,
    paddingTop: theme.option.paddingTop,
    paddingBottom: theme.option.paddingBottom,
    paddingLeft: theme.option.paddingLeft,
    paddingRight: theme.option.paddingRight,
    marginLeft: theme.option.marginLeft,
    marginRight: theme.option.marginRight,
    minHeight: theme.option.minHeight,
    color: state.isDisabled
      ? theme.optionDisabled.color
      : state.isFocused
      ? theme.optionSelected.color
      : theme.option.color,
    fontSize: state.isFocused ? theme.optionSelected.fontSize : theme.option.fontSize,
    fontFamily: state.isFocused ? theme.optionSelected.fontFamily : theme.option.fontFamily,
    borderBottom: `1px ${theme.option.borderColor} solid`,
    backgroundColor: state.isDisabled
      ? theme.option.background
      : state.isFocused
      ? theme.optionSelected.background
      : theme.option.background,
    display: 'flex',
    alignItems: 'center',
    ':active': {
      backgroundColor: state.isDisabled ? theme.optionDisabled.background : theme.optionSelected.background,
    },
  };
};

const SelectOption = (props: IProps) => {
  const { themeContext, isFocused, executionPathState } = props;

  return (
    <div
      css={selectOptionStyles({ isDisabled: props.option.optionDisabled.isDisabled, isFocused }, props.themeContext)}
      onClick={props.onSelect}
      onMouseOver={() => props.onOptionHover && props.onOptionHover()}
    >
      {props.option.renderOption(themeContext, isFocused, executionPathState)}
    </div>
  );
};

export default SelectOption;
