/**
 * Use this file as the source for helper functions for the ExecutionPathReducer
 *
 * Generally, each function here should take in IExecutionPathState as a parameter
 *
 * Example:
 *   ExecutionPath.currentStep() calculates the active step in the execution path state
 */

import { Step, SelectStep, MultiSelectStep, TextInputStep, ChooseCommandOrResourceStep } from './step';
import { Option, ResourceOption, UnfurledCommandOption } from './option';
import Available from './Available';

import {
  ICommandType,
  IUserContext,
  ICallbackMap,
  IOrganizationType,
  isTimeArgument,
  isFunctionArgument,
  isContextArgument,
  IResourceSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import { IBreadcrumb, BREADCRUMB_TYPE } from '../components/select/input/Block';
import { osControlKey } from '../../../internal/util/operatingSystem';
import ClientSearch from './ClientSearch';

export interface IActiveObjectConfig {
  contextKey: string;
  objectLabel: string | string[];
  categoryName: string | undefined;
}
export interface IExecutionPathState {
  /************************************/
  /**** Availability Dependencies *****/
  /************************************/

  /* Organization */
  organization?: IOrganizationType;
  logo: string;
  themeSource: string;

  /* Object search settings -- HACK to remove*/
  hackContextSettings: IResourceSettingsByContextKey;

  /* all commands */
  allCommands: ICommandType[];

  /* complete context (specified by client through window) */
  context: IUserContext;

  /* all callbacks */
  callbacks: ICallbackMap;

  /* an admin can preview and edit commands */
  testMode: boolean;

  /* the user is an admin */
  isAdmin: boolean;

  /*******************************/
  /**** Command Bar Snapshot *****/
  /*******************************/

  /* have the commands loaded? */
  commandsLoaded: boolean;
  /* command bar visibility */
  visible: boolean;

  /* the available options */
  options: Option[];

  /* the full command execution path */
  steps: Step[];

  /* flag if this is a simulated execution path */
  simulation: boolean;
  baseTheme: undefined | string;

  activeObjects: Record<string, IActiveObjectConfig>;
}

export type ExecutionPathActionType =
  | 'fulfill'
  | 'rollback'
  | 'update'
  | 'rebase'
  | 'reset'
  | 'updateFulfillAndRebase'
  | 'fulfillAndRebase'
  | 'setVisible'
  | 'setIsAdmin'
  | 'setCommands'
  | 'addCommand'
  | 'removeCommand'
  | 'setOrganization'
  | 'setLogo'
  | 'setHackContextSettings'
  | 'setContext'
  | 'addContext'
  | 'removeContext'
  | 'addCallbacks'
  | 'removeCallback'
  | 'setTestMode'
  | 'setBaseTheme'
  | 'setActiveObject'
  | 'clearActiveObject'
  | 'UTIL_setContextState';

export interface IExecutionPathDispatchAction {
  [key: string]: any;
  type: ExecutionPathActionType;
}

////////////////////////////////////////////////////////////////////////
////////// Transition Functions
////////////////////////////////////////////////////////////////////////

export const fulfill = (state: IExecutionPathState): IExecutionPathState => {
  let pointer: 'start' | 'scan' | 'finish' = 'start';

  const updatedSteps: Step[] = [];
  state.steps.forEach((step: Step) => {
    /* Don't fulfill already completed steps */
    if (step.completed) {
      updatedSteps.push(step);
    } else if (pointer === 'finish') {
      /* Leave the remaining steps unfulfilled  */
      updatedSteps.push(step);
    } else if (pointer === 'scan') {
      if (step.type === 'execute') {
        /* Fulfill all sequential 'execute' steps */
        updatedSteps.push(step.fulfill(state));
      } else {
        // We can pause in the Execution Path now
        pointer = 'finish';
        updatedSteps.push(step);
      }
    } else {
      /* Fulfill and start scan */
      pointer = 'scan';
      updatedSteps.push(step.fulfill(state));
    }
  });

  // console.log('FULFILL', JSON.parse(JSON.stringify(updatedSteps)));

  return {
    ...state,
    steps: updatedSteps,
  };
};

export const rebase = (state: IExecutionPathState): IExecutionPathState => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(state);

  if (currentStep === undefined) {
    const updatedState = {
      ...state,
      visible: false,
      steps: [new ChooseCommandOrResourceStep(null)],
    };

    return {
      ...updatedState,
      options: Available.available(updatedState),
    };
  } else {
    const updatedState = { ...state };
    return {
      ...state,
      visible: true, // If we're in the middle of an EP, make sure the bar is open (like if the command is called from a shortcut)
      options: Available.available(updatedState),
    };
  }
};

export const rollback = (state: IExecutionPathState): IExecutionPathState => {
  const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(state);

  if (currentStep === undefined) {
    return state;
  }

  // Get previous step
  let steps = state.steps.map((step: Step, index: number) => {
    // Undo current step
    if (index === currentStepIndex) {
      return step.undo();
    }
    // Set previous step to not complete
    if (index === currentStepIndex - 1) {
      return step.setCompleted(false).undo();
    }

    return step;
  });

  // If the previous step was a ChooseCommandOrResourceStep, then reset to empty state
  // With Object search we can have multiple ChooseCommandOrResourceSteps in a row, causing strange behavior unless we reset
  if (steps[currentStepIndex - 1] instanceof ChooseCommandOrResourceStep) {
    steps = [new ChooseCommandOrResourceStep(null)];
  }

  return {
    ...state,
    steps,
  };
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

const currentStepAndIndex = (
  state: IExecutionPathState,
): { currentStep: Step | undefined; currentStepIndex: number } => {
  if (state.steps.length === 0) {
    return { currentStep: undefined, currentStepIndex: -1 };
  }

  const currentStepIndex = state.steps.findIndex((step: Step) => {
    return !step.completed;
  });

  return { currentStep: state.steps[currentStepIndex], currentStepIndex };
};

const lastStep = (state: IExecutionPathState) => {
  return state.steps[state.steps.length - 1];
};

const breadcrumbs = (state: IExecutionPathState): IBreadcrumb[] => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(state);

  // @ts-expect-error: This error is wrong, we do filter out undefined
  const ret: IBreadcrumb[] = state.steps
    .map((step: Step, index: number) => {
      const text = step.breadcrumb(true);
      if (text === undefined) return undefined;
      let type = BREADCRUMB_TYPE.SELECTED;
      if (index === currentStepIndex - 1 || currentStep instanceof ChooseCommandOrResourceStep) {
        type = BREADCRUMB_TYPE.LAST_SELECTED;
      } else if (index === currentStepIndex) {
        type = BREADCRUMB_TYPE.CURRENT_ARGUMENT;
      } else if (index > currentStepIndex) {
        type = BREADCRUMB_TYPE.PLACEHOLDER;
      }

      return { text, type };
    })
    .filter((breadcrumb) => !!breadcrumb);

  return ret;
};

const selections = (state: IExecutionPathState): { [argName: string]: any[] } => {
  let obj = {};
  state.steps.forEach((step: Step) => {
    if (step instanceof SelectStep || step instanceof MultiSelectStep || step instanceof TextInputStep) {
      // @FIXME This doesn't account for multiple steps referencing the same argument name

      obj = {
        ...obj,
        ...step.selection(),
      };
    }
  });

  return obj;
};

// Returns the number of unchosen user input steps
const unchosen = (state: IExecutionPathState): number => {
  const { currentStepIndex } = currentStepAndIndex(state);

  let count = 0;
  state.steps.forEach((step: Step, index: number) => {
    if (currentStepIndex < index) {
      if (step instanceof SelectStep || step instanceof MultiSelectStep || step instanceof TextInputStep) {
        if (step.selected !== null) {
          count = count + 1;
        }
      }
    }
  });

  return count;
};

const inputInstructions = (state: IExecutionPathState): string | undefined => {
  const { currentStep } = currentStepAndIndex(state);

  if (currentStep === undefined) {
    return undefined;
  }

  if (currentStep instanceof ChooseCommandOrResourceStep) {
    if (currentStep.resource === null) {
      return undefined;
    } else {
      return 'Choose an action.';
    }
  }

  if (currentStep instanceof SelectStep) {
    return undefined;
  }

  if (currentStep instanceof MultiSelectStep) {
    return `Enter to select. ${osControlKey()}+Enter to continue.`;
  }

  if (currentStep instanceof TextInputStep) {
    return 'Type text and press enter.';
  }

  return undefined;
};

const activeCommand = (state: IExecutionPathState): ICommandType | undefined => {
  if (state.steps.length === 0) return undefined;
  const firstStep = state.steps[0];
  if (firstStep instanceof ChooseCommandOrResourceStep) return firstStep?.selected?.data;
  return undefined;
};

const activeResource = (state: IExecutionPathState): string | undefined => {
  if (state.steps.length === 0) return undefined;
  const firstStep = state.steps[0];
  if (firstStep instanceof ChooseCommandOrResourceStep) return firstStep?.resource?.category?.contextKey;
  return undefined;
};

export const isSelectStep = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return currentStep instanceof SelectStep || currentStep instanceof MultiSelectStep;
};

export const isTimeStep = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return isSelectStep(currentStep) && isTimeArgument(currentStep.argument);
};

export const isFunctionStep = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return isSelectStep(currentStep) && isFunctionArgument(currentStep.argument);
};

export const isContextFunctionStep = (
  currentStep: Step | undefined,
  executionPathState: IExecutionPathState,
): currentStep is SelectStep | MultiSelectStep => {
  if (isSelectStep(currentStep) && isContextArgument(currentStep.argument)) {
    return !!Object.keys(executionPathState.callbacks).find(
      (callbackKey: string) => callbackKey === `commandbar-initialvalue-${currentStep.argument.value}`,
    );
  }

  return false;
};

/***************************************** Client Search checks *************************************/
export const isArgumentClientSearchActive = (executionPathState: IExecutionPathState) => {
  return !!getActiveArgumentClientSearchKey(executionPathState);
};

export const getActiveArgumentClientSearchKey = (executionPathState: IExecutionPathState) => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
  const activeContextKey =
    isSelectStep(currentStep) && isContextArgument(currentStep.argument) ? currentStep.argument.value : undefined;

  if (activeContextKey && ClientSearch.isDefined(activeContextKey, executionPathState)) {
    return activeContextKey;
  }
  return undefined;
};

export const isCommandOfActiveObject = (
  item: Option,
  activeObjects: Record<string, IActiveObjectConfig>,
): IActiveObjectConfig | null => {
  for (const contextKey of Object.keys(activeObjects)) {
    const activeObject = activeObjects[contextKey];

    if (!activeObject?.objectLabel) {
      continue;
    }

    const containsObject = (object: any) => {
      if (Array.isArray(activeObject?.objectLabel)) return activeObject.objectLabel.includes(object.label);
      else return activeObject.objectLabel === object.label;
    };

    if (item instanceof UnfurledCommandOption) {
      if (item.resource.category.contextKey === activeObject?.contextKey && containsObject(item.resource)) {
        return activeObject;
      }
    } else if (item instanceof ResourceOption) {
      if (item.category.contextKey === activeObject?.contextKey && containsObject(item)) return activeObject;
    }
  }
  return null;
};

const ExecutionPath = {
  currentStepAndIndex,
  lastStep,
  breadcrumbs,
  selections,
  unchosen,
  inputInstructions,
  activeCommand,
  activeResource,
};

export default ExecutionPath;
