/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';
/* React imports */
import { Color } from 'chroma-js';
import { CSSProperties } from 'react';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  id?: string;
  color: Color;
  backgroundColor?: string;
  borderColor?: string;
  fontSize?: string;
  borderRadius?: string;
  children: any;
  onClick?: () => void;
  style?: CSSProperties;
}

const Tag = (props: IProps) => {
  const backgroundColor = props.backgroundColor ? props.backgroundColor : props.color.alpha(0.2).css();
  const borderColor = props.borderColor ? props.borderColor : 'transparent';

  return (
    <span
      id={props.id}
      style={{
        display: 'inline-flex',
        backgroundColor,
        borderRadius: props.borderRadius ?? '2px',
        border: '1px solid transparent',
        borderColor,
        fontSize: props.fontSize ? props.fontSize : '12px',
        padding: '4px 7px',
        transition: 'all 0.3s',
        color: props.color.css(),
        minWidth: 24,
        height: 24,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        ...(props.style || {}),
      }}
      onClick={props.onClick || undefined}
    >
      {props.children}
    </span>
  );
};

export default Tag;
