import { adminOnlyCommands } from './AdminCommands';

import { IExecutionPathState, ExecutionPathActionType, rebase, fulfill, rollback } from './ExecutionPath';
import Available from './Available';
import { ChooseCommandOrResourceStep } from './step';
import { ICommandType } from '@commandbar/internal/middleware/types';
import AnalyticsAPI from '../analytics/Analytics';

import memoizeOne from 'memoize-one';
import isEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

export const initialExecutionPathState: IExecutionPathState = {
  organization: undefined,
  logo: '',
  themeSource: '',
  hackContextSettings: {},
  allCommands: [],
  context: {},
  callbacks: {},
  testMode: false,
  isAdmin: false,

  commandsLoaded: false,
  visible: false,
  options: [],

  // Steps should always have at least one actionable step; this is the default
  steps: [new ChooseCommandOrResourceStep(null)],

  simulation: false,
  baseTheme: undefined,
  activeObjects: {},
};

const memoizedAvailable = memoizeOne(Available.available, (newArgs: any[], lastArgs: any[]) => {
  if (newArgs.length !== 1 || lastArgs.length !== 1) {
    return false;
  }

  const { visible: _nv, simulation: _ns, options: _no, ...newState } = newArgs[0];
  const { visible: _lv, simulation: _ls, options: _lo, ...lastState } = lastArgs[0];

  return isEqual(newState, lastState);
});

export const ExecutionPathReducer = (
  state: IExecutionPathState,
  action: { type: ExecutionPathActionType; [key: string]: any },
): IExecutionPathState => {
  let updatedState: IExecutionPathState = state;

  switch (action.type) {
    case 'rollback':
      return rebase(rollback(state));
    case 'update':
      return action.updatedState;
    case 'fulfill':
      return fulfill(state);
    case 'rebase':
      return rebase(state);
    case 'reset':
      updatedState = { ...state, steps: [new ChooseCommandOrResourceStep(null)] };
      return {
        ...updatedState,
        options: memoizedAvailable(updatedState),
      };
    case 'fulfillAndRebase':
      return rebase(fulfill(state));
    case 'updateFulfillAndRebase':
      /**
       * Note: We need bundle these dispatches because
       * React reducers don't support "side-effects" or
       * async Promise returns
       */

      return rebase(fulfill({ ...state, steps: action.updatedState.steps }));
    case 'setVisible':
      if (action.visible) {
        return {
          ...state,
          visible: action.visible,
          options: memoizedAvailable(state),
        };
      } else {
        return {
          ...state,
          visible: action.visible,
          steps: [new ChooseCommandOrResourceStep(null)],
        };
      }
    case 'setIsAdmin':
      if (action.isAdmin) AnalyticsAPI.isAdmin();
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    case 'addCommand':
      updatedState = {
        ...state,
        allCommands: [...state.allCommands, action.command],
      };

      return {
        ...updatedState,
      };
    case 'removeCommand':
      updatedState = {
        ...state,
        allCommands: state.allCommands.filter((c) => c.name !== action.commandName),
      };

      return {
        ...updatedState,
      };
    case 'setCommands':
      // Programatically added commands will likely get added prior to
      // commands loaded from commandbar.com, so we don't want to
      // clobber those commands when using setCommands
      let allCommands: ICommandType[] = [
        ...state.allCommands.filter((c) => c.source === 'programmatic'),
        ...action.commands,
      ];

      if (state.isAdmin) {
        const adminCommands = adminOnlyCommands(action.commands);
        allCommands = [...adminCommands, ...allCommands];
      }

      updatedState = {
        ...state,
        allCommands,
        commandsLoaded: true,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'setHackContextSettings':
      updatedState = {
        ...state,
        hackContextSettings: {
          ...state.hackContextSettings,
          ...action.hackContextSettings,
        },
      };
      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'setOrganization':
      // HACK
      // [This hack is to support window.CommandBar.updateContextSettings() for a customer. We will solve this long term with Client API meta fields]
      // If object search settings have been set already via the API, don't overwrite them
      // This will fail if the editor has settings set for the same key as settings set in the API
      updatedState = {
        ...state,
        organization: action.organization,
        logo: action.organization?.icon ?? '',
        themeSource: action.metadata.themeSource,
        hackContextSettings: {
          ...state.hackContextSettings,
          ...action.organization.resource_options,
        },
      };

      return {
        ...updatedState,
      };
    case 'setLogo':
      return {
        ...state,
        logo: action?.logo ?? '',
      };
    case 'setContext':
      // If the context is equal, don't make changes
      if (isEqual(state.context, action.context)) return state;

      updatedState = {
        ...state,
        context: action.context,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'addContext':
      const contextAfterAdd = { ...state.context, ...action.context };
      if (isEqual(state.context, contextAfterAdd)) return state;

      updatedState = {
        ...state,
        context: contextAfterAdd,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'removeContext':
      const contextAfterRemove = { ...state.context };
      delete contextAfterRemove[action.toRemove];
      if (isEqual(state.context, contextAfterRemove)) return state;

      updatedState = {
        ...state,
        context: contextAfterRemove,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'addCallbacks':
      const callbacksAfterAdd = { ...state.callbacks, ...action.callbacks };
      if (isEqual(state.callbacks, callbacksAfterAdd)) return state;

      updatedState = {
        ...state,
        callbacks: callbacksAfterAdd,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'removeCallback':
      const callbacksAfterRemove = { ...state.callbacks };
      delete callbacksAfterRemove[action.toRemove];
      if (isEqual(state.callbacks, callbacksAfterRemove)) return state;

      updatedState = {
        ...state,
        callbacks: callbacksAfterRemove,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };
    case 'setTestMode':
      updatedState = {
        ...state,
        testMode: action.testMode,
      };

      action.testMode ? LocalStorage.set('testMode', '1') : LocalStorage.remove('testMode');

      return {
        ...updatedState,
        options: Available.available(updatedState),
      };

    case 'setBaseTheme': {
      const { payload: baseTheme } = action;

      return {
        ...state,
        baseTheme,
        themeSource: action.metadata.themeSource,
      };
    }

    case 'setActiveObject':
      const { objectLabel, contextKey, categoryName } = action;
      return {
        ...state,
        activeObjects: { ...state.activeObjects, [contextKey]: { objectLabel, contextKey, categoryName } },
      };

    case 'clearActiveObject':
      const activeObjects = { ...state.activeObjects };
      delete activeObjects[action.contextKey];
      return {
        ...state,
        activeObjects,
      };

    case 'UTIL_setContextState':
      const _contextAfterAdd = !!action.context ? { ...state.context, ...action.context } : state.context;
      const _callbacksAfterAdd = !!action.callbacks ? { ...state.callbacks, ...action.callbacks } : state.callbacks;
      const _hackContextSettings = !!action.hackContextSettings
        ? // Do a deeper merge to combine settings of different keys
          // Preference to the new settings (action.hackContextSettings)
          merge(state.hackContextSettings, action.hackContextSettings)
        : state.hackContextSettings;
      if (
        isEqual(state.context, _contextAfterAdd) &&
        isEqual(state.callbacks, _callbacksAfterAdd) &&
        isEqual(state.hackContextSettings, _hackContextSettings)
      )
        return state;

      updatedState = {
        ...state,
        context: _contextAfterAdd,
        callbacks: _callbacksAfterAdd,
        hackContextSettings: _hackContextSettings,
      };

      return {
        ...updatedState,
        options: state.visible ? memoizedAvailable(updatedState) : state.options,
      };

    default:
      throw new Error('WRONG');
  }
};

export default ExecutionPathReducer;
