/** @jsx jsx */
import * as React from 'react';
import { useTheme } from 'emotion-theming';
import { jsx } from '@emotion/core';

import SVG from '@commandbar/internal/util/SVG';

import Z from '@commandbar/internal/client/Z';
import ExecutionPath, { IExecutionPathState, IExecutionPathDispatchAction } from '../../../engine/ExecutionPath';
import { Dispatch } from 'react';
import StatusBar from './StatusBar';
import SearchFilter from './SearchFilter';
import { ISearchFilter } from '../../../engine/useCommandBar';
import { inputStyles } from './Input';
import LoadingIndicator from './LoadingIndicator';
import { getPlaceholder } from './Placeholder';
import { ITheme } from '@commandbar/internal/client/theme';

interface IProps {
  executionPathState: IExecutionPathState;
  executionPathDispatch: Dispatch<IExecutionPathDispatchAction>;
  activeSearchFilter: ISearchFilter | undefined;
  clearSearchFilter: () => void;
  inputText: string;
  onInputChange: (text: string) => void;
  isLoading: boolean;
  inputRef: any;
}

const controlStyles = (theme: ITheme): React.CSSProperties => ({
  borderBottom: theme.input.borderBottom,
  background: theme.input.background,
  // borderRadius: `${theme.main.borderRadius} ${theme.main.borderRadius} 0px 0px`,
  // fontSize: theme.input.fontSize,
  // color: theme.input.color,
  // background: theme.main.background,
  zIndex: Z.Z_COMMANDBAR,
});

const Control = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const placeholder = getPlaceholder(props.isLoading, props.executionPathState);

  return (
    <div style={controlStyles(theme)}>
      <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
        <StatusBar
          content={ExecutionPath.breadcrumbs(props.executionPathState)}
          instruction={ExecutionPath.inputInstructions(props.executionPathState)}
          onRollBack={() => {
            props.executionPathDispatch({ type: 'rollback' });
            props.onInputChange('');
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.input.paddingLeft,
            paddingRight: theme.input.paddingRight,
            paddingTop: theme.input.paddingTop,
            paddingBottom: theme.input.paddingBottom,
            cursor: 'text',
          }}
        >
          <Logo svgString={props.executionPathState.logo} />
          <SearchFilter filter={props.activeSearchFilter} onClose={props.clearSearchFilter} theme={theme} />
          <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} className="commandbar__input">
            <input
              css={inputStyles(theme, props.inputText)}
              value={props.inputText}
              onChange={(e: any) => props.onInputChange(e.target.value)}
              placeholder={placeholder}
              ref={props.inputRef}
            />
            <LoadingIndicator isLoading={props.isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = (props: { svgString: string }) => {
  if (props.svgString.length > 0) {
    return (
      <SVG
        style={{ height: 20, width: 20, left: 17, top: 25, marginRight: 12 }}
        htmlstring={props.svgString}
        useDefaultSVGColor={true}
      />
    );
  } else {
    return null;
  }
};

export default Control;
