import { ITheme } from '@commandbar/internal/client/theme';
import ExecutionPath, { IExecutionPathState } from '../../../engine/ExecutionPath';
import { DEFAULT_PLACEHOLDER } from '../../../engine/step/Step';

export const placeholderStyles = (theme: ITheme) => ({
  color: theme.placeholder.color,
  fontFamily: theme.placeholder.fontFamily,
  fontSize: theme.placeholder.fontSize,
});

export const getPlaceholder = (loading: boolean, executionPathState: IExecutionPathState) => {
  if (loading) {
    return 'Loading...';
  }

  const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
  return !!currentStep ? currentStep.placeholder() : DEFAULT_PLACEHOLDER;
};
