import { ICommandType } from '@commandbar/internal/middleware/types';

import AnalyticsAPI from './Analytics';
import ExecutionPath, { IExecutionPathState } from '../engine/ExecutionPath';
import { IOnboardingState, getUniqueID } from '../engine/onboarding/OnboardingReducer';
import { _search, _configuration } from '@commandbar/internal/client/symbols';
import { getSDK } from '@commandbar/internal/client/globals';
import { CommandOption, ParameterOption } from '../engine/option';
import Search from '../engine/Search';

/*********************************************************************** */
/*                   Log events - logged in Data warehouse               */
/*********************************************************************** */

const startSession = (sessionID: string) => {
  AnalyticsAPI.log('New session', {
    session: sessionID,
  });
};

const endSession = (onboardingState: IOnboardingState) => {
  if (onboardingState.isActive && onboardingState.stepStartTime) {
    // If opened, and started
    onboardingExited(onboardingState);
  }

  const currentTime = Date.now();
  const session = getSDK()[_configuration].session;
  if (typeof session === 'string') {
    // Report the session length (in seconds)
    try {
      const sessionLength = (currentTime - parseInt(session.substring(0, currentTime.toString().length))) / 1000;
      AnalyticsAPI.log(
        'End session',
        {
          length: sessionLength,
        },
        true,
      );
    } catch (e) {}
  }
};

export enum SEARCH_TRIGGER {
  PROGRAMMATIC = 'programmatic', // window.CommandBar.open()
  ONBOARDING = 'onboarding', // onboarding completed
  KEYBOARD = 'keyboard', // cmd-k
  LAUNCHER = 'launcher', // click launcher
  URL = 'url', // commandbar in url
}

const startSearch = (onboardingState: IOnboardingState, trigger: SEARCH_TRIGGER) => {
  if (!onboardingState.isActive) {
    const searchID = `${getSDK()[_configuration].session}-${Date.now().toString()}`;
    getSDK()[_search] = searchID;
    AnalyticsAPI.log('New search', { trigger });
  }
};

const endSearch = (inputText: string, state: IExecutionPathState, onboardingState: IOnboardingState) => {
  if (!onboardingState.isActive) {
    AnalyticsAPI.log('Exited', {
      inputText: inputText,
    });

    const activeCommand = ExecutionPath.activeCommand(state);
    const activeResource = ExecutionPath.activeResource(state);

    // If the search is ended with a non empty state, treat it as a deadend
    if (inputText !== '' || activeCommand || activeResource) {
      Reporting.deadend(inputText, 'Closed with text', state);
    }
  }
};

const searchInputChange = (inputText: string, state: IExecutionPathState) => {
  const activeCommand = ExecutionPath.activeCommand(state);
  const activeResource = ExecutionPath.activeResource(state);

  AnalyticsAPI.log('Search input', {
    'inputText[*]': inputText,
    command: activeCommand && activeCommand.id,
    resource: activeResource,
  });
};

const onboardingTooltip = (message: string) => {
  AnalyticsAPI.log('Onboarding tooltip shown', { message: message });
};

const customTooltip = (message: string) => {
  AnalyticsAPI.log('Tooltip shown', { message: message });
};

const onboardingStarted = (state: IOnboardingState, trigger?: string) => {
  if (!state.shouldTrack) return;
  AnalyticsAPI.log('Onboarding started', { trigger, onboardingID: getUniqueID(state) });
};

const onboardingExited = (state: IOnboardingState) => {
  if (!state.shouldTrack) return;
  AnalyticsAPI.log('Onboarding exited', {
    step: state.currentStepIndex,
    command: state.activeCommand?.id,
    time: JSON.stringify(state.timeSpent),
    onboardingID: getUniqueID(state),
  });
};

const onboardingCompleted = (state: IOnboardingState) => {
  if (!state.shouldTrack) return;
  AnalyticsAPI.log('Onboarding completed', {
    step: state.currentStepIndex,
    command: state.activeCommand?.id,
    time: JSON.stringify(state.timeSpent),
    onboardingID: getUniqueID(state),
  });
};

/*********************************************************************** */
/*           Track events - logged in Prod DB and data warehouse         */
/*********************************************************************** */

const deadend = (inputText: string, type: string, state: IExecutionPathState) => {
  const activeCommand = ExecutionPath.activeCommand(state);
  const activeResource = ExecutionPath.activeResource(state);

  const visibleOptions = Search.search(inputText, state);
  const results = {
    numCommands: visibleOptions.filter((o) => o instanceof CommandOption).length,
    numRecords: visibleOptions.filter((o) => o instanceof ParameterOption).length,
  };

  AnalyticsAPI.track('Abandoned search', {
    'inputText[*]': inputText,
    command: activeCommand && activeCommand.id,
    type,
    activeResource,
    results,
  });
};

const suggestion = (text: string) => {
  AnalyticsAPI.track('Command suggestion', {
    text,
  });
};

const execution = (command: ICommandType, inputText: string, meta?: { [key: string]: any }) => {
  AnalyticsAPI.track('Command execution', {
    command: command.id || command.name, // command.name used for programmatic commands
    commandText: command.text,
    category: command.category,
    source: command.source || 'standard',
    'inputText[*]': inputText,
    ...(meta || {}),
  });
};

const unavailableShortcut = (shortcut: string[], command: ICommandType) => {
  AnalyticsAPI.log('Unavailable shortcut', {
    shortcut,
    command: command.id,
    commandText: command.text,
    url: window.location.href,
  });
};

const game = (secondsPlayed?: number) => {
  AnalyticsAPI.log('Game played', { secondsPlayed });
};

const clickedBranding = () => {
  AnalyticsAPI.log('Clicked branding', {});
};

const surveyResponse = () => {
  AnalyticsAPI.log('Survey response', {}, true);
};

const Reporting = {
  session: {
    start: startSession,
    end: endSession,
  },
  search: {
    start: startSearch,
    end: endSearch,
    inputChange: searchInputChange,
  },
  tooltip: {
    onboarding: onboardingTooltip,
    custom: customTooltip,
  },
  onboarding: {
    started: onboardingStarted,
    exited: onboardingExited,
    completed: onboardingCompleted,
  },
  deadend,
  suggestion,
  execution,
  unavailableShortcut,
  game,
  clickedBranding,
  surveyResponse,
};

export default Reporting;
