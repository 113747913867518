import { Option, CommandOption } from '../option';
import { IExecutionPathState } from '../ExecutionPath';
import { ICommandType } from '@commandbar/internal/middleware/types';
import _get from 'lodash.get';
import { interpolate } from '../Interpolate';
import { checkSelector } from '../../util/dom';
import JS from '@commandbar/internal/util/js';
import ClientSearch from '../ClientSearch';
import { ErrorReporter } from '../../error/ErrorReporter';
import { ErrorCode } from '../../error/ErrorCode';

const _reportFailure = (message: string, command: ICommandType, detail?: string) => {
  ErrorReporter.get().exception(ErrorCode.FILTER, {
    errorMsg: message,
    command: command.id,
    commandText: command.text,
    detail,
  });
};

export const hasInitialValueFnDefined = (key: string, executionPathState: IExecutionPathState) =>
  Object.keys(executionPathState.callbacks).some((callbackKey: string) =>
    callbackKey.includes(`commandbar-initialvalue-${key}`),
  );

const isValidArg = (
  command: ICommandType,
  arg: string,
  executionPathState: IExecutionPathState,
): { isValid: boolean; isValidReason: string } => {
  const invalid = (msg: string) => {
    return { isValid: false, isValidReason: msg };
  };

  const valid = () => {
    return { isValid: true, isValidReason: '' };
  };
  const argumentConfig = command.arguments[arg];

  if (!argumentConfig) {
    _reportFailure('Arg parsing', command, `Config for argument ${arg} is missing.`);
    return invalid(`Config for ${arg} is missing.`);
  }

  switch (argumentConfig.type) {
    case 'function':
      if (argumentConfig.value in executionPathState.callbacks && executionPathState.callbacks[argumentConfig.value]) {
        return valid();
      } else {
        return invalid(`Function ${argumentConfig.value} is not a valid callback`);
      }
    case 'context':
      const options = _get(executionPathState.context, argumentConfig.value);

      // If object search is defined for an argument, validate the arg
      // Don't invalidate if empty. Otherwise this could invalidate commands
      // when the context keys are empty on search
      if (ClientSearch.isDefined(argumentConfig.value, executionPathState)) {
        return valid();
      }

      if (hasInitialValueFnDefined(argumentConfig.value, executionPathState)) {
        return valid();
      }

      if (options === undefined) {
        return invalid(`${argumentConfig.value} isn't defined in context.`);
      }

      if (!Array.isArray(options)) {
        return invalid(`${argumentConfig.value} isn't an array in context.`);
      }

      if (options.length === 0) {
        return invalid(`${argumentConfig.value} is an empty array in context.`);
      }

      return valid();
    case 'dependent':
      return valid();
    // FIXME: Need to rethink dependency availability logic
    //  - if a single option doesn't have a valid dependency, blocking the whole list is frustrating
    //    The default behavior of showing "No results" is the right behavior
    //  - if the dependent arg is defined by a loader, search function, function generated, or dependent
    //    then the logic below fails. Most dependent args are complicated situations and the logic below is
    //    too fragile that all too often you see an availability issue that you don't expect => very frustrating

    // Extract the dependent argument and the lookup key
    // const dependency = argumentConfig.value.split('.')[0];
    // const dependencyValue = command.arguments[dependency].value;
    // const lookupKey = argumentConfig.value.split('.').slice(1).join('.');

    // // If dependent on another dependent argument or function argument return valid
    // // FIXME: could do more deep check
    // if (command.arguments[dependency].type === 'dependent' || command.arguments[dependency].type === 'function') {
    //   return valid();
    // }

    // const dependencyOptions = _get(executionPathState.context, dependencyValue);

    // if (dependencyOptions === undefined) {
    //   return invalid(`${dependency} dependency isn't defined in context.`);
    // }

    // if (!Array.isArray(dependencyOptions)) {
    //   return invalid(`${dependency} isn't an array in context.`);
    // }

    // if (dependencyOptions.length === 0) {
    //   return invalid(`${dependency} is an empty array in context.`);
    // }

    // const validDependentOptions = dependencyOptions.filter((option: any) => {
    //   if (!option.hasOwnProperty(lookupKey)) {
    //     return false;
    //   }

    //   const lookupOptions = option[lookupKey];

    //   if (!Array.isArray(lookupOptions)) {
    //     return false;
    //   }

    //   if (lookupOptions.length === 0) {
    //     return false;
    //   }

    //   return true;
    // });

    // /*
    //   FIXME:
    //   This could be refined by updating Available.tsx to only show options that do have
    //   valid dependency fields. In other words, just drop the ones that don't comply and make
    //   sure that the result is at least of length > 0;
    //  */
    // if (validDependentOptions.length !== dependencyOptions.length) {
    //   return invalid(`Some of the context values are missing valid dependency fields (${argumentConfig.value})`);
    // }

    // return valid();
    default:
      return valid();
  }
};

const isClickableArg = (
  command: ICommandType,
  arg: string,
  executionPathState: IExecutionPathState,
): { isClickable: boolean; isClickableReason: string } => {
  const notClickable = (msg: string) => {
    return { isClickable: false, isClickableReason: msg };
  };

  const clickable = () => {
    return { isClickable: true, isClickableReason: '' };
  };

  if (['clickByXpath', 'clickBySelector', 'click'].includes(command.template.type)) {
    let values;
    try {
      // @ts-expect-error: the possible type error is caught
      values = command.template.value.map((el: any) => interpolate(el, executionPathState, true, true));
    } catch (e) {
      return notClickable(e.toString());
    }

    if (!checkSelector(values[0])) {
      return notClickable(`Cannot find element to click: [${values[0]}]`);
    }
  }
  return clickable();
};

const runBooleanConditions = (rawConditions: string[], executionPathState: IExecutionPathState, prefix: string) => {
  // filter out empty conditions
  let conditions = rawConditions.filter((condition: string) => condition.length > 0);

  const failed = (msg: string) => {
    return { passed: false, reason: msg };
  };

  const passed = () => {
    return { passed: true, reason: '' };
  };

  // Replace {{context}} syntax, and join commands with and conditions
  // Convert all quotes to double quotes.
  conditions = conditions.map((condition: string) => condition.replace(/[{}]/g, '').replace(/('|")/g, '"'));

  // We're about to evaluate this javascript so first need to check to make sure it's safe
  for (const condition of conditions) {
    if (!JS.isAllowable(condition)) {
      const isAvailableReason = `${prefix}: sanitization check failed.`;
      return failed(isAvailableReason);
    }
  }

  const booleanLogic = conditions.join(' && ');
  // Run availability function
  try {
    // eslint-disable-next-line no-new-func
    const fn = new Function('context', `if (${booleanLogic}) {return true} else {return false}`);
    const isAvailable = fn(executionPathState.context);
    // @REFACTOR is this backwards?
    if (typeof isAvailable === 'boolean') {
      if (isAvailable) {
        return passed();
      } else {
        return failed(`${prefix}: condition is false.`);
      }
    }
    return failed(`${prefix}: statement didn't produce a boolean`);
  } catch (e) {
    return failed(`${prefix}: ${e}`);
  }
};

const isAvailable = (
  option: Option,
  executionPathState: IExecutionPathState,
): { isAvailable: boolean; isAvailableReason: string } => {
  let conditions: string[] = [];
  if (option instanceof CommandOption) {
    conditions = option.command.availability;
  }

  if (conditions.length === 0) {
    // no conditions defined
    return { isAvailable: true, isAvailableReason: '' };
  }

  const { passed, reason } = runBooleanConditions(conditions, executionPathState, 'Availability condition');

  if (passed) {
    return { isAvailable: true, isAvailableReason: '' };
  } else {
    return { isAvailable: false, isAvailableReason: reason };
  }
};

/**
 * Checks whether all of a command's arguments are presently valid,
 * in order to filter out commands which can't be executed from
 * the user's viewable options.
 */
const isValid = (
  commandOption: CommandOption,
  executionPathState: IExecutionPathState,
): { isValid: boolean; isValidReason: string } => {
  const args = Object.keys(commandOption.command.arguments);

  // AND across args
  for (const arg of args) {
    const { isValid, isValidReason } = isValidArg(commandOption.command, arg, executionPathState);
    if (!isValid) {
      return { isValid, isValidReason };
    }
    const { isClickable, isClickableReason } = isClickableArg(commandOption.command, arg, executionPathState);
    if (!isClickable) {
      return { isValid: isClickable, isValidReason: isClickableReason };
    }
  }

  return { isValid: true, isValidReason: '' };
};

const OptionValidate = {
  isValid,
  isAvailable,
  runBooleanConditions,
};

export default OptionValidate;
