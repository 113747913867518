import { IExecutionPathState } from '../ExecutionPath';
import { Option } from '../option';
import { translate } from '../../util/languages';
import { organizationService } from '../../services/organizationService';
import { ICommandType } from '@commandbar/internal/middleware/types';

interface IStepSelection {
  type: 'command' | 'resource' | 'parameter';
}

export interface IStepSelectionCommand extends IStepSelection {
  category: number | null;
  label: string;
  data: ICommandType;
}

export interface IResourceCategory {
  contextKey: string;
  label: string;
}

export interface IStepSelectionParameter extends IStepSelection {
  category: null;
  data: any;
}

export const DEFAULT_PLACEHOLDER = 'What do you want to do?';

export abstract class Step {
  public completed: boolean;
  public type: string;

  constructor(type: string) {
    this.completed = false;
    this.type = type;
  }

  public setCompleted = (completed: boolean): Step => {
    this.completed = completed;
    return this;
  };

  public undo = (): Step => {
    this.selected = null;

    return this;
  };

  public placeholder = () => {
    return translate(organizationService.getOrganization()?.custom_call_to_action || DEFAULT_PLACEHOLDER);
  };

  abstract selected: IStepSelectionCommand | IStepSelectionParameter | null;
  abstract select(option: Option, selections?: any[]): Step;

  // Complete the step and execute any relevant side-effects
  abstract fulfill(executionPathState: IExecutionPathState): Step;

  // The object to return to the caller
  abstract selection(): { [key: string]: any };

  // The breadcrumb string
  abstract breadcrumb(shouldIncludeBreadcrumb: boolean): string | undefined;
}
