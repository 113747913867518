/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { AiFillCrown, AiOutlineCrown } from 'react-icons/ai';
import Tooltip from 'rc-tooltip';
import '../../../style/rc-tooltip/style.css';
import chroma from 'chroma-js';

import { useTheme } from 'emotion-theming';

import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { osControlKey, getOperatingSystem, OS } from '@commandbar/internal/util/operatingSystem';
import Tag from '../../Tag';

import { SuggestCommand } from './SuggestCommand';
import KeyboardShortcut from './../../KeyboardShortcut';
import { translate } from '../../../util/languages';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { ReactComponent as PoweredBySvg } from '../../../img/powered.svg';
import stylesModule from './styles.module.css';
import Reporting from '../../../analytics/Reporting';
import { ITheme } from '@commandbar/internal/client/theme';

interface IProps {
  setDashboard: (dashboard: undefined | React.ReactNode) => void;
  testMode: boolean;
  isAdmin: boolean;
  setTestMode: (on: boolean) => void;
  organization: IOrganizationType | undefined;
  currentStepIndex: number;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const Footer = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const showBranding = props.organization?.branding === 'branded';

  // Bars shared with users but not installed by apps
  const b2c_bars = ['294a5b63'];

  // Cycle, Lyft
  const showBrandingOnly =
    props.organization && ['ac5f6bc2', '2ab00bc5', ...b2c_bars].includes(props.organization?.id.toString());

  const styles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.footer.paddingTop,
    paddingBottom: theme.footer.paddingBottom,
    paddingLeft: theme.footer.paddingLeft,
    paddingRight: theme.footer.paddingRight,
    borderTop: theme.footer.borderTop,
    backgroundColor: theme.footer.background,
    borderRadius: `0px 0px ${theme.main.borderRadius} ${theme.main.borderRadius}`,
  };

  const os = React.useMemo(getOperatingSystem, []);
  let keys: string[] = [];
  if ([OS.MAC].includes(os)) {
    keys = ['command', 'k'];
  } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
    keys = ['ctrl', 'k'];
  }

  const customContext = props.isAdmin ? LocalStorage.get('customcontext', '').toString() : '';

  const customContextAlert = !!customContext ? (
    <Tag color={chroma('#177DDC')}>[{JSON.parse(customContext).name}]</Tag>
  ) : null;

  // HARDCODE: OneSignal (31faae81)
  const customBetaTag =
    props.organization?.id === '31faae81' ? (
      <Tag color={chroma('#5289b7')} style={{ marginLeft: 12 }}>
        Beta
      </Tag>
    ) : null;

  if (!props.organization) {
    return null;
  }

  const spacer = <div style={{ width: 4 }} />;
  const customNavFooter =
    props.organization?.id === '10d7dc04' && props.currentStepIndex === 0 ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <KeyboardShortcut keys={['↑']} />
        {spacer}
        <KeyboardShortcut keys={['↓']} />
        {spacer}
        <div>to navigate.</div>
        {spacer}
        {spacer}
        <KeyboardShortcut keys={['Return']} />
        {spacer}
        <span>to select.</span>
      </div>
    ) : null;

  const switchToLegacyClickUpSearch =
    props.organization?.id === '10d7dc04' && window.CommandBar.shareCallbacks()?.['global-switchToLegacy'];
  const customSwitchToLegacyClickUpSearchButton =
    props.organization?.id === '10d7dc04' && switchToLegacyClickUpSearch ? (
      <>
        <div onClick={() => switchToLegacyClickUpSearch({}, {})} style={{ cursor: 'pointer' }}>
          Legacy Search
        </div>
        {spacer}
        {spacer}
        {spacer}
        <div style={{ height: 16, width: 1, background: theme.keyboardShortcuts.borderColor }} />
      </>
    ) : null;

  const branding = showBranding ? (
    <div
      className={stylesModule.poweredContainer}
      onClick={() => {
        Reporting.clickedBranding();
        if (b2c_bars.includes(props.organization?.id as string)) {
          window.open(`https://commandbar.com/`, '_blank');
        } else {
          window.open(
            `https://commandbar.com/powered-by-commandbar?org=${props.organization?.name || encodeURI('This app')}`,
            '_blank',
          );
        }
      }}
    >
      <PoweredBySvg style={{ marginRight: '5px' }} />
      <span style={{ fontSize: '12px', color: theme.main.color }}>{translate('Powered by CommandBar')}</span>
    </div>
  ) : null;

  if (showBrandingOnly) {
    return (
      <div style={{ position: 'relative' }}>
        <div style={styles}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>{branding}</div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {customNavFooter || branding}
          <div style={{ flexGrow: 1 }} />
          {customSwitchToLegacyClickUpSearchButton}
          {customContextAlert}
          <span style={{ width: 10 }} />
          {props.organization?.in_bar_feedback === false ? null : (
            <SuggestCommand organization={props.organization} setDashboard={props.setDashboard} isIcon={true} />
          )}
          {props.isAdmin && (
            <Tooltip
              prefixCls="commandbar-tooltip"
              overlay={
                <span style={{ fontFamily: theme.main.fontFamily }}>
                  {translate(`${props.testMode ? 'Exit' : 'Enter'} test mode`)}
                </span>
              }
              placement="bottom"
            >
              {props.testMode ? (
                <AiFillCrown
                  size="22px"
                  onClick={() => props.setTestMode(!props.testMode)}
                  style={{ color: theme.base.primary, cursor: 'pointer', marginLeft: 10 }}
                />
              ) : (
                <AiOutlineCrown
                  size="22px"
                  onClick={() => props.setTestMode(!props.testMode)}
                  style={{ color: theme.main.color, cursor: 'pointer', marginLeft: 10 }}
                />
              )}
            </Tooltip>
          )}
          {customBetaTag}
          <Tooltip
            prefixCls="commandbar-tooltip"
            overlay={
              <span style={{ fontFamily: theme.main.fontFamily }}>
                {translate(`Type ${osControlKey()}+K to open and close`)}
              </span>
            }
            placement="bottom"
          >
            <div
              style={{
                cursor: 'default',
                marginLeft: 13,
              }}
            >
              <KeyboardShortcut keys={keys} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Footer;
