import React, { ReactNode, ReactElement, useState, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import { AlignType } from 'rc-trigger/lib/interface';

interface IProps {
  overlay: ReactNode;
  children: ReactElement;
  visible: boolean;
  delay?: number;
  placement?: string;
  align?: AlignType;
}

export const TooltipWrapper = ({ visible, overlay, children, delay = 500, placement, align }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (visible) {
      timeout = setTimeout(() => {
        setIsVisible(true);
      }, delay);
    } else {
      clearTimeout(timeout);
      setIsVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return (
    <Tooltip
      prefixCls="commandbar-tooltip"
      overlay={overlay}
      placement={placement}
      trigger={'hover'}
      visible={isVisible}
      align={align}
    >
      {children}
    </Tooltip>
  );
};
