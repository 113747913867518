import { ITheme } from '@commandbar/internal/client/theme';
import React from 'react';
import { AiOutlineClose, AiOutlineFilter } from 'react-icons/ai';
import { ISearchFilter } from '../../../engine/useCommandBar';

interface IProps {
  onClose: () => void;
  filter: ISearchFilter | undefined;
  theme: ITheme;
}
const SearchFilter = (props: IProps) => {
  if (!props.filter) return null;

  return (
    <div
      className="commandbar-search-filter-breadcrumb"
      style={{
        border: `1px solid ${props.theme.base.primary}`,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
      }}
    >
      <AiOutlineFilter style={{ marginRight: 3 }} />
      {props.filter.name}
      <AiOutlineClose style={{ cursor: 'pointer', fontSize: 11, marginLeft: 4 }} onClick={props.onClose} />
    </div>
  );
};

export default SearchFilter;
