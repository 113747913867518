import { ICommandType } from '@commandbar/internal/middleware/types';
import { getElement } from './dom';

import Mousetrap from 'mousetrap';

import { ICommandBarState, ICommandBarDispatch } from '../engine/useCommandBar';

import { OS, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import { formatMousetrapString } from '@commandbar/internal/client/keyboardShortcuts';
import Reporting from '../analytics/Reporting';

import { executeCommand } from '../engine/stateUtils';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';

/*************************************************************************************************************
 **** Add shorcuts and shorcut tooltips
 *************************************************************************************************************/

const setUpShortcuts = (state: ICommandBarState, dispatch: ICommandBarDispatch) => {
  const availableCommands = state.executionPathState.allCommands;

  Mousetrap.reset(); // Clear all bound hotkeys

  const os = getOperatingSystem();

  if ([OS.MAC, OS.WINDOWS, OS.LINUX].includes(os)) {
    availableCommands.map((cmd: ICommandType) => {
      const hotkeys = os === OS.MAC ? cmd.shortcut_mac : cmd.shortcut_win;
      if (hotkeys.length === 0) {
        return null;
      }
      const hotkeyString = formatMousetrapString(hotkeys);

      // @FIXME
      // This will always add the tooltips on elements that are visible and associated with a command
      // Theoretically, we should check custom availability of the command too before adding the tooltip
      if (_canAddHoverTooltip(cmd)) {
        _addHoverTooltip(cmd);
      }

      Mousetrap.bind(hotkeyString, () => {
        const isDisabled = executeCommand(
          cmd,
          state,
          dispatch,
          () => {
            dispatchCustomEvent('commandbar-shortcut-executed', { detail: { keys: hotkeyString } });
          },
          () => Reporting.unavailableShortcut(hotkeys, cmd),
        );

        // https://craig.is/killing/mice#api.bind.default
        // Returning false prevents the event from bubbling up, i.e., doesn't fill in the input
        return isDisabled;
      });
      return null;
    });
  }
};

// REFACTOR None of this included yet...
/*************************************************************************************************************
 **** Utils to add hover tooltips
 *************************************************************************************************************/
// Check if a command can add a hover tooltip
const _canAddHoverTooltip = (command: ICommandType) => {
  // Only allow hovers for click templates if turned on, and not a multi-click
  // Multi-click hovers might get ugly, because you can have multiple hovers showing up for the same element
  const isHoverType =
    ['click', 'clickBySelector', 'clickByXpath'].includes(command.template.type) &&
    command.template.hoverTooltip &&
    command.template.value.length === 1;

  const os = getOperatingSystem();
  let hasShortcut = false;
  if ([OS.MAC].includes(os)) {
    hasShortcut = command.shortcut_mac.length > 0;
  } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
    hasShortcut = command.shortcut_win.length > 0;
  }

  return isHoverType && hasShortcut;
};

// We need separate classnames for entry and exit tooltips in order to show an entry and exit animation
const _TOOLTIP_CLASSNAME = 'commandbar-shortcut-tooltip';
const _TOOLTIP_EXIT_CLASSNAME = 'commandbar-shortcut-tooltip-remove';
const _SHORTCUT_TAG_CLASSNAME = 'commandbar-shortcut-tooltip-tag';

// Show nice animation to fade out (by changing class), then remove element
const _addHoverTooltip = (command: ICommandType) => {
  const elem = getElement(command.template.value[0]);

  if (elem) {
    const os = getOperatingSystem();
    let hotkeyString = '';
    if ([OS.MAC].includes(os)) {
      hotkeyString = formatMousetrapString(command.shortcut_mac);
    } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
      hotkeyString = formatMousetrapString(command.shortcut_win);
    }

    elem.addEventListener('mouseout', _onMouseOutTooltipListener);
    elem.addEventListener(
      'mouseenter',
      function () {
        // Clean up any existing tooltips, if any
        const elements = document.getElementsByClassName(_TOOLTIP_CLASSNAME);
        while (elements.length > 0) {
          elements[0]?.parentNode?.removeChild(elements[0]);
        }

        const hover = document.createElement('div');
        const [topPosition, leftPosition] = _getTooltipPlacement(elem, command);
        hover.style.top = topPosition;
        hover.style.left = leftPosition;

        hover.innerHTML = `<div>${command.text}<span class=${_SHORTCUT_TAG_CLASSNAME}>${hotkeyString}</span></div>`;
        hover.className = 'commandbar-shortcut-tooltip';
        document.body.appendChild(hover);

        setTimeout(() => {
          _gracefullyRemoveTooltip(hover);
        }, 5000);
      },
      false,
    );
  }
};

// Show nice animation to fade out (by changing class), then remove element
const _gracefullyRemoveTooltip = (elem: any) => {
  elem.className = _TOOLTIP_EXIT_CLASSNAME;
  setTimeout(() => {
    elem.remove();
  }, 300);
};

// MouseOut event listener
// remove all tooltips so we don't have multiple tooltips showing on rapid mouse out and mouse in
const _onMouseOutTooltipListener = () => {
  const elements = document.getElementsByClassName(_TOOLTIP_CLASSNAME);
  while (elements.length > 0) {
    const elemToRemove = elements[0];
    _gracefullyRemoveTooltip(elemToRemove);
  }
};

// Rudimentary dynamic positioning
// Maybe we want to make this a setting going forward
const _getTooltipPlacement = (elem: any, command: ICommandType) => {
  const elemTop = window.scrollY + elem.getBoundingClientRect().top;
  const elemBottom = window.scrollY + elem.getBoundingClientRect().bottom;
  const elemLeft = window.scrollX + elem.getBoundingClientRect().left;
  const elemRight = window.scrollX + elem.getBoundingClientRect().right;
  const elemWidth = (elem as any).offsetWidth;
  const elemHeight = (elem as any).offsetHeight;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // Rudimentary estimate of tooltip width
  // We can only measure the width when the element is rendered.
  // FIXME: Potential hack if this approach is noticeable is to create a second div, render it,
  // measure it's width, and then use that
  const os = getOperatingSystem();
  let hotkeyString = '';
  if ([OS.MAC].includes(os)) {
    hotkeyString = formatMousetrapString(command.shortcut_mac);
  } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
    hotkeyString = formatMousetrapString(command.shortcut_win);
  }

  const tooltipWidth = 7 * (command.text.length + hotkeyString.length) + 24;
  const tooltipHeight = 28;

  let HOVER_TYPE;
  // If element is taller than wide, put the tooltip on the right or left
  // Else put it on the top or bottom
  const cantAddToLeft = elemLeft - tooltipWidth < 0;
  const cantAddtoRight = elemRight + tooltipWidth > windowWidth;
  const cantAddToBottom = elemBottom + tooltipHeight > windowHeight;
  const cantAddToTop = elemTop - tooltipHeight < 0;
  if (elemWidth / windowWidth < elemHeight / windowHeight) {
    if ((elemLeft < windowWidth / 2 && !cantAddtoRight) || cantAddToLeft) HOVER_TYPE = 'right';
    else HOVER_TYPE = 'left';
  } else {
    if ((elemTop < windowHeight / 2 && !cantAddToBottom) || cantAddToTop) {
      HOVER_TYPE = 'bottom';
    } else {
      HOVER_TYPE = 'top';
    }
  }

  const widthOffset = elemWidth / 2 - tooltipWidth / 2;
  const heightOffset = elemHeight / 2 - tooltipHeight / 2;
  let topPosition = '0px';
  let leftPosition = '0px';
  // center hover position
  switch (HOVER_TYPE) {
    case 'right':
      topPosition = `${elemTop + heightOffset}px`;
      leftPosition = `${elemLeft + elemWidth}px`;
      break;
    case 'left':
      topPosition = `${elemTop + heightOffset}px`;
      leftPosition = `${elemLeft - tooltipWidth}px`;
      break;
    case 'top':
      topPosition = `${elemTop - tooltipHeight}px`;
      leftPosition = `${elemLeft + widthOffset}px`;
      break;
    case 'bottom':
      topPosition = `${elemTop + elemHeight}px`;
      leftPosition = `${elemLeft + widthOffset}px`;
      break;
  }

  return [topPosition, leftPosition];
};

export default setUpShortcuts;
