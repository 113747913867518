// eslint-disable-next-line unused-imports/no-unused-imports
import Logger from '@commandbar/internal/util/Logger';
import { CommandBarProxyGlobal } from '@commandbar/internal/client/CommandBarProxySDK';
import { _configuration, _orgConfig } from '@commandbar/internal/client/symbols';
import { getProxySDK } from '@commandbar/internal/client/globals';
import { ErrorReporter } from '../error/ErrorReporter';

////////////////////////////////////////////////////////////////////////////
// index.html content
// ----------------------
//
// <div
//   id="commandbar-wrapper"
//   data-commandbar-org-id="42424242"
//   data-commandbar-org-name="Gusto"
// >
//   <div id="commandbar"></div>
// </div>
// <link rel="stylesheet" type="text/css" href="%PUBLIC_URL%/commandbar.css" />
// <script src="%PUBLIC_URL%/commandbar.js"></script>
//
////////////////////////////////////////////////////////////////////////////

const genSession = () => {
  const len = 12;
  const factor = Math.pow(10, len);
  return Math.floor(factor + Math.random() * 9 * factor).toString();
};

const getConfig = () => {
  const lc = localStorage.getItem('commandbar.lc');

  switch (lc) {
    case 'prod':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-prod.commandbar.com',
        editor: 'https://frames-editor-prod.commandbar.com',
      };
    case 'labs':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-labs.commandbar.com',
        editor: 'https://frames-editor-labs.commandbar.com',
      };
    case 'branch':
      return {
        api: 'https://api-branch.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-branch.commandbar.com',
        editor: 'https://frames-editor-branch.commandbar.com',
      };
    case 'branch2':
      return {
        api: 'https://api-labs.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-branch2.commandbar.com',
        editor: 'https://frames-editor-branch2.commandbar.com',
      };
    case 'stress':
      return {
        api: 'https://api-stress.commandbar.com',
        commandbar: '__local__',
        proxy: 'https://frames-proxy-labs.commandbar.com',
        editor: 'https://frames-editor-labs.commandbar.com',
      };
    case 'local':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'http://localhost:3002',
        editor: 'http://localhost:3003',
      };
    case 'test':
    case 'local-dev':
      return {
        api: 'http://localhost:8000',
        commandbar: '__local__',
        proxy: 'http://localhost:3002',
        editor: 'http://localhost:3003',
      };
    case 'local-docker':
      return {
        api: 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: 'http://localhost:5002',
        editor: 'http://localhost:5003',
      };
    case 'local-dev-docker':
      return {
        api: 'http://localhost:8000',
        commandbar: '__local__',
        proxy: 'http://localhost:5002',
        editor: 'http://localhost:5003',
      };
    default:
      return {
        api: process.env.REACT_APP_API_URL || 'https://api.commandbar.com',
        commandbar: '__local__',
        proxy: process.env.REACT_APP_PROXY_URL || 'https://frames-proxy-prod.commandbar.com',
        editor: process.env.REACT_APP_EDITOR_URL || 'https://frames-editor-prod.commandbar.com',
      };
  }
};

const initConfig = (sdk: CommandBarProxyGlobal) => {
  const session = genSession();
  sdk[_configuration] = {
    session,
    uuid: sdk[_configuration]?.uuid,
    ...getConfig(),
  };
};

const initWrapper = (): HTMLElement => {
  let wrapper = document.getElementById('commandbar-wrapper');
  let container = document.getElementById('commandbar');

  if (!wrapper) {
    wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'commandbar-wrapper');
    wrapper.setAttribute('data-commandbar', '1');

    document.body.appendChild(wrapper);
  }
  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', 'commandbar');
    container.setAttribute('data-commandbar', '1');
    wrapper.appendChild(container);
  }
  return container;
};

export const loadedFromApi = () => typeof getProxySDK()[_orgConfig]?.name === 'string';
export const loadedFromStaticBundle = () => !!process.env.REACT_APP_NPM_VERSION;

/** Initialize the global client API proxy (window.CommandBar) and prepares the DOM for rendering. */
export const deploy = (): HTMLElement => {
  Logger.blue('[CommandBar] Loading...');

  const sdk = getProxySDK();
  ErrorReporter.initialize();
  const container = initWrapper();
  initConfig(sdk);

  return container;
};
