/*******************************************************************************/
/* Imports
/*******************************************************************************/

import _get from 'lodash.get';
import ExecutionPath, { IExecutionPathState } from './ExecutionPath';
import { Option, CommandOption, ParameterOption, ResourceOption, UnfurledCommandOption } from './option';
import { ChooseCommandOrResourceStep, SelectStep, MultiSelectStep, TextInputStep } from './step';
import {
  ICommandType,
  IStepArgumentType,
  IConditionType,
  IArgumentType,
  IResourceSettings,
} from '@commandbar/internal/middleware/types';

import { compareObjs } from '@commandbar/internal/middleware/utils';

import { InternalError } from './Errors';

import ClientSearch from './ClientSearch';

/*******************************************************************************/
/* Constants
/*******************************************************************************/

const MAX_UNFURL_LENGTH = 50000;

/******************************** Option availability **********************************/
const _passesCondition = (option: any, condition?: IConditionType) => {
  if (condition === undefined) return true;
  switch (condition.operator) {
    case '==':
      return _get(option?.parameter, condition.field) === condition.value;
    case '!=':
      return _get(option?.parameter, condition.field) !== condition.value;
    default:
      return true;
  }
};

const _isOptionAvailable = (option: any, conditions: IConditionType[] | undefined) => {
  if (conditions === undefined || !Array.isArray(conditions)) {
    // Array.isArray for temporary backwards compatibility to non-array condition
    return true;
  }

  const failedOneCondition = conditions.some(function (condition: IConditionType) {
    return !_passesCondition(option, condition);
  });
  return !failedOneCondition;
};

const _filterOptionsByAvailability = (options: any[], config: IArgumentType) => {
  return options.filter((opt) => _isOptionAvailable(opt, config.availability_condition));
};

/******************************** Parameter  logic **********************************/

const _optionsFromKey = (
  executionPathState: IExecutionPathState,
  obj: any,
  key: string,
  argument: IStepArgumentType,
) => {
  let possibleValues;

  if (ClientSearch.isDefined(key, executionPathState)) {
    // If clientSearch is defined, don't throw an error if it can't be found in state
    possibleValues = ClientSearch.get(key, executionPathState) || [];
  } else {
    possibleValues = _get(obj, key);
  }

  if (possibleValues === undefined) {
    throw new InternalError(
      `Trying to get value of ${key} for ${argument.userDefinedName} returned undefined.`,
      executionPathState,
    );
  }

  if (Array.isArray(possibleValues) && possibleValues.length > MAX_UNFURL_LENGTH) {
    possibleValues = possibleValues.slice(0, MAX_UNFURL_LENGTH);
  } else if (!Array.isArray(possibleValues)) {
    possibleValues = [possibleValues];
  }

  possibleValues = possibleValues.map((obj: any) => {
    return ParameterOption.convertParameterToOption(executionPathState, obj, argument);
  });

  return possibleValues;
};

/******************************** Resource logic **********************************/
export const commandsForResource = (resourceKey: string, executionPathState: IExecutionPathState) => {
  return availableCommands(executionPathState).filter((commandOption: CommandOption) => {
    const referencesResource = Object.keys(commandOption.command.arguments).find(
      (arg: string) =>
        commandOption.command.arguments[arg].type === 'context' &&
        commandOption.command.arguments[arg].value === resourceKey,
    );
    return !!referencesResource;
  });
};

const _isResourceAvailableForCommand = (
  resource: ResourceOption,
  command: CommandOption,
  executionPathState: IExecutionPathState,
) => {
  // Does the command reference the resource?
  const arg = Object.keys(command.command.arguments).find(
    (arg: string) =>
      command.command.arguments[arg].type === 'context' &&
      command.command.arguments[arg].value === resource.category.contextKey,
  );
  if (!arg) return false;

  // Does the resoruce meet the parameter availability condition?
  const config = command.command.arguments[arg];
  const _parameterOption = new ParameterOption(executionPathState, resource.label, resource.parameter);
  return _isOptionAvailable(_parameterOption, config.availability_condition);
};

const getContextKeyValue = (key: string, executionPathState: IExecutionPathState): any => {
  if (ClientSearch.isDefined(key, executionPathState)) {
    return ClientSearch.get(key, executionPathState);
  } else {
    const context = executionPathState.context;
    if (context.hasOwnProperty(key) && Array.isArray(context[key])) {
      return context[key];
    }
  }
  return undefined;
};
const availableOptionsForResource = (executionPathState: IExecutionPathState): CommandOption[] | ResourceOption[] => {
  if (!executionPathState.organization) {
    return [];
  }

  const contextSettings = executionPathState.hackContextSettings;

  // Get the searchable resource keys as defined on the Organization object (organization.resource_options)
  const searchableResourceKeys = Object.keys(contextSettings).filter((k: string) => {
    // Search is on, and resources are defined in context as an array
    return contextSettings[k].search && !!getContextKeyValue(k, executionPathState);
  });

  searchableResourceKeys.sort((a, b) =>
    compareObjs(
      { sort_key: contextSettings[a].sort_key || 0, id: 0 },
      { sort_key: contextSettings[b].sort_key || 0, id: 0 },
    ),
  );

  const toRet: any[] = [];
  searchableResourceKeys.forEach((k: string) => {
    const commands = commandsForResource(k, executionPathState);
    const searchOptions: IResourceSettings = contextSettings[k];

    const unfurl = !!searchOptions.unfurl;

    getContextKeyValue(k, executionPathState).forEach((r: any) => {
      if (!k) return;
      const label = ResourceOption.getLabel(r, k, executionPathState);

      const activeObjectLabel = executionPathState.activeObjects[k] && executionPathState.activeObjects[k]?.objectLabel;
      const isActiveObject = !!activeObjectLabel && !Array.isArray(activeObjectLabel) && activeObjectLabel === label;
      const resource = new ResourceOption(executionPathState, r, label, k, searchOptions);

      // Unfurl if it's an active object
      if (!unfurl && !isActiveObject) {
        toRet.push(resource);
        return;
      }

      // UNFURLING LOGIC: Get the avaialble commands for a resource and show them
      const availableCommands = commands.filter((command) =>
        _isResourceAvailableForCommand(resource, command, executionPathState),
      );
      availableCommands.forEach((c: CommandOption) => {
        toRet.push(new UnfurledCommandOption(executionPathState, c.command, c, resource));
      });
    });
  });

  return toRet;
};

/******************************** Commands logic **********************************/

const availableCommands = (executionPathState: IExecutionPathState): CommandOption[] => {
  return executionPathState.allCommands
    .filter((command: ICommandType) => {
      if (executionPathState.testMode) {
        return true;
      }
      return !['admin'].includes(command.template.type) && command.is_live;
    })
    .map((command: ICommandType) => {
      return new CommandOption(executionPathState, command);
    })
    .filter((commandOption: CommandOption) => {
      if (executionPathState.testMode) {
        return true;
      }

      return !commandOption.optionDisabled.isDisabled && commandOption.isValid(executionPathState);
    });
};

/******************************** Commands logic **********************************/

const available = (executionPathState: IExecutionPathState): Option[] => {
  const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);

  if (currentStep === undefined) {
    return [];
  }

  /* typical starting point */
  if (currentStep instanceof ChooseCommandOrResourceStep) {
    if (currentStep.resource !== null) {
      // Get all the commands that reference the active resource
      const commands = commandsForResource(currentStep.resource.category.contextKey, executionPathState);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return commands.filter((c) => _isResourceAvailableForCommand(currentStep.resource!, c, executionPathState));
    } else {
      const commands = availableCommands(executionPathState);
      const resourceOptions = availableOptionsForResource(executionPathState);
      return [...commands, ...resourceOptions];
    }
  }

  /* select parameters step */
  if (currentStep instanceof SelectStep || currentStep instanceof MultiSelectStep) {
    if (!currentStep.argument || !currentStep.argument.type) {
      return [];
    }

    const argument = currentStep.argument;

    switch (currentStep.argument.type) {
      case 'context':
        if (
          !!Object.keys(executionPathState.callbacks).find(
            (callbackKey: string) => callbackKey === `commmandbar-initialvalue-${currentStep.argument.value}`,
          )
        ) {
          /**
           * Setting the options by async function is in CommandBar.tsx, just like 'function-generated'
           */
          return [];
        }
        const context = executionPathState.context;
        const options = _optionsFromKey(executionPathState, context, currentStep.argument.value, argument);
        return _filterOptionsByAvailability(options, currentStep.argument);
      case 'provided':
        switch (currentStep.argument.value) {
          case 'time':
            return [];
          case 'text':
            return [];
          default:
            return [];
        }
      case 'set':
        const set = currentStep.argument.value;
        if (Array.isArray(set)) {
          // @ts-expect-error: FIXME set types
          return set.slice(0, MAX_UNFURL_LENGTH).map((obj: any) => {
            return ParameterOption.convertParameterToOption(executionPathState, obj, currentStep.argument);
          });
        } else {
          throw new Error(`Set for ${currentStep.argument.userDefinedName} must be an array.`);
        }
      case 'dependent':
        const dependentArg = currentStep.argument.value.split('.')[0];
        const lookupKey = currentStep.argument.value.split('.').slice(1).join('.');
        const selections = ExecutionPath.selections(executionPathState);
        const dependentObj = selections[dependentArg];

        try {
          return _optionsFromKey(executionPathState, dependentObj, lookupKey, currentStep.argument);
        } catch {
          return [];
        }

        break;
      case 'function':
        /**
         * Setting the options by async function is in CommandBar.tsx
         */
        return [];
    }
  }

  if (currentStep instanceof TextInputStep) {
    const textString = currentStep.argument.userDefinedName;
    const textValue = currentStep.selected?.data || '';
    const textOption = new ParameterOption(executionPathState, textString, textValue);
    return [textOption];
  }

  /* error: should never get here */
  throw new Error('Trying to calculate availability on invalid step.');
};

const Available = {
  available,
};

export default Available;
