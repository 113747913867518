/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { getOperatingSystem, OS } from '@commandbar/internal/util/operatingSystem';
import { useTheme } from 'emotion-theming';
import chroma from 'chroma-js';

import Tag from './Tag';
import { ITheme } from '@commandbar/internal/client/theme';

/*******************************************************************************/
/* Render
/*******************************************************************************/

interface IProps {
  keys: string[]; // Shortcut of format 'command+k'
  highlight?: boolean;
}

const KeyboardShortcut = (props: IProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const os = React.useMemo(getOperatingSystem, []);

  if (props.keys.length === 0) {
    return null;
  }

  if (![OS.MAC, OS.WINDOWS, OS.LINUX].includes(os)) {
    return null;
  }

  const getIcon = (k: string, i: number) => {
    let icon;

    if ([OS.MAC].includes(os)) {
      switch (k.toLowerCase()) {
        case 'command':
          icon = <span key={i}>⌘</span>;
          break;
        case 'option':
          icon = <span key={i}>⌥</span>;
          break;
        case 'ctrl':
          icon = <span key={i}>^</span>;
          break;
        case 'shift':
          icon = <span key={i}>⇧</span>;
          break;
        case 'return':
          icon = <span key={i}>Return</span>;
          break;
        default:
          if (k.length > 1) {
            icon = <span key={i}>{k.toLowerCase()}</span>;
          } else {
            icon = <span key={i}>{k.toUpperCase()}</span>;
          }
      }
    } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
      switch (k.toLowerCase()) {
        case 'ctrl':
          icon = <span key={i}>Ctrl+</span>;
          break;
        case 'shift':
          icon = <span key={i}>⇧</span>;
          break;
        case 'alt':
          icon = <span key={i}>alt</span>;
          break;
        case 'meta':
          icon = <span key={i}>⊞</span>;
          break;
        case 'return':
          icon = <span key={i}>Return</span>;
          break;
        default:
          if (k.length > 1) {
            icon = <span key={i}>{k.toLowerCase()}</span>;
          } else {
            icon = <span key={i}>{k.toUpperCase()}</span>;
          }
      }
    } else {
      icon = null;
    }

    return icon;
  };

  let color: any;
  try {
    color = chroma(theme.keyboardShortcuts.color);
  } catch {
    color = chroma('rgba(255, 255, 255, 0.85)');
  }

  const sequences = props.keys
    .join('+')
    .split(/\+then\d?\+/)
    .map((s: string) => {
      return s.split('+');
    });

  const tags: any = [];
  sequences.forEach((s: string[], index: number) => {
    tags.push(
      <Tag
        key={s.join('-')}
        color={color}
        borderRadius={theme.keyboardShortcuts.borderRadius}
        backgroundColor={theme.keyboardShortcuts.background}
        borderColor={props.highlight ? theme.keyboardShortcuts.highlight : theme.keyboardShortcuts.borderColor}
        fontSize={theme.keyboardShortcuts.fontSize}
        style={{
          paddingTop: theme.keyboardShortcuts.paddingTop,
          paddingBottom: theme.keyboardShortcuts.paddingBottom,
          paddingLeft: theme.keyboardShortcuts.paddingLeft,
          paddingRight: theme.keyboardShortcuts.paddingRight,
        }}
      >
        {s.map((k: string, i) => {
          return getIcon(k, i);
        })}
      </Tag>,
    );

    if (index !== sequences.length - 1) {
      tags.push(
        <span key={`then-${index}`} style={{ margin: '0px 4px' }}>
          then
        </span>,
      );
    }
  });

  return tags;
};

export default KeyboardShortcut;
