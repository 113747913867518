/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React, { useState, useEffect } from 'react';
import { generateTheme, ITheme } from '@commandbar/internal/client/theme';
import chroma from 'chroma-js';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { Skin } from '@commandbar/internal/middleware/skin';

interface IThemeAttributes {
  [attribute: string]: string;
}

const DARK_MODE_BASE_FONT_COLOR = 'rgba(255,255,255,0.85)';
const LIGHT_MODE_BASE_FONT_COLOR = 'rgba(0,0,0,0.85)';

const pickFontColorForBackground = (backgroundColor: string) => {
  // Web Standards recommend at least a 4:5:1 contrast ratio between text and background
  // https://www.w3.org/TR/WCAG20-TECHS/G18.html
  if (chroma.contrast(backgroundColor, DARK_MODE_BASE_FONT_COLOR) > 4.5) {
    return DARK_MODE_BASE_FONT_COLOR;
  } else {
    return LIGHT_MODE_BASE_FONT_COLOR;
  }
};

const THEME: Record<string, IThemeAttributes> = {
  dark: {
    background: 'rgb(36,35,41)',
    fontColor: DARK_MODE_BASE_FONT_COLOR,
  },
  light: { background: 'rgba(255,255,255, 1.0)', fontColor: LIGHT_MODE_BASE_FONT_COLOR },
};

const isHexColor = (str: string) => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(str);
};

const useCustomTheme = (props: {
  userTheme: any;
  baseTheme?: string;
  themeSource: string;
  organization: IOrganizationType | undefined;
  setLogo: (svgString: string) => void;
}) => {
  const [semaphore, setSemaphore] = React.useState(false);
  const { userTheme, baseTheme, themeSource, organization } = props;
  const [theme, setTheme] = React.useState<ITheme | undefined>(undefined);
  const [prevThemeSource, setPrevThemeSource] = useState('');

  const setProgrammaticTheme = async () => {
    if (['light', 'dark'].includes(baseTheme as string)) {
      const baseThemeStyles = {
        ...THEME[baseTheme as string],
        primary: userTheme?.base?.primary ?? theme?.base?.primary,
        fontFamily: userTheme?.base?.fontFamily ?? 'Avenir Next, proxima-nova, sans-serif',
      };
      setTheme(generateTheme({ ...userTheme, base: baseThemeStyles }));
    } else if (isHexColor(baseTheme as string)) {
      const fontColor = pickFontColorForBackground(baseTheme as string);

      const baseThemeStyles = {
        background: baseTheme as string,
        fontColor,
        primary: userTheme?.base?.primary ?? theme?.base?.primary,
        fontFamily: userTheme?.base?.fontFamily ?? 'Avenir Next, proxima-nova, sans-serif',
      };
      setTheme(generateTheme({ ...userTheme, base: baseThemeStyles }));
    } else {
      if (!organization) return;
      if (baseTheme === 'reset') return;

      try {
        const data = await Skin.read(baseTheme ?? '', { org: organization.id.toString() });
        setTheme(generateTheme(data.skin));
        props.setLogo(data?.logo ?? '');
      } catch (err) {
        console.error(`Theme slug ${baseTheme} not found.`);
      }
    }
  };

  React.useEffect(() => {
    if (baseTheme) {
      setProgrammaticTheme();
      return;
    }

    if (userTheme !== undefined) {
      setTheme(generateTheme(userTheme));
    }
  }, [userTheme, baseTheme, themeSource]);

  useEffect(() => {
    setPrevThemeSource(themeSource);
  }, [themeSource]);

  useEffect(() => {
    if (baseTheme && !semaphore && prevThemeSource === 'setThemeFunction') {
      setProgrammaticTheme();
    }
  }, [prevThemeSource, themeSource, baseTheme]);

  React.useEffect(() => {
    if (!semaphore) {
      setSemaphore(true);
    }
  }, [theme, semaphore]);

  /******************************************************************/

  return {
    userTheme: props.userTheme,
    theme,
  };
};

export default useCustomTheme;
