import moment from 'moment';
import * as chrono from 'chrono-node';
import { DEFAULTS_TIME_OPTIONS, MONTHS, NUMBERS, SpecialUnitHandler, Suggestion, WEEKDAYS } from './constants';

function getNextDayOfWeek(date: Date, dayOfWeek: number) {
  // Code to check that date and dayOfWeek are valid left as an exercise ;)

  const resultDate = new Date(date.getTime());

  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return resultDate;
}

const getThisWeekend = () => {
  return getNextDayOfWeek(new Date(), 6);
};

const getNextWeekend = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return getNextDayOfWeek(date, 6);
};

const customChrono = chrono.casual.clone();

customChrono.refiners.push({
  refine: (context, results) => {
    // If there is no AM/PM (meridiem) specified,
    results.forEach((result) => {
      const hour = result.start.get('hour') as number;

      if (!result.start.isCertain('meridiem') && hour > 0 && hour < 12) {
        result.start.assign('meridiem', 1);
        result.start.assign('hour', hour + 12);
      }
    });

    return results;
  },
});

customChrono.parsers.push({
  pattern: () => {
    return /\bthis weekend\b/;
  },
  extract: (_context, _match) => {
    const date = getThisWeekend();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      hour: 8,
      minute: 0,
      meridiem: 0,
    };
  },
});

customChrono.parsers.push({
  pattern: () => {
    return /\bnext weekend\b/i;
  },
  extract: (_context, _match) => {
    const date = getNextWeekend();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      hour: 8,
      minute: 0,
      meridiem: 0,
    };
  },
});

customChrono.parsers.push({
  pattern: () => {
    return /\bweekend\b/i;
  },
  extract: (_context, _match) => {
    const date = getThisWeekend();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      hour: 8,
      minute: 0,
      meridiem: 0,
    };
  },
});

export const parseDate = (input: string) => {
  return customChrono.parse(input, undefined);
};

const specialUnits: Record<string, SpecialUnitHandler> = {
  someday: () => ({ type: 'datetime', date: moment(randomDate()) }),
  sometime: () => ({ type: 'datetime', date: moment(randomDate()) }),
};

export const getText = (string: string, originalString?: string) => {
  const splitOriginalString = originalString?.split(' ');
  const originalStringFirstWord = splitOriginalString && splitOriginalString[0];
  const splitString = string.split(' ');
  const firstWord = splitString[0];

  if (originalString && originalStringFirstWord?.toLocaleLowerCase() === 'in') {
    return originalString;
  }

  if (firstWord.toLowerCase() === 'an') {
    return `in ${string}`;
  }

  if (firstWord.toLowerCase() === 'on') {
    return string;
  }

  if (MONTHS.find((month) => month.toLowerCase().includes(firstWord.trim().toLowerCase()))) {
    splitString.unshift('on');
    return splitString.join(' ');
  }

  if (WEEKDAYS.find((day) => day.toLowerCase().includes(firstWord.trim().toLowerCase()))) {
    splitString.unshift('on');
    return splitString.join(' ');
  }

  return string;
};

export const isMonth = (inputValue: string) => {
  const firstWord = inputValue.split(' ')[0];
  return MONTHS.find((month) => month.toLowerCase() === firstWord.toLowerCase());
};

export const suggestAt = (words: string[]) => {
  const res = ['8am', '9am', '10am'].map((time) => words.concat(time).join(' '));
  return res;
};

export const getHour = (text: string, knownHour: number, impliedHour: number) => {
  if (
    ['morning', 'noon', 'afternoon', 'evening', 'midnight', 'tonight', 'night'].find((item) =>
      text.toLowerCase().includes(item),
    )
  ) {
    return impliedHour;
  }

  // 12am is being parsed as hour = 0
  if (knownHour === 0) {
    return knownHour;
  }

  return knownHour || DEFAULTS_TIME_OPTIONS.hour;
};

export const randomDate = (start = new Date(), end = new Date(2050, 0, 1)) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

export const getWordByIndex = (query: string, index: number) => {
  return query.split(' ')[index];
};

export const hasSpecialUnits = (query: string) => {
  return Object.keys(specialUnits).some((unit) => query.startsWith(unit));
};

export const normalizeNumber = (word: string) => {
  if (isNaN(+word)) {
    return Object.keys(NUMBERS).find((key) => NUMBERS[key].includes(word));
  }

  return +word;
};

export const parseToSuggestionFormat = (
  parsingResults: chrono.ParsedResult[],
  specificType: number,
  query?: string,
): Suggestion[] => {
  return parsingResults.map((chronoObject: any) => {
    const result = {
      type: 'datetime',
      specificType,
      label: getText(chronoObject.text as string, query),
      date: moment({
        year: chronoObject.start.get('year'),
        month: chronoObject.start.get('month') - 1, // align month (moment starts month counting from 0, but chrono starts from 1).
        day: chronoObject.start.get('day'),
        hour: getHour(chronoObject.text, chronoObject.start.knownValues.hour, chronoObject.start.impliedValues.hour),
        minute: chronoObject.start.knownValues.minute || DEFAULTS_TIME_OPTIONS.minute,
      }),
    };

    return result;
  });
};

export const parseToDateOnlyFormat = (parsingResults: chrono.ParsedResult[], query?: string): Suggestion[] => {
  return parsingResults.map((chronoObject: any) => {
    const result = {
      type: 'datetime',
      label: getText(chronoObject.text as string, query),
      date: moment({
        year: chronoObject.start.get('year'),
        month: chronoObject.start.get('month') - 1, // align month (moment starts month counting from 0, but chrono starts from 1).
        day: chronoObject.start.get('day'),
        hour: getHour(chronoObject.text, chronoObject.start.knownValues.hour, chronoObject.start.impliedValues.hour),
        minute: chronoObject.start.knownValues.minute || DEFAULTS_TIME_OPTIONS.minute,
      }),
    };

    return result;
  });
};

export const getSpecialUnitsSuggestions = (inputValue: string) => {
  return Object.keys(specialUnits)
    .filter((unit) => unit === inputValue)
    .reduce<Suggestion[]>((acc, cur) => {
      const formatted = {
        label: cur,
        ...specialUnits[cur](),
      };

      return acc.concat(formatted);
    }, []);
};

export const getSuggestionsFromStringArray = (array: string[], specificType: number) => {
  const suggestions = array.reduce<Suggestion[]>((acc, cur) => {
    const parsingResults = parseDate(cur);
    return acc.concat(...parseToSuggestionFormat(parsingResults, specificType, cur));
  }, []);
  return suggestions;
};

export const getDefaultSuggestions = (defaults: string[], specificType: number) => {
  return getSuggestionsFromStringArray(defaults, specificType);
};
