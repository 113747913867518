import React from 'react';
import { Option } from './Option';
import { Step, SelectStep, MultiSelectStep, TextInputStep } from '../step';
import ExecutionPath from '../ExecutionPath';
import { IExecutionPathState } from '../ExecutionPath';
import isEqual from 'lodash.isequal';
import OptionWrapper from '../../components/select/menu/OptionWrapper';
import { IStepArgumentType, IResourceSettings, isContextArgument } from '@commandbar/internal/middleware/types';
import OptionValidate from './OptionValidate';
import DateTime from '../predefinedTypes/DateTime/DateTime';
import { isPrimitive } from '../../client_api/utils';

import _get from 'lodash.get';
import deepGet from '../fuzzysort-fork/deepGet/deepGet';
import SearchMatchFormatter, { showTopSearchMatch } from '../../components/select/menu/SearchMatchFormatter';
import Icon, { ICON_SIZE } from '@commandbar/internal/client/Icon';
import { SelectOrCreate } from '../features/selectOrCreate';
import moment from 'moment';
import { UnderlineFormatter } from './UnderlineFormatter';
import { ITheme } from '@commandbar/internal/client/theme';

export class ParameterOption extends Option {
  public parameter: any;
  public tags: { key: string; value: string | string[] }[];
  public searchOptions?: IResourceSettings;
  public description: string | undefined;
  public breadcrumbs: string | undefined;

  constructor(
    executionPathState: IExecutionPathState,
    label: string,
    parameter: any,
    searchOptions?: IResourceSettings,
  ) {
    super(executionPathState, 'parameter', label, label);
    this.parameter = parameter;
    // we need to preserve both the key and the value of searchable fields, because we use the key in highlighting
    //    search matches. E.g., contact.address = "10 main street"
    //    When we search 10 main, we want to show "address: 10 main street", instead of just "10 main street"
    this.tags = (searchOptions?.search_fields || []).map((t) => ({
      key: t,
      // deepGet used to fetch nested objs, i.e., comments.text where comments is an array
      value: deepGet(parameter, t),
    }));
    this.searchOptions = searchOptions;
    this.description = searchOptions?.description_field && _get(this.parameter, searchOptions.description_field);
    this.breadcrumbs = this.getReservedField('__breadcrumbs');

    this._isDisabled(executionPathState);
  }

  /************************ Static functions ****************************/

  public static convertParameterToOption = (
    executionPathState: IExecutionPathState,
    parameter: any,
    argument: IStepArgumentType,
  ) => {
    if (isPrimitive(parameter)) {
      return new ParameterOption(executionPathState, parameter, parameter, undefined);
    } else {
      let labelField = argument.label_field || 'label';
      let searchSettings;
      if (isContextArgument(argument)) {
        searchSettings = executionPathState.hackContextSettings[argument.value.toString()];
        if (searchSettings && searchSettings.label_field) {
          labelField = searchSettings.label_field;
        }
      }
      return new ParameterOption(executionPathState, _get(parameter, labelField), parameter, searchSettings);
    }
  };

  public static isSelected = (option: ParameterOption, currentStep: Step) => {
    const selected = currentStep?.selected?.data;
    if (Array.isArray(selected)) {
      return selected.some((selected) => isEqual(option.parameter, selected));
    } else {
      return isEqual(option.parameter, selected);
    }
  };

  /**********************************************************************/

  /************************ Validators **********************************/
  public isValid = (_executionPathState: IExecutionPathState): { isValid: boolean; isValidReason: string } => {
    return { isValid: true, isValidReason: '' };
  };

  public isAvailable = (
    executionPathState: IExecutionPathState,
  ): { isAvailable: boolean; isAvailableReason: string } => {
    return OptionValidate.isAvailable(this, executionPathState);
  };

  public isExecutable = (
    _executionPathState: IExecutionPathState,
  ): { isExecutable: boolean; isExecutableReason: string } => {
    return { isExecutable: true, isExecutableReason: '' };
  };

  /**********************************************************************/

  public choose = (executionPathState: IExecutionPathState, executionPathDispatch: any): void | IExecutionPathState => {
    const { currentStep, currentStepIndex } = ExecutionPath.currentStepAndIndex(executionPathState);

    if (currentStep === undefined) {
      throw new Error('Invalid parameter step');
    }

    /**
     * SelectStep:
     *  - Add this parameter to {state.selected}
     *  - Fulfill immediately
     */
    if (currentStep instanceof SelectStep || currentStep instanceof TextInputStep) {
      // @IMPROVEMENT: Can create a helper to loop through steps and edit one of them
      const steps = executionPathState.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return step.select(this);
        }
        return step;
      });

      const updatedState = {
        ...executionPathState,
        steps,
      };

      if (executionPathState.simulation) {
        return updatedState;
      } else {
        executionPathDispatch({ type: 'updateFulfillAndRebase', updatedState });
        return;
      }
    } else if (currentStep instanceof MultiSelectStep) {
      const steps = executionPathState.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return step.select(this);
        }
        return step;
      });

      const updatedState = {
        ...executionPathState,
        steps,
      };

      if (executionPathState.simulation) {
        return updatedState;
      } else {
        executionPathDispatch({ type: 'update', updatedState });
        return;
      }
    } else {
      throw new Error('Unknown parameter step');
    }
  };

  /**
   * This command's icon
   */

  public icon = (theme: ITheme, isFocused: boolean, executionPathState: IExecutionPathState) => {
    const { color, opacity, useDefaultSVGColor } = this.iconColorBase(theme, isFocused);
    const preserveSVG = !!this.getReservedField('__preserveSVG');
    const userDefinedIcon = this.getReservedField('icon');
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    if (currentStep instanceof TextInputStep) {
      return <div />;
    }

    if (currentStep instanceof MultiSelectStep) {
      const thisOptionIsSelected = ParameterOption.isSelected(this, currentStep);
      if (thisOptionIsSelected) {
        return MultiSelectStep.selectedIcon(theme, {
          userDefinedIcon,
          useDefaultSVGColor: useDefaultSVGColor || preserveSVG,
        });
      } else if (!!userDefinedIcon) {
        return (
          <Icon
            icon={userDefinedIcon}
            default={<div style={{ minWidth: ICON_SIZE, display: 'inline-block' }} />}
            style={{ color, minWidth: ICON_SIZE }}
            useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
          />
        );
      } else {
        return MultiSelectStep.defaultIcon(color, opacity);
      }
    }

    if (currentStep instanceof SelectStep) {
      const thisOptionIsSelected = ParameterOption.isSelected(this, currentStep);
      const customIcon = userDefinedIcon || this.searchOptions?.icon;
      const useCalculatedOpacity = !userDefinedIcon; // Don't overwrite opacity of user defined icon
      if (thisOptionIsSelected) {
        return MultiSelectStep.defaultSelectedIcon(theme.optionSelected.iconColor);
      }
      if (!!customIcon) {
        const styles = { color, minWidth: ICON_SIZE, opacity: useCalculatedOpacity ? opacity : undefined };
        return (
          <Icon
            icon={customIcon}
            default={<div style={{ minWidth: ICON_SIZE, display: 'inline-block' }} />}
            style={styles}
            useDefaultSVGColor={useDefaultSVGColor || preserveSVG}
          />
        );
      } else {
        return SelectStep.defaultIcon(color, opacity);
      }
    }

    return <div style={{ minWidth: ICON_SIZE }} />;
  };

  public addOn = (theme: ITheme, _isFocused: boolean, _executionPathState: IExecutionPathState): JSX.Element | null => {
    if (this.isDateTimeOption()) {
      return (
        <div
          style={{
            fontSize: parseInt(theme.option.fontSize, 10) - 2,
            fontFamily: 'monospace',
            color: theme.option.color,
          }}
        >
          {DateTime.display(this.parameter.date, this.parameter.specificType)}
        </div>
      );
    }
    return null;
  };

  public renderOption = (theme: ITheme, isFocused: boolean, executionPathState: IExecutionPathState) => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    const icon = this.icon(theme, isFocused, executionPathState);
    const color = isFocused ? theme.optionSelected.color : theme.option.color;

    const addOn = this.addOn(theme, isFocused, executionPathState);
    let text: string | React.ReactNode = this.label;

    const descriptionField = this.searchOptions?.description_field;
    const descriptionText = !!descriptionField && _get(this.parameter, descriptionField);
    const extraHTML = this.getReservedField('__extraHTML');

    const isHTML = (str: string) => {
      // https://stackoverflow.com/a/15458987
      return /<\/?[a-z][\s\S]*>/i.test(str);
    };

    const transformSearchField = (str: string) => {
      // Split on '.'
      let result = str.split('.')[0];
      // remove snake_case
      result = result.replace('_', ' ');
      // remove camelCase
      result = result.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    };

    const searchMatch = showTopSearchMatch(this.fuseMatches) && (
      <SearchMatchFormatter
        matches={this.fuseMatches}
        formatKey={(searchKey: string, searchValue: string) => {
          /* find the name of the field corresponding to the top match */
          const matchedField = this.tags.find(({ value }) =>
            Array.isArray(value) ? value.includes(searchValue) : searchValue === value,
          )?.key;
          if (!matchedField) return '';
          return (
            <span
              style={{
                color: theme.searchableFieldMatch.color,
                background: theme.searchableFieldMatch.background,
                paddingTop: theme.searchableFieldMatch.paddingTop,
                paddingBottom: theme.searchableFieldMatch.paddingBottom,
                paddingLeft: theme.searchableFieldMatch.paddingLeft,
                paddingRight: theme.searchableFieldMatch.paddingRight,
                marginRight: theme.searchableFieldMatch.marginRight,
                marginLeft: theme.searchableFieldMatch.marginLeft,
                borderRadius: theme.searchableFieldMatch.borderRadius,
                lineHeight: theme.searchableFieldMatch.lineHeight,
                overflow: 'hidden',
                display: 'inline-flex',
              }}
            >{`${transformSearchField(matchedField)}`}</span>
          );
        }}
        formatValue={(value: string) =>
          Option.highlight('tags.value', value, this.fuseMatches, this.fuseScore, theme, false)
        }
        theme={theme}
      />
    );

    let descriptionBlock;
    if (searchMatch) {
      descriptionBlock = searchMatch;
    } else if (descriptionText) {
      descriptionBlock = Option.highlight(
        'description',
        descriptionText,
        this.fuseMatches,
        this.fuseScore,
        theme,
        true,
      );
    }

    let extraBlock;
    if (extraHTML && isHTML(extraHTML)) {
      extraBlock = <div dangerouslySetInnerHTML={{ __html: extraHTML }} />;
    }

    if (currentStep instanceof TextInputStep && typeof this.parameter === 'string') {
      text = <UnderlineFormatter prefix={this.label} suffix={this.parameter} delimiter={':'} theme={theme} />;
    }

    if (SelectOrCreate.isEnabled(currentStep) && SelectOrCreate.recordIsCreated(this.parameter)) {
      text = <UnderlineFormatter prefix={this.label} suffix={this.parameter.value} delimiter={':'} theme={theme} />;
    }

    const breadcrumbs = Option.highlight(
      'breadcrumbs',
      this.getReservedField('__breadcrumbs'),
      this.fuseMatches,
      this.fuseScore,
      theme,
      true,
    );

    return (
      <OptionWrapper
        label={Option.highlight('label', text, this.fuseMatches, this.fuseScore, theme, false)}
        color={color}
        description={descriptionBlock}
        extra={extraBlock}
        type="resource"
        icon={icon}
        shortcut={addOn}
        isFocused={isFocused}
        theme={theme}
        breadcrumbs={breadcrumbs}
      />
    );
  };

  public getReservedField = (field: 'category' | 'icon' | '__preserveSVG' | '__extraHTML' | '__breadcrumbs') => {
    switch (field) {
      case 'category':
        if (typeof this.parameter?.category === 'string') {
          return this.parameter?.category;
        }
        break;
      case 'icon':
        if (typeof this.parameter?.icon === 'string') {
          return this.parameter?.icon;
        }
        break;
      case '__preserveSVG':
        if (!!this.parameter?.__preserveSVG) {
          return this.parameter?.__preserveSVG;
        }
        break;
      case '__extraHTML':
        if (!!this.parameter?.__extraHTML && typeof this.parameter?.__extraHTML === 'string') {
          return this.parameter?.__extraHTML;
        }
        break;
      case '__breadcrumbs':
        if (!!this.parameter?.__breadcrumbs && typeof this.parameter?.__breadcrumbs === 'string') {
          return this.parameter?.__breadcrumbs;
        }
        break;
    }
    return undefined;
  };

  public isDateTimeOption = () => {
    return (
      this.parameter?.type === 'datetime' &&
      this.parameter?.date instanceof moment &&
      typeof this.parameter?.specificType === 'number'
    );
  };
}
