import { IExecutionPathState } from './ExecutionPath';
import { ErrorReporter } from '../error/ErrorReporter';
import { ErrorCode } from '../error/ErrorCode';

export class InternalError extends Error {
  constructor(message: string, executionPathState?: IExecutionPathState) {
    super(message);
    this.name = 'InternalError';

    if (!executionPathState?.simulation) {
      ErrorReporter.get().exception(ErrorCode.UNKNOWN, this, { message });
    }
  }
}
