import React, { ReactNode } from 'react';

import chroma from 'chroma-js';
import { AiOutlineArrowRight } from 'react-icons/ai';

import ExecutionPath, { IExecutionPathState, IExecutionPathDispatchAction } from '../ExecutionPath';
import { Step, ExecuteStep, SelectStep, MultiSelectStep, TextInputStep, ChooseCommandOrResourceStep } from '../step';
import { Option, ParameterOption, ResourceOption } from '../option';

import { ICommandType, IStepArgumentType } from '@commandbar/internal/middleware/types';
import { OS, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';

import Icon, { commandDefault } from '@commandbar/internal/client/Icon';
import OptionWrapper from '../../components/select/menu/OptionWrapper';
import KeyboardShortcut from '../../components/KeyboardShortcut';
import Tag from '../../components/Tag';

import OptionValidate from './OptionValidate';
import { interpolate } from '../Interpolate';
import Simulate from '../Simulate';
import { compareObjsWithMapping } from '@commandbar/internal/middleware/utils';

import _get from 'lodash.get';
import { compareByMultiplePriorityFns } from '../../client_api/utils';
import Tooltip from 'rc-tooltip';
import { ITheme } from '@commandbar/internal/client/theme';

export class CommandOption extends Option {
  public command: ICommandType;
  public isRecommended: boolean;

  constructor(executionPathState: IExecutionPathState, command: ICommandType, parent?: CommandOption) {
    super(
      executionPathState,
      'command',
      parent ? parent.label : interpolate(command.text, executionPathState, true, false),
      command.text,
    );
    this.command = command;
    this.isRecommended = this._isRecommended(executionPathState);
    if (parent) {
      // Checking disabled is an expensive operation that we can avoid if creating
      // an option from a parent
      this.optionDisabled = parent.optionDisabled;
    } else {
      this._isDisabled(executionPathState);
    }
  }

  /************************ Static functions ****************************/

  public static sort = (rankings: Record<string, number> | undefined, a: CommandOption, b: CommandOption) => {
    const comparatorFuncs = [];
    if (rankings) comparatorFuncs.push(compareObjsWithMapping.bind({}, rankings));
    return compareByMultiplePriorityFns(comparatorFuncs, a.command, b.command);
  };

  public static chooseCommandOption = (
    option: CommandOption,
    executionPathState: IExecutionPathState,
    activeResource: ResourceOption | null,
    executionPathDispatch?: React.Dispatch<IExecutionPathDispatchAction>,
    triggeredByShortcut?: boolean,
  ) => {
    const { currentStepIndex } = ExecutionPath.currentStepAndIndex(executionPathState);

    // Transform command arguments into an array, sorted by order_key
    const sortedArgs = Object.keys(option.command.arguments)
      .map((argName: string) => {
        return {
          ...option.command.arguments[argName],
          userDefinedName: option.command.arguments[argName].label || argName,
          userDefinedValue: argName,
        };
      })
      .sort((a: IStepArgumentType, b: IStepArgumentType) => {
        return a.order_key - b.order_key;
      });

    // Turn the arguments into Steps
    const argSteps = sortedArgs
      .map((arg: IStepArgumentType) => {
        switch (arg.type) {
          case 'set':
          case 'dependent':
          case 'context':
          case 'function':
            if (arg.input_type === 'multi-select') {
              return new MultiSelectStep(arg);
            } else {
              return new SelectStep(arg);
            }
          case 'provided':
            if (arg.value === 'time') return new SelectStep(arg);
            if (arg.value === 'text') return new TextInputStep(arg);
            throw new Error('Invalid provided arg type');
          default:
            throw new Error('Invalid arg type');
        }
      })
      .map((step: Step) => {
        let newStep = step;

        // If pre-selected value, pre-select it
        if (newStep instanceof SelectStep || newStep instanceof MultiSelectStep || newStep instanceof TextInputStep) {
          newStep = option.preSelectIfApplicable(newStep, executionPathState);
        }

        // Pre-fill selected values in generated Steps with the active resource
        if (activeResource) {
          // ParameterOption from context resource
          if (step instanceof SelectStep || step instanceof MultiSelectStep || step instanceof TextInputStep) {
            if (step.argument !== undefined && step.argument.type === 'context') {
              if (step.argument.value === activeResource.category.contextKey) {
                const _parameterOption = new ParameterOption(
                  executionPathState,
                  activeResource.label,
                  activeResource.parameter,
                );
                newStep = step.select(_parameterOption).fulfill(executionPathState);
              }
            }
          }
        }

        return newStep;
      });

    const updatedSteps = executionPathState.steps.map((step: Step, index: number) => {
      if (currentStepIndex === index) {
        return step.select(option);
      }
      return step;
    });

    const steps = [...updatedSteps, ...argSteps, new ExecuteStep(option.command, triggeredByShortcut || false)];
    const updatedState = {
      ...executionPathState,
      steps,
    };

    if (executionPathState.simulation) {
      return updatedState;
    } else {
      if (executionPathDispatch) {
        executionPathDispatch({ type: 'updateFulfillAndRebase', updatedState });
      }
    }
  };
  /**********************************************************************/

  /************************ Validators **********************************/
  public isValid = (executionPathState: IExecutionPathState): { isValid: boolean; isValidReason: string } => {
    return OptionValidate.isValid(this, executionPathState);
  };

  public isAvailable = (
    executionPathState: IExecutionPathState,
  ): { isAvailable: boolean; isAvailableReason: string } => {
    return OptionValidate.isAvailable(this, executionPathState);
  };

  public isExecutable = (
    executionPathState: IExecutionPathState,
  ): { isExecutable: boolean; isExecutableReason: string } => {
    if (executionPathState.simulation) {
      return { isExecutable: true, isExecutableReason: '' };
    } else {
      return Simulate.simulate(this, executionPathState);
    }
  };

  public _isRecommended = (executionPathState: IExecutionPathState): boolean => {
    if (executionPathState.organization?.recommendations_type === 'None') return false;
    if (!this.command.recommend_conditions || this.command.recommend_conditions.length === 0) return false;

    const { passed } = OptionValidate.runBooleanConditions(
      this.command.recommend_conditions,
      executionPathState,
      'Recommendations:',
    );

    return passed;
  };

  /**********************************************************************/
  /**
   * Choosing a CommandOption does the following:
   *   - Plucks out the arguments
   *   - Creates a "Step" for each argument
   *   - Creates a "Step" of type "Execute" according to the Command template
   *   - Calls ExecutionPathState.fulfill()
   */

  public choose = (
    executionPathState: IExecutionPathState,
    executionPathDispatch?: React.Dispatch<IExecutionPathDispatchAction>,
    triggeredByShortcut?: boolean,
  ): void | IExecutionPathState => {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);
    const activeResource = currentStep instanceof ChooseCommandOrResourceStep ? currentStep.resource : null;

    return CommandOption.chooseCommandOption(
      this,
      executionPathState,
      activeResource,
      executionPathDispatch,
      triggeredByShortcut,
    );
  };

  /**
   * This command's keyboard shortcut
   */
  public shortcut = (theme: ITheme): ReactNode => {
    const os = getOperatingSystem();
    let hotkeys: string[] = [];

    const highlightShortcut = () => {
      if (this.fuseMatches[0]?.key === 'command.shortcut_mac' || this.fuseMatches[0]?.key === 'command.shortcut_win') {
        const matchedIndices = this.fuseMatches[0]?.indices[0];
        const isValidPair = !!matchedIndices && matchedIndices.length === 2;
        const pairLength = (pair: number[]) => pair[1] - pair[0] + 1;
        const SHORTCUT_LENGTH_TO_HIGHLIGHT = 2;
        if (isValidPair && pairLength(matchedIndices) >= SHORTCUT_LENGTH_TO_HIGHLIGHT) {
          return true;
        }
      }
      return false;
    };

    if (this.command.shortcut_mac) {
      if ([OS.MAC].includes(os)) {
        hotkeys = this.command.shortcut_mac;
      } else if ([OS.WINDOWS, OS.LINUX].includes(os)) {
        hotkeys = this.command.shortcut_win;
      }
    }
    const highlight = highlightShortcut();

    const shortcut = <KeyboardShortcut keys={hotkeys} highlight={highlight} />;

    return highlight ? (
      <Tooltip
        prefixCls="commandbar-tooltip"
        overlay={
          <span style={{ fontFamily: theme.main.fontFamily }}>You can use this hotkey when the bar is closed.</span>
        }
        placement="right"
      >
        <div>{shortcut}</div>
      </Tooltip>
    ) : (
      shortcut
    );
  };

  /**
   * This command's icon
   */
  public icon = (theme: ITheme, isFocused: boolean) => {
    // If the command has been executed before, then give it a custom icon style
    // const hasBeenExecuted = endUser && !!endUser.usage && endUser.usage[this.command.id.toString()] !== undefined;

    const { color, opacity, useDefaultSVGColor } = this.iconColorBase(theme, isFocused);

    const style = { color: this.command.template.type === 'admin' ? 'orange' : color, opacity };

    return (
      <Icon
        icon={this.command.icon}
        default={commandDefault(this.command)}
        style={style}
        useDefaultSVGColor={useDefaultSVGColor}
      />
    );
  };

  public renderLabel = (theme: ITheme) => {
    return (
      <span style={{ color: 'inherit' }}>
        {Option.highlight('label', this.label, this.fuseMatches, this.fuseScore, theme, false)}
      </span>
    );
  };

  public renderOption = (theme: ITheme, isFocused: boolean, executionPathState: IExecutionPathState): ReactNode => {
    let { isDisabled, isDisabledReason } = this.optionDisabled;

    // This is only important for proactively catching click errors since the underlying app state may have shifted
    if (!isDisabled) {
      const { isValid, isValidReason } = this.isValid(executionPathState);
      if (!isValid) {
        isDisabled = !isValid;
        isDisabledReason = isValidReason;
      }
    }

    const icon = this.icon(theme, isFocused);

    let color = theme.option.color;
    if (isDisabled) {
      color = theme.optionDisabled.color;
    } else if (isFocused) {
      color = theme.optionSelected.color;
    }

    const draft = !this.command.is_live && executionPathState.isAdmin && (
      <span>
        <Tag color={chroma('lightgrey')}>Draft</Tag>{' '}
      </span>
    );

    const explanation = (this.command.explanation || isDisabled) && (
      <>
        {isDisabled && executionPathState.isAdmin && <span style={{ color: 'orange' }}>{isDisabledReason}</span>}
        {this.command.explanation && !isDisabled && (
          <span>
            {Option.highlight(
              'command.explanation',
              this.command.explanation,
              this.fuseMatches,
              this.fuseScore,
              theme,
              true,
            )}
          </span>
        )}
      </>
    );

    const shortcut = this.shortcut(theme);

    const goForward = Object.keys(this.command.arguments).length > 0 && (
      <span
        style={{
          marginLeft: 10,
          marginTop: 6,
          color: isFocused ? theme.optionSelected.iconColor : theme.option.iconColor,
        }}
      >
        <AiOutlineArrowRight />
      </span>
    );

    const label = this.renderLabel(theme);

    return (
      <OptionWrapper
        label={label}
        color={color}
        type="command"
        icon={icon}
        draft={draft}
        description={explanation}
        shortcut={shortcut}
        goForward={goForward}
        isFocused={isFocused}
        theme={theme}
      />
    );
  };

  //  If a value is preselected, pre-select it
  private preSelectIfApplicable = (
    step: SelectStep | MultiSelectStep | TextInputStep,
    executionPathState: IExecutionPathState,
  ): Step => {
    const arg = step.argument;
    if (arg.preselected_key === undefined) return step;
    if (typeof arg.preselected_key !== 'string') return step;
    const preSelected = _get(executionPathState.context, arg.preselected_key);
    if (preSelected === undefined || preSelected === null) return step;

    if (step instanceof MultiSelectStep) {
      const _preSelected = Array.isArray(preSelected) ? preSelected : [preSelected];
      let newStep = step;
      _preSelected.forEach((value) => {
        const _parameterOption = ParameterOption.convertParameterToOption(executionPathState, value, arg);
        newStep = newStep.select(_parameterOption) as MultiSelectStep;
      });
      return newStep;
    } else {
      const _parameterOption = ParameterOption.convertParameterToOption(executionPathState, preSelected, arg);
      return step.select(_parameterOption);
    }
  };
}
