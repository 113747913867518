/********************************** DIFFS FOR REVIEW ****************************
 * - Algorithm variable to keep track of which search algorithm to use
 * - Standard API to access search, agnostic of algorithm:
 *      - Search, setCollection, getCollection
 *
 * Algorithm changes:
 *    For fuse:
 *      - Consolidating all fuses to a single fuse
 *      - Requires modifying the clientsearch function to be adding back options, instead of a
 *        separate fuse
 *    For fuzzysort: All functions
 *      - searchFuzzy => Search with fuzzysort algorithm
 *      - tranformFuzzy => Transform result to a standard result (ISearchResult)
 * ***********************************************************************************/

/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import { isContextArgument } from '@commandbar/internal/middleware/types';
import ExecutionPath, { IExecutionPathState } from './ExecutionPath';
import { Option, CommandOption, ResourceOption, ParameterOption } from './option';
import fuzzy, { Fuzzysort } from './fuzzysort-fork/fuzzysort';
import partition from 'lodash.partition';

const fuzzysort = fuzzy();

export interface ISearchResult {
  item: Option;
  score: number;
  matches: ISearchMatch[];
  refIndex: number;
}

export interface ISearchMatch {
  key: string;
  value: string;
  indices: number[][];
}

enum SEARCH_ALGORITHM {
  FUZZYSORT = 'New search',
}

export interface ISearch {
  search: (input: string, executionPathState: IExecutionPathState) => Option[];
  getCollection: () => Option[];
  setCollection: (options: Option[]) => void;
}

// Options don't need to be stored in state because they don't trigger re-renders
// They're accessed by components when they re-render
// Not storing in state avoids async issues (options is set and search is called immediately after using old state)
let options: Option[] = [];
const algorithm: SEARCH_ALGORITHM = SEARCH_ALGORITHM.FUZZYSORT;

const searchWeights: Record<string, number> = {
  'command.shortcut_mac': 1.01,
  'command.shortcut_win': 1.01,
  label: 1,
  'command.explanation': 0.6,
  'command.tags': 0.5,
  'tags.value': 0.8,
  description: 0.8,
  breadcrumbs: 0.8,
  'category.label': 0.5,
};

/*****************
 * Fuzzysort
 ******************/
// Given an array of ordered match indexes, return all sequences
//    Input: [1,2,3,5,7,8]
//    Output: [[1,3], [7,8]]
const findAllSequences = (indexes: number[]): number[][] => {
  let count = 0;
  const pairs = [];

  if (indexes.length === 1) return [[indexes[0], indexes[0]]];
  for (let i = 1; i < indexes.length; i++) {
    if (i > 0 && indexes[i] === indexes[i - 1] + 1) {
      // Continue the sequence
      count += 1;
    } else {
      if (count > 1) {
        // Sequence ended with last index
        const prevIndex = i - 1;
        pairs.push([indexes[prevIndex - count], indexes[prevIndex]]);
      }
      count = 0;
    }
  }
  if (count > 0) {
    // If we're still in a sequence, add it
    const maxIndex = indexes.length - 1;
    pairs.push([indexes[maxIndex - count], indexes[maxIndex]]);
  }
  return pairs;
};

const fuzzyResultSorter = (a: ISearchResult, b: ISearchResult): number => {
  // Javascript sort isn't stable. Use the original index as a secondary sorter
  if (a.score === b.score) {
    // Low is better
    return a.refIndex - b.refIndex;
  }
  // Higher is better
  return b.score - a.score;
};

const transformFuzzyResult = (result: any, keys: string[], index: number): ISearchResult => {
  const bestMatchIndex = result.findIndex(
    (match: any, index: number) => weightFuzzyScore(keys[index], match?.score) === result.score,
  );

  // If match doesn't exist (-Infinity) return a default match
  // This can happen if we do a threshold of -Infinity

  const bestMatch = !!result[bestMatchIndex]
    ? {
        key: keys[bestMatchIndex],
        value: result[bestMatchIndex].target,
        indices: findAllSequences(result[bestMatchIndex].indexes),
      }
    : { key: 'label', value: result.obj.label, indices: [[]] };

  const toRet = { item: result.obj, score: result.score, refIndex: index, matches: [bestMatch] };
  return toRet;
};

function weightFuzzyScore(key: string, score: number | undefined | null) {
  if (score === null || score === undefined) return -Infinity;
  const cappedScore = capSearchScore(score);
  const weight = searchWeights[key];
  if (!weight) return cappedScore;
  return cappedScore - (1 - weight) * 100;
}

function searchFuzzy(options: Option[], input: string, opts?: Fuzzysort.Options) {
  const keysToSearch = Object.keys(searchWeights);
  const results = fuzzysort.go(input, options, {
    keys: keysToSearch,
    threshold: -100000,
    allowTypo: true,
    minLengthForExactSearch: 100,
    shouldSort: false, // Off because fuzzysort sort isn't stable
    getFn: (obj: any, path: string | string[], target: any) => {
      let value = target;
      // path examples: ['command', 'shortcut_mac'] or 'label'
      const strPath = typeof path === 'string' ? path : path.join('.');
      // If we're getting a shortcut, filter out any keys that aren't a single character (e.g., Command)
      if (['command.shortcut_win', 'command.shortcut_mac'].includes(strPath) && Array.isArray(value)) {
        value = value.filter((shortcut) => shortcut.length === 1).join('');
      }
      // For unfurled commands, concatenate the resource label
      // Allows for searches like "open record 42" or "add to groceries"
      if (strPath === 'label' && obj.resource) {
        return obj.label + ' ' + obj.resource.label;
      }

      return value;
    },
    scoreFn: function (a: any) {
      const results = keysToSearch.map((key, index) => {
        return weightFuzzyScore(key, a[index]?.score);
      });
      return Math.max(...results);
    },
    ...opts,
  });
  return results.map((r: any, i: any) => transformFuzzyResult(r, Object.keys(searchWeights), i));
}

// cap score so that exact matches with small differences are equal
const capSearchScore = (score: number) => {
  switch (algorithm) {
    case SEARCH_ALGORITHM.FUZZYSORT:
      // estimate -- might have to update
      return Math.min(score, -100);
    default:
      return Math.min(score, -100);
  }
};

/****************************** Auto-show options ***********************/
function isClientSearchOption(option: Option, executionPathState: IExecutionPathState): boolean {
  if (option instanceof CommandOption) return false;
  if (option instanceof ResourceOption) {
    const addSearchKey = `commandbar-search-${option.category.contextKey}`;
    return !!executionPathState.callbacks[addSearchKey];
  }
  if (option instanceof ParameterOption) {
    const { currentStep } = ExecutionPath.currentStepAndIndex(executionPathState);

    // @ts-expect-error: FIXME step types
    if (!currentStep || !currentStep.argument || !currentStep.argument.value) {
      return false;
    }

    // @ts-expect-error: FIXME step types
    const argument = currentStep.argument;

    if (!isContextArgument(argument)) return false;
    const addSearchKey = `commandbar-search-${argument.value}`;
    return !!executionPathState.callbacks[addSearchKey];
  }
  return false;
}

// Deprecated if we move all client search options to the bottom
// const addClientSearchOptions = (
//   matches: Option[],
//   allOptions: Option[],
//   executionPathState: IExecutionPathState,
// ): Option[] => {
//   // We want to show all results, ordered by search quality
//   // Hack to get around this issue: https://github.com/krisk/Fuse/issues/182
//   const toRet = [...matches];
//   allOptions.forEach((opt: Option, index: number) => {
//     if (isClientSearchOption(opt, executionPathState)) {
//       const isMatched = matches.find((x) => Option.equalTo(x, opt));
//       if (!isMatched) {
//         opt.setMatches([], getLowestSeachScore());
//         toRet.push(opt);
//       }
//     }
//   });
//   return toRet;
// };

/****************************** API functions ***********************/
const setCollection = (newOptions: Option[]): void => {
  options = newOptions;
};

const getCollection = (): Option[] => {
  return options;
};

const search = (input: string, executionPathState: IExecutionPathState): Option[] => {
  if (input === '') {
    // Return options with cleared matches
    return options.map((x: Option) => x.setMatches([], undefined));
  }

  let searchResults: ISearchResult[] = [];
  // Handle clientSearch results differently:
  //    - Keep all results
  //    - Don't sort them

  const [standardOptions, clientSearchOptions] = partition(
    options,
    (o: Option) => !isClientSearchOption(o, executionPathState),
  );

  let standardResults: ISearchResult[], clientSearchResults: ISearchResult[];
  switch (algorithm) {
    case SEARCH_ALGORITHM.FUZZYSORT:
      // Threshold of 0 === Exact search
      const shouldUseExactSearch = executionPathState.organization?.search_fuzzy_threshold === 0;
      standardResults = searchFuzzy(
        standardOptions,
        input,
        shouldUseExactSearch
          ? { minLengthForExactSearch: 0 }
          : {
              // Org threshold positive #. 1 (close to exact match ) => -1000; 100 (default) => -100,000
              threshold: (executionPathState.organization?.search_fuzzy_threshold || 100) * -1000,
            },
      ).sort(fuzzyResultSorter);

      clientSearchResults = searchFuzzy(clientSearchOptions, input, { threshold: -Infinity, shouldSort: false });
      searchResults = [...standardResults, ...clientSearchResults];
      break;
  }

  return searchResults.map((x: ISearchResult) => x.item.setMatches(x.matches, x.score));
  // Merge in client search options into a list. Turned off to put them at the bottom
  // const toRet = addClientSearchOptions(resultingOptions, options, executionPathState);
  // return toRet;
};

const Search = {
  search,
  getCollection,
  setCollection,
};

export default Search;
