import React from 'react';
import { IOnboardingStep, ONBOARDING_TEXT_TOKEN, IOnboardingState, IOnboardingEventType } from './OnboardingReducer';
import { IGuideType } from '@commandbar/internal/middleware/types';
import { AiFillCheckCircle } from 'react-icons/ai';

const getOnboardingSteps = (): IOnboardingStep[] => {
  return [
    {
      title: ['You can use this command bar to get stuff done.'],
      text: ["We'll quickly show you how to use it."],
      nextBtnText: 'Next',
      hideBarAtStart: true,
      instructionText: ['Hit ', ONBOARDING_TEXT_TOKEN.OS_CTRL_KEY, '+k to continue'],
      canShowNext: (_state: IOnboardingState, _event_type: IOnboardingEventType, _data?: Record<string, any>) => true,
      emptyContent: (
        <div style={{ textAlign: 'center', marginTop: -50 }} className="commandbar-scale-entry-animation">
          <img
            alt="dark mode skeleton"
            src="https://staticassets.commandbar.com/editor-assets/Dark_mode_skeleton_new.png"
            width={600}
            height={364}
          />
        </div>
      ),
    },
    {
      title: ['You can hit ', ONBOARDING_TEXT_TOKEN.OS_CTRL_KEY, '+k to pull up the bar.'],
      text: ["Let's give it a try!"],
      nextBtnText: undefined,
      hideBarAtStart: true,
      instructionText: ['Hit ', ONBOARDING_TEXT_TOKEN.OS_CTRL_KEY, '+k to continue'],
      canShowNext: (state: IOnboardingState, event_type: IOnboardingEventType, _data?: Record<string, any>) => {
        switch (event_type) {
          case 'CMDK':
            return true;
          default:
            return false;
        }
      },
    },
    {
      title: ['Searching'],
      text: [
        'You can use the bar to search for things. Try executing the ',
        ONBOARDING_TEXT_TOKEN.COMMAND_NAME,
        ' command.',
      ],
      nextBtnText: undefined,
      hideBarAtStart: false,
      instructionText: ['Type ', ONBOARDING_TEXT_TOKEN.COMMAND_NAME, ' and hit enter'],
      canShowNext: (state: IOnboardingState, event_type: IOnboardingEventType, data?: Record<string, any>) => {
        // Some error with no active command here
        switch (event_type) {
          case 'Execution':
            if (data?.command?.id === state.activeCommand?.id) return true;
            if (!state.activeCommand) return true;
            return false;
          default:
            return false;
        }
      },
    },
    {
      title: ['Browsing commands'],
      text: ['Great! You can use the mouse or arrow keys to browse through commands to learn what you can do.'],
      nextBtnText: 'Continue',
      hideBarAtStart: false,
      instructionText: ['Press arrow down or scroll to continue'],
      canShowNext: (state: IOnboardingState, event_type: IOnboardingEventType, _data?: Record<string, any>) => {
        switch (event_type) {
          case 'Scroll':
          case 'ArrowPress':
            return true;
          default:
            return false;
        }
      },
    },
    {
      title: ['Good job!'],
      text: ["If you're stuck, try using this to find what you're looking for."],
      hideBarAtStart: true,
      instructionText: [''],
      nextBtnText: `Finish`,
      canShowNext: (_state: IOnboardingState, _event_type: IOnboardingEventType, _data?: Record<string, any>) => true,
      emptyContent: (
        <div className="commandbar-scale-entry-animation" style={{ textAlign: 'center', marginTop: 30 }}>
          <AiFillCheckCircle style={{ fontSize: 200, opacity: 1 }} />
        </div>
      ),
    },
  ];
};

const getGuideSteps = (guide: IGuideType): IOnboardingStep[] => {
  return [
    {
      title: [guide.nudge],
      text: [guide.guidance],
      instructionText: [],
      nextBtnText: undefined,
      hideBarAtStart: false,
      canShowNext: (state: IOnboardingState, event_type: IOnboardingEventType, _data?: Record<string, any>) => {
        switch (event_type) {
          case 'Execution':
          case 'Closed':
            return true;
        }
        return false;
      },
      allowExecution: true,
    },
  ];
};

const StepGenerator = {
  onboarding: getOnboardingSteps,
  guides: getGuideSteps,
};

export default StepGenerator;
