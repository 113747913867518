import ExecutionPath, {
  IExecutionPathState,
  fulfill,
  rebase,
  isFunctionStep,
  isContextFunctionStep,
} from './ExecutionPath';
import { Option, ParameterOption } from './option';
import { ChooseCommandOrResourceStep, TextInputStep } from './step';
import moment from 'moment';
import { InternalError } from './Errors';
import ClientSearch from './ClientSearch';
import { ErrorReporter } from '../error/ErrorReporter';
import { ErrorCode } from '../error/ErrorCode';

const simulate = (
  optionToSimulate: Option,
  state: IExecutionPathState,
): { isExecutable: boolean; isExecutableReason: string } => {
  try {
    _simulate(optionToSimulate, state);
  } catch (err) {
    if (err instanceof InternalError) {
      return { isExecutable: false, isExecutableReason: err.message };
    } else {
      ErrorReporter.get().exception(ErrorCode.SIMULATE_FAILED, err);
      throw err;
    }
  }
  return { isExecutable: true, isExecutableReason: '' };
};

const _simulate = (optionToSimulate: Option, state: IExecutionPathState) => {
  let { currentStep } = ExecutionPath.currentStepAndIndex(state);
  let _state = { ...state, simulation: true };

  if (!(currentStep instanceof ChooseCommandOrResourceStep)) {
    throw new InternalError('Simulation error: Invalid simulation step.', _state);
  }

  /*
    Things to catch:
      - A Step has no available options
      - Renders work
      - Final selections and breadcrumbs are good

    Improvements:
      - typechecking conditional .choose return value
   */

  const pickARandomOption = (options: Option[]): Option => {
    if (options.length === 0) {
      throw new InternalError('Step has no valid options', _state);
    }
    const randomIndex = Math.floor(Math.random() * Math.floor(options.length));

    return options[randomIndex];
  };

  let option;
  let started = false;

  while (currentStep !== undefined) {
    if (!started) {
      // If first step, we don't need to calculate the available options
      //    because we already know what command to use
      // The first rebase can also be very computationally intensive to get the available resources
      option = optionToSimulate;
      started = true;
    } else {
      if (currentStep instanceof TextInputStep) {
        option = new ParameterOption(_state, 'SampleTextInput', 'SampleTextInput');
      } else if (
        currentStep.hasOwnProperty('argument') &&
        // @ts-expect-error: FIXME update argument types
        currentStep.argument.type === 'provided' &&
        // @ts-expect-error: FIXME update argument types
        currentStep.argument.value === 'time'
      ) {
        option = new ParameterOption(_state, 'today', { label: 'today', date: moment.now() });
      } else if (
        currentStep.hasOwnProperty('argument') &&
        // @ts-expect-error: FIXME update argument types
        currentStep.argument.type === 'dependent'
      ) {
        option = new ParameterOption(_state, 'SampleDependentParameter', {
          label: 'SampleDependentParameter',
          value: 'SampleDependentParameter',
        });
      } else if (isFunctionStep(currentStep) || isContextFunctionStep(currentStep, state)) {
        // @REFACTOR Figure out what to do here
        option = new ParameterOption(_state, 'SampleFunctionParameter', {
          label: 'SampleFunctionParameter',
          value: 'SampleFunctionParameter',
        });
      } else if (
        currentStep.hasOwnProperty('argument') && // @ts-expect-error: FIXME update argument types
        ClientSearch.isDefined(currentStep.argument.value, state)
      ) {
        // If addSearch is defined for an argument, it might
        // not have any values defined initially
        option = new ParameterOption(_state, 'SampleSearchParameter', {
          label: 'SampleSearchParameter',
          value: 'SampleSearchParameter',
        });
      } else {
        // Rebase the state to get the current available commands
        _state = rebase(_state);
        option = pickARandomOption(_state.options);
      }
    }

    // @ts-expect-error: FIXME update argument types
    _state = option.choose(_state, undefined);
    _state = fulfill(_state);

    currentStep = ExecutionPath.currentStepAndIndex(_state).currentStep;
  }
  // Important not to do the final rebase, otherwise it will call simulate again
  // _state = rebase(_state);
};

const Simulate = {
  simulate,
};

export default Simulate;
