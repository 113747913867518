export enum ErrorCode {
  API = 'Internal-API-error',
  EXECUTION = 'Internal-execution-error',
  UNKNOWN = 'Internal-unknown-error',
  FILTER = 'Internal-filter-error',
  SIMULATE_FAILED = 'Failed simulate',
  CALLBACK = 'Client-callback-error',
  CLICK = 'Client-click-error',
  DASHBOARD = 'Client-dashboard-error',
  LINK = 'Client-link-error',
  CONTEXT = 'Client-context-error',
  MENU = 'Internal-menu-height-error',
  BAR_OPEN = 'Internal-bar-open-error',
}

const errorCodes = Object.values(ErrorCode);

export const isErrorCode = (x: unknown): x is ErrorCode => errorCodes.indexOf(x as ErrorCode) !== -1;
